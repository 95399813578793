import { Route, Switch } from 'react-router-dom';
import InspectionListPage from './pages/InspectionListPage';

export default function Inspection() {
  return (
    <div>
      <Switch>
        <Route path="/">
          <InspectionListPage />
        </Route>
      </Switch>
    </div>
  );
}
