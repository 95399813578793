import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { history } from 'utils';
import App from './App';
import { persistor, store } from './app/store';

import { I18nextProvider } from 'react-i18next';
import i18n from './translation/i18n';
import { PersistGate } from 'redux-persist/integration/react';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router history={history}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Router>
    </I18nextProvider>
  </Provider>
);
