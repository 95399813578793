import { BranchKey } from 'models';
import { CompanyNode } from '../models/company';
import { DepartmentNode } from '../models/department';

export function isCompanyNode(data: CompanyNode | DepartmentNode): data is CompanyNode {
  return data && (data as CompanyNode).company_id !== undefined;
}

export function isCompanyNodeOrDepartmentNode(data: CompanyNode | DepartmentNode) {
  return (
    data && ((data as CompanyNode).company_id !== undefined || (data as DepartmentNode).department_id !== undefined)
  );
}

export function getBranchKey(data: CompanyNode | DepartmentNode): BranchKey {
  if (isCompanyNode(data)) return { id: data.company_id, cd: data.company_cd };

  return { id: data.department_id, cd: data.department_cd };
}

export function findParentKeys(
  arr: CompanyNode[] | DepartmentNode[] | undefined,
  id: number,
  cd: string
): BranchKey[] | false {
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      const branchKey = getBranchKey(arr[i]);
      if (branchKey.id === id && branchKey.cd === cd) {
        return [];
      } else if (arr[i].departments && arr[i].departments?.length) {
        const t = findParentKeys(arr[i].departments, id, cd);

        if (t !== false) {
          t.push(branchKey);

          return t;
        }
      }
    }
  }

  return false;
}

export function findNode(
  arr: CompanyNode[] | DepartmentNode[] | undefined,
  id: number,
  cd: string
): CompanyNode | DepartmentNode | undefined {
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      const branchKey = getBranchKey(arr[i]);
      if (branchKey.id === id && branchKey.cd === cd) {
        return arr[i];
      } else if (arr[i].departments && arr[i].departments?.length) {
        const t = findNode(arr[i].departments, id, cd);

        if (t !== undefined) {
          return t;
        }
      }
    }
  }

  return undefined;
}

export function getParent(
  arr: CompanyNode[] | DepartmentNode[] | undefined,
  id: number,
  cd: string
): CompanyNode | DepartmentNode | undefined {
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].departments?.length &&
        arr[i].departments?.some((department) => department.department_id === id && department.department_cd === cd)
      ) {
        return arr[i];
      } else if (arr[i].departments && arr[i].departments?.length) {
        const t = getParent(arr[i].departments, id, cd);
        if (t !== undefined) {
          return t;
        }
      }
    }
  }

  return undefined;
}

export function getDirectChilds(
  arr: CompanyNode[] | DepartmentNode[] | undefined,
  id: number,
  cd: string
): CompanyNode[] | DepartmentNode[] | undefined {
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      const branchKey = getBranchKey(arr[i]);
      if (branchKey.id === id && branchKey.cd === cd) {
        return arr[i].departments;
      } else if (arr[i].departments && arr[i].departments?.length) {
        const t = getDirectChilds(arr[i].departments, id, cd);
        if (t !== undefined) {
          return t;
        }
      }
    }
  }

  return undefined;
}
