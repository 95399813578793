import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AssetListPage from './pages/index/AssetListPage';
import AssetAddPage from './pages/add/AssetAddPage';
import AssetEditPage from './pages/edit/AssetEditPage';

export default function AssetScreen() {
  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <AssetListPage />
        </Route>
        <Route path={`${path}/add`}>
          <AssetAddPage />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <AssetEditPage />
        </Route>
      </Switch>
    </div>
  );
}
