import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { Asset } from '../../models/Asset';
import { PreDataLoad } from 'features/AssetScreen/models/PreDataLoad';

const assetService = {
  getPreDataSearch(): Promise<PreDataLoad> {
    const url = 'assets-preload-search';
    return axiosClient.get(url);
  },
  getAll(params: string): Promise<CommonResponse<Asset>> {
    const url = `assets?${params}`;
    return axiosClient.get(url);
  },
};

export default assetService;
