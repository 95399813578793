import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AxiosError } from 'axios';
import { AlertModalProps } from 'components/common/AlertModal';
import { GlobalModalContext } from 'components/common/GlobalModal';
import { selectActiveAssetClassificationList } from 'features/AdminMaster/assetClassificationSlice';
import assetModelrService from 'features/AssetModel/assetModelService';
import { assetModelActions } from 'features/AssetModel/assetModelSlice';
import { selectActiveManufacturerList } from 'features/AssetModel/manufacturerSlice';
import { AssetModelFilterPayload } from 'features/AssetModel/models/assetModel';
import { CommonResponse } from 'models';
import { FormEvent, useRef, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPES } from 'utils';
import { handleServiceError } from 'utils/common';
import './style.scss';

export interface AssetModelFormModalProps {
  filter?: AssetModelFilterPayload;
  onClose?: () => void;
}

export function AssetModelFormModal({ filter, onClose }: AssetModelFormModalProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();

  const imgUrlRef = useRef<HTMLInputElement>(null);
  const fileNameRef = useRef<HTMLLabelElement>(null);

  // state
  const [validated, setValidated] = useState(false);
  const manufacturerList = useAppSelector(selectActiveManufacturerList);
  const assetClassificationList = useAppSelector(selectActiveAssetClassificationList);

  function displayError(error: AxiosError) {
    if (error) {
      const errorData = error.response?.data as CommonResponse<any>;
      if (!errorData.data) {
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: errorData.message,
          title: t('App.error'),
        });
      } else {
        let errorMessage = '';
        for (const property in errorData.data) {
          errorMessage += errorData.data[property].join('<br />');
        }

        if (errorMessage.length > 0) {
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: errorMessage,
            title: t('App.error'),
          });
        }
      }
    }
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      const bodyFormData = new FormData();
      bodyFormData.append('asset_classification_id', form['asset_classification_id'].value);
      bodyFormData.append('manufacturer_id', form['manufacturer_id'].value);
      bodyFormData.append('name', form['model_name'].value.trim());
      bodyFormData.append('size', form['size'].value.trim());
      if (imgUrlRef.current && imgUrlRef.current.files) {
        const file = imgUrlRef.current.files[0];
        if (file) {
          bodyFormData.append('img_url', file, file.name);
        }
      }

      (async () => {
        try {
          await assetModelrService.add(bodyFormData);
          // refetch asset model list
          dispatch(assetModelActions.fetchAssetModelList(filter));

          form.reset();
          if (fileNameRef.current) {
            fileNameRef.current.innerHTML = '';
          }
          setValidated(false);
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('AssetModelFeature.save_data_successful'),
            title: t('App.info'),
          });
        } catch (e) {
          if (!handleServiceError(showGlobalModal, e as AxiosError)) {
            displayError(e as AxiosError);
            setValidated(true);
          }
        }
      })();
    }

    setValidated(true);
  }

  function handleClose() {
    onClose && onClose();
  }

  function handleChooseFile() {
    if (imgUrlRef.current) {
      imgUrlRef.current.click();
    }
  }

  function handleFileChange() {
    if (imgUrlRef.current && fileNameRef.current) {
      fileNameRef.current.innerHTML = imgUrlRef.current.value.split(/[\\]+/).pop() || '';
    }
  }

  return (
    <Modal className="asset-model-form-modal" show backdrop="static" centered>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header className="bg-primary justify-content-center">
          <Modal.Title className="fs-6 text-white">{t('AssetModelFeature.form_add_title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-danger mb-3">{t('AssetModelFeature.note_required_field')}</div>
          <Row className="mb-3">
            <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="txt-asset-classification-id">
              <span className="text-danger">※</span>
              <span>{t('AssetModelFeature.category_name')}</span>
            </Form.Label>
            <Col xs sm="6">
              <InputGroup hasValidation>
                <Form.Select id="txt-asset-classification-id" name="asset_classification_id" required>
                  <option value="">{t('AssetModelFeature.select_category')}</option>
                  {assetClassificationList.map(function (classificationItem) {
                    return (
                      <option key={classificationItem.id} value={classificationItem.id}>
                        {classificationItem.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t('AssetModelFeature.warning_required_field')}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="txt-manufacturer-id">
              <span className="text-danger">※</span>
              <span>{t('AssetModelFeature.manufacturer_name')}</span>
            </Form.Label>
            <Col xs sm="6">
              <InputGroup hasValidation>
                <Form.Select id="txt-manufacturer-id" name="manufacturer_id" required>
                  <option value="">{t('AssetModelFeature.select_manufacturer')}</option>
                  {manufacturerList.map(function (manufacturer) {
                    return (
                      <option key={manufacturer.id} value={manufacturer.id}>
                        {manufacturer.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t('AssetModelFeature.warning_required_field')}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="txt-model-name">
              <span className="text-danger">※</span>
              <span>{t('AssetModelFeature.model_name')}</span>
            </Form.Label>
            <Col xs sm="6">
              <InputGroup hasValidation>
                <Form.Control id="txt-model-name" name="model_name" type="text" required />
                <Form.Control.Feedback type="invalid">
                  {t('AssetModelFeature.warning_required_field')}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="txt-size">
              <span>{t('AssetModelFeature.size')}</span>
            </Form.Label>
            <Col xs sm="6">
              <InputGroup hasValidation>
                <Form.Control id="txt-size" name="size" type="text" />
                <Form.Control.Feedback type="invalid">
                  {t('AssetModelFeature.warning_required_field')}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs sm="4" className="text-end">
              <Button className="btn--yellow" onClick={handleChooseFile}>
                {t('AssetModelFeature.upload_image')}
              </Button>
            </Col>
            <Col xs sm="8">
              <input
                type="file"
                name="img_url"
                style={{ display: 'none' }}
                ref={imgUrlRef}
                onChange={handleFileChange}
              />
              <Form.Label className="col-form-label file-name" ref={fileNameRef}></Form.Label>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button type="submit" variant="primary" className="me-3">
            {t('AssetModelFeature.sign_up')}
          </Button>
          <Button type="button" variant="light" onClick={handleClose}>
            {t('AssetModelFeature.close')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
