import 'react-datepicker/dist/react-datepicker.css';
import { Asset } from 'features/AssetScreen/models/Asset';
import moment from 'moment';

interface AssetProps {
  asset: Asset;
}

function ItemDelete({ asset }: AssetProps) {
  return (
    <tr key={Math.random()}>
      <td>{moment(asset.updated_date).format('YYYY-MM-DD')}</td>
      <td>{asset.updated_employee?.username}</td>
      <td>廃棄</td>
      <td>ステータス：廃棄</td>
      <td>{moment(asset.updated_date).format('YYYY-MM-DD')}</td>
    </tr>
  );
}
export default ItemDelete;
