import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { StoragePlace } from './models/storagePlace';

const storagePlaceService = {
  getAll(): Promise<CommonResponse<StoragePlace[]>> {
    const url = '/storage-places';
    return axiosClient.get(url);
  },
  add(name: string): Promise<CommonResponse<any>> {
    const url = '/storage-places';
    return axiosClient.post(url, { name });
  },
  delete(id: number): Promise<CommonResponse<null>> {
    const url = '/storage-place/' + id;
    return axiosClient.delete(url);
  },
  update(payload: Partial<StoragePlace>[]) {
    const url = '/storage-places';
    return axiosClient.patch(url, payload);
  },
  restore(id: number): Promise<CommonResponse<null>> {
    const url = '/storage-place/' + id + '/restore';
    return axiosClient.put(url);
  },
};

export default storagePlaceService;
