import { PayloadAction } from '@reduxjs/toolkit';
import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { CompanyNode, DepartmentNode, OrganizationTreeFilter } from 'components/BranchSidebar/models';
import organizationTreeService from 'components/BranchSidebar/organizationTreeService';
import { CommonResponse } from 'models';
import { call, put, takeEvery } from 'redux-saga/effects';
import inventoryService from './inventoryService';
import { inventoryListActions } from './inventorySlice';
import { InventoryMasterDataInfo } from './models/inventory';

function* getInventoryMasterData() {
  try {
    const response: CommonResponse<InventoryMasterDataInfo> = yield call(inventoryService.getInventoryMasterData);
    yield put(inventoryListActions.fetchInventoryMasterDataSuccess(response));
  } catch (e) {
    yield put(inventoryListActions.fetchInventoryMasterDataFailed());
    yield handleResponseError(e as AxiosError);
  }
}

function* getOrganizationData(action: PayloadAction<OrganizationTreeFilter>) {
  try {
    const response: CommonResponse<CompanyNode[] | DepartmentNode[]> = yield call(
      organizationTreeService.getAll,
      action.payload
    );
    yield put(inventoryListActions.fetchOrganizationDataSuccess(response));
  } catch (e) {
    yield put(inventoryListActions.fetchOrganizationDataFailed());
    yield handleResponseError(e as AxiosError);
  }
}

function* reloadOrganizationData(action: PayloadAction<OrganizationTreeFilter>) {
  try {
    const response: CommonResponse<CompanyNode[] | DepartmentNode[]> = yield call(
      organizationTreeService.getAll,
      action.payload
    );
    yield put(inventoryListActions.reloadOrganizationDataSuccess(response));
  } catch (e) {
    yield put(inventoryListActions.reloadOrganizationDataFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* inventorySaga() {
  yield takeEvery(inventoryListActions.fetchInventoryMasterData.type, getInventoryMasterData);
  yield takeEvery(inventoryListActions.fetchOrganizationData.type, getOrganizationData);
  yield takeEvery(inventoryListActions.reloadOrganizationData.type, reloadOrganizationData);
}
