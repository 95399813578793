interface SquarePlusIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export function SquarePlusIcon({ width = 21, height = 24, color = 'black' }: SquarePlusIconProps) {
  return (
    <svg width={width} height={height} viewBox={`0 0 28 32`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 21.5V17.5H8.5C7.66875 17.5 7 16.8313 7 16C7 15.1687 7.66875 14.5 8.5 14.5H12.5V10.5C12.5 9.66875 13.1687 9 14 9C14.8313 9 15.5 9.66875 15.5 10.5V14.5H19.5C20.3313 14.5 21 15.1687 21 16C21 16.8313 20.3313 17.5 19.5 17.5H15.5V21.5C15.5 22.3313 14.8313 23 14 23C13.1687 23 12.5 22.3313 12.5 21.5ZM0 6C0 3.79063 1.79063 2 4 2H24C26.2062 2 28 3.79063 28 6V26C28 28.2062 26.2062 30 24 30H4C1.79063 30 0 28.2062 0 26V6ZM3 6V26C3 26.55 3.4475 27 4 27H24C24.55 27 25 26.55 25 26V6C25 5.4475 24.55 5 24 5H4C3.4475 5 3 5.4475 3 6Z"
        fill={color}
      />
    </svg>
  );
}
