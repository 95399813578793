import axiosClient from 'utils/axiosClient';
import { CommonResponse } from 'models';
import { TransferHistory } from 'features/AssetScreen/models/Asset';
import { Company } from 'features/AssetScreen/models/Company';
const movementHisotryService = {
  filterAsset(query: string): Promise<CommonResponse<TransferHistory[]>> {
    const url = `movement-history?${query}`;
    return axiosClient.get(url);
  },
  fetchCompanies(params: string): Promise<CommonResponse<Company[]>> {
    const url = 'get-companies?data_date=' + params;
    return axiosClient.get(url);
  },
};

export default movementHisotryService;
