import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CommonResponse } from 'models';
import { AssetModel, AssetModelFilterPayload } from './models/assetModel';

export interface AssetModelState {
  loading: boolean;
  list: AssetModel[];
}

const initialState: AssetModelState = {
  loading: false,
  list: [],
};

const assetModelSlice = createSlice({
  name: 'asset_model',
  initialState,
  reducers: {
    fetchAssetModelList(state, action: PayloadAction<AssetModelFilterPayload | undefined>) {
      state.loading = true;
    },
    fetchAssetModelSuccess(state, action: PayloadAction<CommonResponse<AssetModel[]>>) {
      state.list = action.payload.data;
      state.loading = false;
    },
    fetchAssetModelFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const assetModelActions = assetModelSlice.actions;

// Selectors
export const selectAssetModelLoading = (state: RootState) => state.assetModel.loading;
export const selectAssetModelList = (state: RootState) => state.assetModel.list;

// Reducer
export const assetModelReducer = assetModelSlice.reducer;
