export const assetModelFeatureTranslationJP = {
  AssetModelFeature: {
    add_new: '新規登録',
    manufacturer_management: 'メーカー管理',
    category_name: '分類名',
    select_category: '分類を選択して下さい',
    manufacturer_name: 'メーカー名',
    select_manufacturer: 'メーカーを選択して下さい',
    search: '検索',
    update: '更新',
    change_image: '画像変更',
    classification: '分類',
    manufacturer: 'メーカー',
    model_name: '型式／機種名',
    size: '寸法',
    image: '画像',
    enable_stop: '有効／停止',

    //warning
    confirm_delete: '停止してもよろしいですか？',
    save_data_successful: 'データを正常に保存',
    no_data_changed: 'データは変更されていません',
    confirm_restore: 'データを回復してもよろしいですか?',

    //form
    form_add_title: '機種・型式 新規登録',
    sign_up: '登録',
    close: '閉じる',
    note_required_field: '※は入力必須項目です。',
    warning_required_field: 'この項目は必須です',
    upload_image: '画像アップロード',
    restore: '戻す',

    // manufacturer
    manufacturer_form_title: 'メーカーマスタ更新',
    change: '変更',
    stop: '停止',
    select: '選択',
  },
};
