export const inventoryTranslationJP = {
  Inventory: {
    btn_inventory_output: '棚卸表出力',
    btn_send_inventory: '棚卸送信',
    btn_approve: '承認',
    btn_send_back: '差戻',
    btn_store_report: '店舗報告済',
    btn_register: '登録',
    btn_detail_update: '明細更新',
    btn_final_confirmation: '最終確定',
    btn_confirm_cancellation: '確定取消',
    btn_csv_output: 'CSV出力',
    lbl_inventory_period: '棚卸期間(営業管理の最終承認期限)',
    lbl_inventory_entry: '棚卸入力',
    lbl_asset_management_number: '資産管理番号',
    lbl_situation: '状態',
    lbl_comment: 'コメント',
    lbl_possible_use: '利用可能(使用中)',
    lbl_possible_use_html: '利用可能<br/>(使用中)',
    lbl_fault: '故障中',
    lbl_belongs: '所属',
    lbl_state: '状況',
    lbl_broken: '故障',
    lbl_repairing: '修理中',
    lbl_loss: '紛失',
    lbl_unmoved_processing: '未移動処理',
    lbl_inventory_result_detail: '棚卸結果明細',
    lbl_inventory_status_list: '棚卸状況一覧',
    lbl_cancel: '取消',
    lbl_classification: '分類',
    lbl_use: '用途',
    lbl_manufacturer: 'メーカー',
    lbl_model_name: '機種名',
    lbl_status: 'ステータス',
    lbl_store_comment: '店舗コメント',
    lbl_system_message: 'システムメッセージ',
    lbl_register_person: '登録者',
    lbl_register_date: '登録日時',
    lbl_approval_history: '承認履歴',
    lbl_required_field: '※は入力必須項目です。\n全て登録が完了すれば、「棚卸送信」を押して下さい。',
    lbl_store_not_register: '店舗未登録',
    msg_update_successfully: '更新に成功',
    msg_required: '{0}を選択してください。',
    msg_select_department: '所属を選択してください。',
    msg_no_inventory_history: '棚卸履歴はありません',
    msg_confirm_approve: '指定組織下の店舗を、一括で承認しますか？',
    msg_confirm_send_back: '指定組織下の店舗を、一括で差戻しますか？',
    msg_confirm_need_report: '指定組織下の店舗を、一括で報告書提出済に更新しますか？',
    msg_confirm_final_confirmation: '最終確定を行いますか？',
    msg_confirm_cancel_final_confirmation: '確定取消を行いますか？',
    inventory_error_001: '資産がありません',
    inventory_error_002: '他所属資産です。本当に登録しますか？',
    inventory_asset_existed: 'この店舗に入力した資産管理番号はすでに登録されています。',
  },
};
