import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AxiosError } from 'axios';
import { AlertModalProps } from 'components/common/AlertModal';
import { ConfirmModalProps } from 'components/common/ConfirmModal';
import { GlobalModalContext } from 'components/common/GlobalModal';
import manufacturerService from 'features/AssetModel/manufacturerService';
import { manufacturerActions, selectManufacturerList } from 'features/AssetModel/manufacturerSlice';
import _ from 'lodash';
import { CommonResponse } from 'models';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPES } from 'utils';
import { handleServiceError } from 'utils/common';
import { Manufacturer } from '../../models/manufacturer';

export interface ManufacturerMasterModalProps {
  onClose: () => void;
}

export function ManufacturerMasterModal({ onClose }: ManufacturerMasterModalProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const tableDataRef = useRef<HTMLDivElement>(null);
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();

  //state
  const manufacturerList = useAppSelector(selectManufacturerList);
  const manufacturerEditedList = JSON.parse(JSON.stringify(manufacturerList)) as Manufacturer[];

  function displayError(error: AxiosError) {
    if (error) {
      const errorData = error.response?.data as CommonResponse<any>;
      if (!errorData.data) {
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: errorData.message,
          title: t('App.error'),
        });
      } else {
        let errorMessage = '';
        for (const property in errorData.data) {
          errorMessage += errorData.data[property].join('<br />');
        }

        if (errorMessage.length > 0) {
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: errorMessage,
            title: t('App.error'),
          });
        }
      }
    }
  }

  useEffect(() => {
    dispatch(manufacturerActions.fetchManufacturerList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const rows = tableDataRef.current?.querySelectorAll('tbody > tr');
    function rowEventClick(rowEvent: any) {
      const radioInput = rowEvent.currentTarget.querySelector('.form-check-input');
      if (radioInput) {
        radioInput.checked = true;
      }
    }
    if (rows) {
      rows.forEach((row) => {
        (row as HTMLTableRowElement).addEventListener('click', rowEventClick);
      });
    }

    return () => {
      if (rows) {
        rows.forEach((row) => {
          row.removeEventListener('click', rowEventClick);
        });
      }
    };
  }, [manufacturerList]);

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget as HTMLFormElement;
    if (!form.checkValidity()) {
      setValidated(true);
    } else {
      const name = form['manufacturer_name'].value.trim();

      (async () => {
        try {
          await manufacturerService.add(name);
          dispatch(manufacturerActions.fetchManufacturerList());
          form.reset();
          setValidated(false);
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('AssetModelFeature.save_data_successful'),
            title: t('App.info'),
          });
        } catch (e) {
          if (!handleServiceError(showGlobalModal, e as AxiosError)) {
            displayError(e as AxiosError);
            setValidated(true);
          }
        }
      })();
    }
  }

  function handleClose() {
    onClose && onClose();
  }

  function handleInputNewManufacturerBlur() {
    setValidated(false);
  }

  function handleUpdate() {
    // reset error text
    const errorHelperElems = document.querySelectorAll('.error-helper');
    errorHelperElems.forEach(function (elem) {
      elem.innerHTML = '';
    });

    const modifiedData = _.differenceWith(manufacturerEditedList, manufacturerList, _.isEqual);
    if (modifiedData.length === 0) {
      showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
        title: t('App.info'),
        message: t('AssetModelFeature.no_data_changed'),
      });
    } else {
      (async () => {
        try {
          const response = await manufacturerService.update(modifiedData);
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            title: t('Alpp.info'),
            message: response.message,
          });
          dispatch(manufacturerActions.fetchManufacturerList());

          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('AssetModelFeature.save_data_successful'),
            title: t('App.info'),
          });
        } catch (e) {
          if (!handleServiceError(showGlobalModal, e as AxiosError)) {
            const errorResponse = (e as AxiosError).response?.data as CommonResponse<any>;
            const errorData = errorResponse.data.error_data;
            const rowError = document.querySelector('#row-' + errorData.id);
            if (rowError) {
              rowError.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
              });
              for (const errorKey in errorResponse.data.errors) {
                const errorControlTextElem = rowError.querySelector('.error-' + errorKey);
                if (errorControlTextElem) {
                  errorControlTextElem.innerHTML = errorResponse.data.errors[errorKey];
                }
              }
            }
          }
        }
      })();
    }
  }

  function handleDelete() {
    let selectedRowIndex = -1;

    const selectedInput = document.querySelector('input[name="mfm-selected"]:checked') as HTMLInputElement;
    if (selectedInput) selectedRowIndex = Number(selectedInput.value);

    if (
      selectedRowIndex >= 0 &&
      manufacturerList[selectedRowIndex] &&
      !manufacturerList[selectedRowIndex].stopped_flg
    ) {
      showGlobalModal<ConfirmModalProps>(MODAL_TYPES.CONFIRM_MODAL, {
        title: t('App.warning'),
        message: t('AssetModelFeature.confirm_delete'),
        onConfirm: function () {
          (async () => {
            try {
              const response = await manufacturerService.delete(manufacturerList[selectedRowIndex].id);
              showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
                title: t('Alpp.info'),
                message: response.message,
              });
              dispatch(manufacturerActions.fetchManufacturerList());
            } catch (e) {
              if (!handleServiceError(showGlobalModal, e as AxiosError)) {
                displayError(e as AxiosError);
              }
            }
          })();
        },
      });
    }
  }

  function handleChangeName(e: React.ChangeEvent<HTMLInputElement>) {
    const errorHelperElem = e.currentTarget.parentElement?.querySelector('.error-helper');
    if (errorHelperElem) errorHelperElem.innerHTML = '';

    const newName = e.currentTarget.value;
    const rowIndex = Number(e.currentTarget.getAttribute('data-index'));

    if (manufacturerEditedList[rowIndex]) {
      manufacturerEditedList[rowIndex].name = newName;
    }
  }

  function handleRestore() {
    let selectedRowIndex = -1;

    const selectedInput = document.querySelector('input[name="mfm-selected"]:checked') as HTMLInputElement;
    if (selectedInput) selectedRowIndex = Number(selectedInput.value);

    if (selectedRowIndex >= 0 && manufacturerList[selectedRowIndex] && manufacturerList[selectedRowIndex].stopped_flg) {
      showGlobalModal<ConfirmModalProps>(MODAL_TYPES.CONFIRM_MODAL, {
        title: t('App.warning'),
        message: t('AssetModelFeature.confirm_restore'),
        onConfirm: function () {
          (async () => {
            try {
              const response = await manufacturerService.restore(manufacturerList[selectedRowIndex].id);
              showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
                title: t('App.info'),
                message: response.message,
              });
              dispatch(manufacturerActions.fetchManufacturerList());
            } catch (e) {
              if (!handleServiceError(showGlobalModal, e as AxiosError)) {
                displayError(e as AxiosError);
              }
            }
          })();
        },
      });
    }
  }

  return (
    <Modal className="manufacturer-master-modal" show backdrop="static" centered>
      <Modal.Header className="bg-primary justify-content-center">
        <Modal.Title className="fs-6 text-white">{t('AssetModelFeature.manufacturer_form_title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mb-3">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="d-flex flex-row">
              <InputGroup className="flex-grow-1 flex-shrink-1 me-4" hasValidation>
                <Form.Control
                  id="txt-new-manufacturer-name"
                  name="manufacturer_name"
                  type="text"
                  required
                  onBlur={handleInputNewManufacturerBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {t('AssetModelFeature.warning_required_field')}
                </Form.Control.Feedback>
              </InputGroup>
              <div>
                <Button type="submit" variant="primary">
                  {t('AssetModelFeature.add_new')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <Row className="mb-3">
          <Col xs sm="8">
            <Button className="btn--green me-3" onClick={handleUpdate}>
              {t('AssetModelFeature.change')}
            </Button>
            <Button className="btn--violet me-3" onClick={handleDelete}>
              {t('AssetModelFeature.stop')}
            </Button>
            <Button className="btn--yellow" onClick={handleRestore}>
              {t('AssetModelFeature.restore')}
            </Button>
          </Col>
          <Col xs sm="4" className="text-end">
            <Button variant="light" onClick={handleClose}>
              {t('AssetModelFeature.close')}
            </Button>
          </Col>
        </Row>

        <div className="sticky-table" ref={tableDataRef} style={{ maxHeight: '330px' }}>
          <Table hover>
            <thead>
              <tr>
                <th>{t('AssetModelFeature.select')}</th>
                <th>{t('AssetModelFeature.manufacturer_name')}</th>
              </tr>
            </thead>
            <tbody>
              {manufacturerList.map(function (item, index) {
                return (
                  <tr id={`row-${item.id}`} key={item.id} className={item.stopped_flg ? 'table-secondary' : ''}>
                    <td>
                      <Form.Check
                        className="col-form-label d-flex justify-content-center"
                        type="radio"
                        label=""
                        name="mfm-selected"
                        value={index}
                      />
                    </td>
                    <td>
                      <Form.Control
                        defaultValue={item.name}
                        data-index={index}
                        onChange={handleChangeName}
                      ></Form.Control>
                      <span className="error-helper error-name small text-danger"></span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
}
