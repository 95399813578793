export const inventoryHistoryFeatureTranslationEN = {
  InventoryHistoryFeature: {
    Component: {
      affiliation_search: 'Account search',
      employee_search: 'Employee search',
      search: 'search',
      csv_output: 'CSV output',
    },
    Text: {
      search_condition: 'Search conditions',
      it_is_a_required_field: '※It is a required field.',
      inventory_information: 'Inventory information',
      inventory_month: 'Inventory month',
      inventory_status: 'Inventory status',
      in_use: 'In use',
      out_of_order: 'Out of order',
      under_repair: 'Under repair',
      lost: 'Lost',
      basic_information: 'Basic information',
      asset_management_number: 'Asset management number',
      store_code: 'Store code',
      store_name: 'Store name',
      organization_selection: 'Organization selection',
      company_selection: 'Company selection',
      select_area_or_sales_department: 'Select area/Sales department',
      block_or_area_selection: 'Block/Area selection',
      number_of_searches: 'Number of searches',
      subject: 'Subject',
      all: 'All',
      please_select_a_company: 'Please select a company',
    },
    Table: {
      inventory_taking_month: 'Inventory Taking Month',
      inventory_status: 'Inventory Status',
      company_name: 'Company Name',
      area_sales_department: 'Area Sales Department',
      block_area: 'Block Area',
      store_name: 'Store Name',
      department_cd: 'Department cd',
      restaurant_cd: 'Restaurant cd',
      user: 'User',
      asset_management_number: 'Asset Management Number',
      comment: 'Comment',
      system: 'System',
      message: 'Message',
      classification: 'Classification',
      maker: 'Maker',
      model_name: 'Model Name',
      usage: 'Usage',
      system_messages: 'System Messages',
    },
  },
};
