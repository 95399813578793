import { ListGroup } from 'react-bootstrap';
import { CompanyNode } from '../models/company';
import { DepartmentNode } from '../models/department';
import { isCompanyNode } from '../utils/common';
import { BranchMenuItem } from './BranchMenuItem';
import { useTranslation } from 'react-i18next';
import './BranchMenu.css';

export interface BranchMenuProps {
  data: Array<CompanyNode> | Array<DepartmentNode>;
}

export function BranchMenu({ data }: BranchMenuProps) {
  const { t } = useTranslation();
  return (
    <ListGroup>
      {data.map(function (item) {
        let keyId = isCompanyNode(item) ? item.company_cd : (item as DepartmentNode).department_cd;
        return (
          <div key={keyId}>
            {isCompanyNode(item) && item.final_confirmation_flg && (
              <div className="confirmedText">
                {t('BranchSidebar.final_confirmed')}
              </div>
            )}
            <BranchMenuItem
              data={item}
            />
          </div>
        );
      })}
    </ListGroup>
  );
}
