//import { push } from 'connected-react-router';
import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { CommonResponse } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import companyService from './companyService';
import { companyActions } from './companySlice';
import { Company } from './models/company';

function* fetchCompanyList() {
  try {
    const response: CommonResponse<Company[]> = yield call(companyService.getAll);
    yield put(companyActions.fetchCompanySuccess(response));
  } catch (e) {
    yield put(companyActions.fetchCompanyFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* companySaga() {
  yield takeLatest(companyActions.fetchCompanyList.type, fetchCompanyList);
}
