import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GlobalModalContext } from '../GlobalModal';

export interface AssetImageModalProps {
  imageUrl: string;
}

function AssetImageModal({ imageUrl }: AssetImageModalProps) {
  const { t } = useTranslation();
  const { closeGlobalModal } = GlobalModalContext.useGlobalModalContext();

  function handleClose() {
    closeGlobalModal();
  }
  return (
    <Modal size="sm" className="confirm-modal" show backdrop="static" centered>
      <Modal.Body>
        <img style={{ maxWidth: '100%', height: 'auto' }} src={imageUrl} alt="資産イメージ" />
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="light" onClick={handleClose}>
          {t('Alert_Modal.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AssetImageModal;
