import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { InspectionHistory, InspectionHistoryFilterPayload } from './models/inspectionHistory';

const inspectionHistoryService = {
  getAll(params: InspectionHistoryFilterPayload): Promise<CommonResponse<InspectionHistory[]>> {
    const url = '/inspection-history';
    return axiosClient.get(url, { params });
  },
};

export default inspectionHistoryService;
