import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DepartmentSearchModalProps, DepartmentSearchModalReturnData } from 'components/common/DepartmentSearchModal';
import { EmployeeSearchModalProps, EmployeeSearchModalReturnData } from 'components/common/EmployeeSearchModal';
import { GlobalModalContext } from 'components/common/GlobalModal';
import { AssetFormSearch } from 'features/AssetScreen/models/AssetFormSearch';
import { PreDataLoad } from 'features/AssetScreen/models/PreDataLoad';
import { selectPermissionByFeature } from 'features/Auth/authSlice';
import moment from 'moment';
import { useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPES } from 'utils';
import { assetActions } from '../assetListSlice';
import CustomInputDatePicker from 'features/AssetScreen/components/CustomInputDatePicker';
import CustomDatePicker from 'components/common/DatePickerCustom/CustomDatePicker';

export interface PreDataLoadProps {
  paramsQuery: AssetFormSearch;
  preDataLoad: PreDataLoad;
  setParamsQuery: (value: AssetFormSearch) => void;
}

function FormSearch({ setParamsQuery, paramsQuery, preDataLoad }: PreDataLoadProps) {
  const { t } = useTranslation();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const dispatch = useAppDispatch();
  const [specifiedDateSearch, setSpecifiedDateSearch] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const permissions = useAppSelector(selectPermissionByFeature('asset'));

  function onChangeHandler(event: React.FormEvent<EventTarget>) {
    const params = { ...paramsQuery };
    const target = event.target as HTMLInputElement;
    switch (target.name) {
      case 'asset_management_no':
        params.asset_management_no = target.value;
        break;
      case 'restaurant_cd':
        params.restaurant_cd = target.value;
        break;
      case 'restaurant_name':
        params.restaurant_name = target.value;
        break;
      case 'current_storage_place_id':
        params.current_storage_place_id = target.value;
        break;
      case 'current_possession_company_cd':
        params.current_possession_company_cd = target.value;
        break;
      case 'classification_id':
        params.classification_id = target.value;
        break;
      case 'current_use_id':
        params.current_use_id = target.value;
        break;
      case 'option_search_date':
        params.option_search_date = target.value;
        break;
      case 'obsolete_department_asset_flg':
        params.obsolete_department_asset_flg = target.checked;
        break;
      case 'accounting_no':
        params.accounting_no = target.value;
        break;
      case 'accounting_class_id':
        params.accounting_class_id = target.value;
        break;
    }
    setParamsQuery(params);
  }
  function onChangeHandlerSpecificDate(date: Date) {
    const params = { ...paramsQuery };
    const dateTime = moment(date).format('YYYY-MM-DD');
    params.specific_data_date = dateTime;
    setParamsQuery(params);
  }
  function onChangeRangeStartDate(date: Date) {
    const params = { ...paramsQuery };
    const dateTime = moment(date).format('YYYY-MM-DD');
    params.from_data_date = dateTime;
    setParamsQuery(params);
  }
  function onChangeRangeEndDate(date: Date) {
    const params = { ...paramsQuery };
    const dateTime = moment(date).format('YYYY-MM-DD');
    params.to_data_date = dateTime;
    setParamsQuery(params);
  }
  function handdleSubmitSearch() {
    const query = Object.entries(paramsQuery)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    dispatch(assetActions.fetchAssetList(query));
  }
  function handleShowDepartmentSearchModal() {
    showGlobalModal<DepartmentSearchModalProps>(MODAL_TYPES.DEPARTMENT_SEARCH_MODAL, {
      dataDate: '',
      onReflect: (data: DepartmentSearchModalReturnData) => {
        const params = { ...paramsQuery };
        params.current_possession_department_cd = data.cd;
        params.current_possession_department_name = data.name;
        setParamsQuery(params);
      },
    });
  }
  function handleShowEmployeeSearchModal() {
    showGlobalModal<EmployeeSearchModalProps>(MODAL_TYPES.EMPLOYEE_SEARCH_MODAL, {
      dataDate: '',
      onReflect: (data: EmployeeSearchModalReturnData) => {
        const params = { ...paramsQuery };
        params.current_possession_employee_cd = data.employee_cd;
        params.current_possession_employee_name = data.employee_name;
        setParamsQuery(params);
      },
    });
  }
  function onChangeCurrentEmployeeCd(event: React.FormEvent<EventTarget>) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const params = { ...paramsQuery };
    params.current_possession_employee_cd = value;
    setParamsQuery(params);
  }
  function onChangeCurrentEmployeeName(event: React.FormEvent<EventTarget>) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const params = { ...paramsQuery };
    params.current_possession_employee_name = value;
    setParamsQuery(params);
  }

  function onChangeCurrentDepartmentCd(event: React.FormEvent<EventTarget>) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const params = { ...paramsQuery };
    params.current_possession_department_cd = value;
    setParamsQuery(params);
  }

  function onChangeCurrentDepartmentName(event: React.FormEvent<EventTarget>) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const params = { ...paramsQuery };
    params.current_possession_department_name = value;
    setParamsQuery(params);
  }

  function onChangeCurrentStatus(event: React.FormEvent<EventTarget>) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const params = { ...paramsQuery };
    params.current_status_id = value;
    setParamsQuery(params);
  }
 
  return (
    <div className="mb-3">
      <h4 className="test">{t('AssetScreen.index.form_search.title')}</h4>
      <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('AssetScreen.index.form_search_accordion_info.title')}</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Form.Label className="col-sm-2 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.management_no')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Control
                  className="form-control-sm"
                  type="text"
                  name="asset_management_no"
                  onChange={onChangeHandler}
                ></Form.Control>
              </Col>
            </Row>

            <Row>
              <Form.Label className="col-sm-2 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.restaurant_name')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Control
                  type="text"
                  className="form-control-sm"
                  name="restaurant_name"
                  onChange={onChangeHandler}
                ></Form.Control>
              </Col>
              <Form.Label className="col-sm-2 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.restaurant_cd')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Control
                  className="form-control-sm"
                  type="text"
                  name="restaurant_cd"
                  onChange={onChangeHandler}
                ></Form.Control>
              </Col>
            </Row>

            <Row>
              <Form.Label className="col-sm-2 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.current_storage_place_id')}
              </Form.Label>
              <Col xs sm="4">
                <Form.Select size="sm" onChange={onChangeHandler} name="current_storage_place_id">
                  <option value="">{t('AssetScreen.index.form_search_accordion_info.current_storage_place_id')}</option>
                  {preDataLoad &&
                    preDataLoad.data.storage_places.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>

            <Row>
              <Form.Label className="col-sm-2 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.current_possession_company_id')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Select size="sm" onChange={onChangeHandler} name="current_possession_company_cd">
                  <option value="">
                    {t('AssetScreen.index.form_search_accordion_info.current_possession_company_id')}
                  </option>
                  {preDataLoad &&
                    preDataLoad.data.companies.map((item) => (
                      <option key={item.id} value={item.cd}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              <Form.Label className="col-sm-1 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.current_possession_department_cd')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Control
                  className="form-control-sm"
                  type="text"
                  name="current_possession_department_cd"
                  onChange={onChangeCurrentDepartmentCd}
                  value={paramsQuery.current_possession_department_cd}
                ></Form.Control>
              </Col>

              <Form.Label className="col-sm-1 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.current_possession_department_name')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Control
                  className="form-control-sm"
                  type="text"
                  onChange={onChangeCurrentDepartmentName}
                  name="current_possession_department_name"
                  value={paramsQuery.current_possession_department_name}
                ></Form.Control>
              </Col>

              <Col xs sm="2">
                <Button size="sm" variant="primary" onClick={handleShowDepartmentSearchModal}>
                  {t('AssetScreen.index.form_search_accordion_info.affiliation_search')}
                </Button>
              </Col>
            </Row>

            <Row>
              <Form.Label className="col-sm-2 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.customer_selection')}
              </Form.Label>
              <Col xs sm="2"></Col>

              <Form.Label className="col-sm-1 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.current_possession_employee_cd')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Control
                  className="form-control-sm"
                  type="text"
                  onChange={onChangeCurrentEmployeeCd}
                  name="current_possession_employee_cd"
                  value={paramsQuery.current_possession_employee_cd}
                ></Form.Control>
              </Col>

              <Form.Label className="col-sm-1 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.current_possession_employee_name')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Control
                  className="form-control-sm"
                  type="text"
                  onChange={onChangeCurrentEmployeeName}
                  name="current_possession_employee_name"
                  value={paramsQuery.current_possession_employee_name}
                ></Form.Control>
              </Col>

              <Col xs sm="2">
                <Button variant="primary" type="submit" size="sm" onClick={handleShowEmployeeSearchModal}>
                  {t('AssetScreen.index.form_search_accordion_info.employee_search')}
                </Button>
              </Col>
            </Row>
            <Row>
              <Form.Label className="col-sm-2 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.classification_id')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Select size="sm" onChange={onChangeHandler} name="classification_id">
                  <option value="">{t('AssetScreen.index.form_search_accordion_info.classification_id')}</option>
                  {preDataLoad &&
                    preDataLoad.data.asset_classifications.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              <Form.Label className="col-sm-2 col-form-label text-end">
                {t('AssetScreen.index.form_search_accordion_info.current_use_id')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Select size="sm" onChange={onChangeHandler} name="current_use_id">
                  <option value="">{t('AssetScreen.index.form_search_accordion_info.current_use_id')}</option>
                  {preDataLoad &&
                    preDataLoad.data.asset_uses.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('AssetScreen.index.form_search_asset_status_information.title')}</Accordion.Header>
          <Accordion.Body>
            <Row className="fix-padding">
              <Form.Label className="col-sm-2 col-form-label text-end">
                {t('AssetScreen.index.form_search_asset_status_information.current_status_id')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Select size="sm" onChange={onChangeCurrentStatus} name="current_status_id">
                  <option value="">
                    {t('AssetScreen.index.form_search_asset_status_information.current_status_id')}
                  </option>
                  {preDataLoad &&
                    preDataLoad.data.asset_status.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm={2}></Col>
              <Col sm={2}>
                <Form.Check
                  type="radio"
                  label={t('AssetScreen.index.form_search_asset_status_information.specified_date_search')}
                  value={1}
                  onChange={onChangeHandler}
                  name="option_search_date"
                  id="formHorizontalRadios1"
                  defaultChecked={true}
                />
              </Col>
              <Col sm={2}>
                <CustomDatePicker
                  value={specifiedDateSearch}
                  customInputComponent={<CustomInputDatePicker />}
                  onChange={(date: Date) => {
                    setSpecifiedDateSearch(date);
                    onChangeHandlerSpecificDate(date);
                  }}
                  className="form-control form-control-sm"
                  name="specific_data_date"
                  dateFormat="yyyy/MM/dd"
                />
              </Col>
            </Row>

            <Row>
              <Col sm={2}></Col>
              <Col sm={2}>
                <Form.Check
                  type="radio"
                  label={t('AssetScreen.index.form_search_asset_status_information.range_search')}
                  value={2}
                  onChange={onChangeHandler}
                  name="option_search_date"
                  id="formHorizontalRadios2"
                />
              </Col>
              <Col sm={2}>
                <CustomDatePicker
                  value={startDate}
                  onChange={(date: Date) => {
                    setStartDate(date);
                    onChangeRangeStartDate(date);
                  }}
                  customInputComponent={<CustomInputDatePicker />}
                  className="form-control form-control-sm"
                  name="from_data_date"
                  dateFormat="yyyy/MM/dd"
                  selectsStart={true}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Col>
              ~
              <Col sm={2}>
                <CustomDatePicker
                  value={endDate}
                  onChange={(date: Date) => {
                    setEndDate(date);
                    onChangeRangeEndDate(date);
                  }}
                  customInputComponent={<CustomInputDatePicker />}
                  className="form-control form-control-sm"
                  name="to_data_date"
                  dateFormat="yyyy/MM/dd"
                  selectsEnd={true}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
              </Col>
            </Row>
            {permissions && permissions.includes('show_check_box_obsolete') && (
              <Row className="fix-padding-current-status">
                <Col sm={2}></Col>
                <Col sm={4}>
                  <Form.Check
                    type="checkbox"
                    label={t('AssetScreen.index.form_search_asset_status_information.obsolete_department_asset_flg')}
                    onChange={onChangeHandler}
                    name="obsolete_department_asset_flg"
                    id="formHorizontalRadios3"
                    defaultChecked={false}
                  />
                </Col>
              </Row>
            )}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{t('AssetScreen.index.form_search_asset_accounting_information.title')}</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Form.Label className="col-sm-2 col-form-label text-end">
                {t('AssetScreen.index.form_search_asset_accounting_information.accounting_no')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Control
                  className="form-control-sm"
                  type="text"
                  onChange={onChangeHandler}
                  name="accounting_no"
                ></Form.Control>
              </Col>

              <Form.Label className="col-sm-2 col-form-label text-end">
                {t('AssetScreen.index.form_search_asset_accounting_information.accounting_class_id')}
              </Form.Label>
              <Col xs sm="2">
                <Form.Select size="sm" onChange={onChangeHandler} name="accounting_class_id">
                  <option value="">
                    {t('AssetScreen.index.form_search_asset_accounting_information.accounting_class_id')}
                  </option>
                  {preDataLoad &&
                    preDataLoad.data.accounting_class.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="row mt-2">
        <div className="col text-center">
          <Button variant="primary" onClick={handdleSubmitSearch}>
            {t('AssetScreen.index.form_search.button')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FormSearch;
