import { FaDownload } from 'react-icons/fa';
import { Inventory } from '../models/inventory';
import { useTranslation } from 'react-i18next';
import ExportLink from 'components/common/ExportLink';

interface ExportCSVProps {
  data: Inventory[];
}

export default function ExportCSV({ data }: ExportCSVProps) {
  const { t } = useTranslation();
  const extraData = data.map((object) => {
    let month_year = `${
      String(object.inventory_month).substring(0, 4) + '/' + String(object.inventory_month).substring(4, 6)
    }`;
    return { ...object, inventory_month_edit: month_year };
  });

  const headers = [
    { header: t('InventoryHistoryFeature.Table.inventory_taking_month'), key: 'inventory_month_edit' },
    { header: t('InventoryHistoryFeature.Table.inventory_status'), key: 'inventory_status_name' },
    { header: t('InventoryHistoryFeature.Table.company_name'), key: 'company_name' },
    { header: t('InventoryHistoryFeature.Table.area_sales_department'), key: 'area_name' },
    { header: t('InventoryHistoryFeature.Table.block_area'), key: 'block_name' },
    { header: t('InventoryHistoryFeature.Table.store_name'), key: 'store_name' },
    { header: t('InventoryHistoryFeature.Table.department_cd'), key: 'department_cd' },
    { header: t('InventoryHistoryFeature.Table.restaurant_cd'), key: 'store_cd' },
    { header: t('InventoryHistoryFeature.Table.user'), key: 'current_possession_employee' },
    { header: t('InventoryHistoryFeature.Table.asset_management_number'), key: 'asset_management_no' },
    { header: t('InventoryHistoryFeature.Table.comment'), key: 'store_cmt' },
    { header: t('InventoryHistoryFeature.Table.system_messages'), key: 'system_mess' },
    { header: t('InventoryHistoryFeature.Table.classification'), key: 'department_classification' },
    { header: t('InventoryHistoryFeature.Table.maker'), key: 'manufacturer' },
    { header: t('InventoryHistoryFeature.Table.model_name'), key: 'model_name' },
    { header: t('InventoryHistoryFeature.Table.usage'), key: 'current_use' },
  ];
  return (
    <>
      <ExportLink
        target="_blank"
        filename={'inventory_history.csv'}
        data={extraData}
        headers={headers}
        className="btn btn-primary csv-export"
      >
        <FaDownload />
        &nbsp;
        <span className="d-inline-block align-middle">{t('InventoryHistoryFeature.Component.csv_output')}</span>
      </ExportLink>
    </>
  );
}
