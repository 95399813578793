import { NotFound } from 'components/common/NotFound';
import AssetModelFeature from 'features/AssetModel';
import MovementHistoryFeature from 'features/MovementHistory';
import Inventory from 'features/Inventory';
import InventoryHistoryConfirmationFeature from 'features/InventoryHistoryConfirmation';
import MainMenu from 'features/MainMenu';
import { Route, Switch } from 'react-router-dom';
import AssetScreen from 'features/AssetScreen';
import InspectionHistoryFeature from 'features/InspectionHistory';
import AdminMasterFeature from 'features/AdminMaster';
import Footer from 'components/common/Footer';
import { PERMISSIONS } from 'utils';
import Inspection from 'features/Inspection';

export const ROUTE_PERMISSION_MAPPING: { [key: string]: string } = {
  '/inventory': PERMISSIONS.INVENTORY,
  '/inspection': PERMISSIONS.INSPECTION,
  '/asset': PERMISSIONS.SEARCH_ASSET,
  '/inventory-history': PERMISSIONS.INVENTORY_HISTORY,
  '/movement-history': PERMISSIONS.MOVEMENT_HISTORY,
  '/asset-model': PERMISSIONS.ASSET_MODEL,
  '/asset/add': PERMISSIONS.ASSET_DETAIL,
  '/asset/edit/:id': PERMISSIONS.ASSET_DETAIL,
  '/inspection-history': PERMISSIONS.INSPECTION_HISTORY,
  '/admin-master': PERMISSIONS.ADMIN_MASTER,
};

export function AppLayout() {
  return (
    <>
      <section className="main-content">
        <Switch>
          <Route exact path="/">
            <MainMenu />
          </Route>
          <Route path="/asset">
            <AssetScreen />
          </Route>
          <Route path="/movement-history">
            <MovementHistoryFeature />
          </Route>
          <Route path="/inventory">
            <Inventory />
          </Route>
          <Route path="/inventory-history">
            <InventoryHistoryConfirmationFeature />
          </Route>
          <Route path="/inspection">
            <Inspection />
          </Route>
          <Route path="/asset-model">
            <AssetModelFeature />
          </Route>
          <Route path="/inspection-history">
            <InspectionHistoryFeature />
          </Route>
          <Route path="/admin-master">
            <AdminMasterFeature />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>

        <div className="page-footer">
          <Footer />
        </div>
      </section>
    </>
  );
}
