import { CommonResponse } from 'models/common';
import axiosClient from 'utils/axiosClient';
import { DepartmentNode } from './models';
import { OrganizationTreeFilter } from './models/common';
import { CompanyNode } from './models/company';

const organizationTreeService = {
  getAll(payload: OrganizationTreeFilter): Promise<CommonResponse<CompanyNode[] | DepartmentNode[]>> {
    const url = `/organization-tree?department_id=${payload.departmentId}&type=${payload.type}&year=${payload.year}&month=${payload.month}`;
    return axiosClient.get(url);
  },
};

export default organizationTreeService;
