import { useAppSelector } from 'app/hooks';
import { add } from 'date-fns';
import { selectEmployeeInfo } from 'features/Auth/authSlice';
import moment from 'moment';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactDatePicker, { registerLocale }  from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import ja from 'date-fns/locale/ja';
// Register the locale if you want to use a different one
registerLocale('ja', ja);

export interface BranchMonthPickerProps {
  onMonthChanged?: (date: Date) => void;
}

export function BranchMonthPicker({ onMonthChanged }: BranchMonthPickerProps) {
  const employeeInfo = useAppSelector(selectEmployeeInfo);
  const [startDate, setStartDate] = useState(
    employeeInfo?.previous_month_flg === 1 ? moment().subtract(1, 'months').toDate() : new Date()
  );

  function handleNextMonth() {
    const newDate = add(startDate, { months: 1 });
    setStartDate(newDate);

    onMonthChanged && onMonthChanged(newDate);
  }

  function handlePrevMonth() {
    const newDate = add(startDate, { months: -1 });
    setStartDate(newDate);

    onMonthChanged && onMonthChanged(newDate);
  }

  function handleDateChanged(newDate: Date) {
    setStartDate(newDate);

    onMonthChanged && onMonthChanged(newDate);
  }

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <input
        className="form-control text-center"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
      />
    );
  });

  return (
    <div className="ams-month-picker d-flex align-items-center">
      <Button variant="primary" className="ams-month-picker__button me-2" onClick={handlePrevMonth}>
        <svg width="14" height="14" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.58752 14.5875C1.80627 15.3687 1.80627 16.6375 2.58752 17.4187L12.5875 27.4187C13.3688 28.2 14.6375 28.2 15.4188 27.4187C16.2 26.6375 16.2 25.3687 15.4188 24.5875L6.83127 16L15.4125 7.41249C16.1938 6.63124 16.1938 5.36249 15.4125 4.58124C14.6313 3.79999 13.3625 3.79999 12.5813 4.58124L2.58127 14.5812L2.58752 14.5875ZM24.5875 4.58749L14.5875 14.5875C13.8063 15.3687 13.8063 16.6375 14.5875 17.4187L24.5875 27.4187C25.3688 28.2 26.6375 28.2 27.4188 27.4187C28.2 26.6375 28.2 25.3687 27.4188 24.5875L18.8313 16L27.4125 7.41249C28.1938 6.63124 28.1938 5.36249 27.4125 4.58124C26.6313 3.79999 25.3625 3.79999 24.5813 4.58124L24.5875 4.58749Z"
            fill="white"
          />
        </svg>
      </Button>
      <ReactDatePicker
        className="ams-month-picker__input"
        selected={startDate}
        onChange={handleDateChanged}
        dateFormat="yyyy/MM"
        showMonthYearPicker
        customInput={<CustomInput />}
        locale="ja"
      />
      <Button variant="primary" className="ams-month-picker__button ms-2" onClick={handleNextMonth}>
        <svg width="14" height="14" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M29.4125 17.4125C30.1938 16.6312 30.1938 15.3625 29.4125 14.5812L19.4125 4.58124C18.6313 3.79999 17.3625 3.79999 16.5813 4.58124C15.8 5.36249 15.8 6.63124 16.5813 7.41249L25.1688 16L16.5875 24.5875C15.8063 25.3687 15.8063 26.6375 16.5875 27.4187C17.3688 28.2 18.6375 28.2 19.4188 27.4187L29.4188 17.4187L29.4125 17.4125ZM7.41255 27.4125L17.4125 17.4125C18.1938 16.6312 18.1938 15.3625 17.4125 14.5812L7.41255 4.58124C6.6313 3.79999 5.36255 3.79999 4.5813 4.58124C3.80005 5.36249 3.80005 6.63124 4.5813 7.41249L13.1688 16L4.58755 24.5875C3.8063 25.3687 3.8063 26.6375 4.58755 27.4187C5.3688 28.2 6.63755 28.2 7.4188 27.4187L7.41255 27.4125Z"
            fill="white"
          />
        </svg>
      </Button>
    </div>
  );
}
