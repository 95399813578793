import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { CommonResponse } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import approvalStatusService from './approvalStatusService';
import { approvalStatusActions } from './approvalStatusSlice';
import { ApprovalStatus } from './models/approvalStatus';

function* fetchApprovalStatusList() {
  try {
    const response: CommonResponse<{ approval_status: ApprovalStatus[] }> = yield call(approvalStatusService.getAll);
    yield put(approvalStatusActions.fetchApprovalStatusSuccess(response));
  } catch (e) {
    yield put(approvalStatusActions.fetchApprovalStatusFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* approvalStatusSaga() {
  yield takeLatest(approvalStatusActions.fetchApprovalStatusList.type, fetchApprovalStatusList);
}
