export const APP_NAME = 'Asset Management System';

export const MODAL_TYPES = {
  ALERT_MODAL: 'ALERT_MODAL',
  CONFIRM_MODAL: 'CONFIRM_MODAL',
  DEPARTMENT_SEARCH_MODAL: 'DEPARTMENT_SEARCH_MODAL',
  EMPLOYEE_SEARCH_MODAL: 'EMPLOYEE_SEARCH_MODAL',
  ASSET_IMAGE_MODAL: 'ASSET_IMAGE_MODAL',
};

export const ERROR_NOTIFICATION_CODES = [403, 404, 500, 503];

export const SELECT_ORGANIZATION_TREE_TYPE_STORE: number = 1;
export const SELECT_ORGANIZATION_TREE_TYPE_NOT_STORE: number = 2;

export const STATUS_SUCCESS: string = 'success';
export const STATUS_ERROR: string = 'error';
export const INVENTORY_STATUS_POSSIBLE: number = 1;
export const INVENTORY_STATUS_FAULT: number = 2;
export const INVENTORY_STATUS_LOSS: number = 4;

export const APPROVAL_STATUS_APPROVED_COMPLETED_REPORT_COMPLETED: number = 1;
export const APPROVAL_STATUS_APPROVED_COMPLETED_NEED_REPORT: number = 2;
export const APPROVAL_STATUS_APPROVED_COMPLETED: number = 3;
export const APPROVAL_STATUS_SENT: number = 4;
export const APPROVAL_STATUS_SEND_BACK: number = 5;
export const APPROVAL_STATUS_UNSENT: number = 6;

export const RESTAURANT_DEPARTMENT_CLASSIFICATION: string = 'T';
export const UNIT_DEPARTMENT_CLASSIFICATION: string = 'U';
export const BLOCK_DEPARTMENT_CLASSIFICATION: string = 'B';
export const AREA_DEPARTMENT_CLASSIFICATION: string = 'A';

export const SIDE_BAR_FOR_INVENTORY: number = 3;
export const SIDE_BAR_FOR_INSPECTION: number = 4;

export const PERMISSIONS = {
  INVENTORY: 'show_inventory_btn',
  INSPECTION: 'show_inspection_btn',
  SEARCH_ASSET: 'show_search_asset_btn',
  INVENTORY_HISTORY: 'show_inventory_history_btn',
  MOVEMENT_HISTORY: 'show_movement_history_btn',
  ASSET_MODEL: 'show_asset_model_btn',
  ASSET_DETAIL: 'show_asset_detail_btn',
  INSPECTION_HISTORY: 'show_inspection_history_btn',
  ADMIN_MASTER: 'show_admin_master_btn',
};

export const INSPECTION_STATE_NORMAL = 1;
export const INSPECTION_STATE_ABNORMAL = 2;

export const STATE_NORMAL_TEXT = '〇'; //〇
export const STATE_ABNORMAL_TEXT = 'X'; //ｘ
export const INSPECTION_STATE_ABNORMAL_TEXT = '異常あり';
export const INSPECTION_STATE_NO_ABNORMAL_TEXT = '異常なし';

// エリアマネージャー（安楽亭） Area Manager (Anrakutei), エリア長（アークミール）Area leader (Arcmeal)
export const AREA_MANAGEMENT_LEVEL = '300';
// エリア長（アークミール）Area leader (Arcmeal), ブロック長（安楽亭）Block leader (Anrakutei)
export const BLOCK_MANAGEMENT_LEVEL = '200';
