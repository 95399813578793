import * as CryptoTS from 'crypto-ts';

export default class EncryptUtils {
  static encrypt(plainText: string) {
    let encryptedString = CryptoTS.AES.encrypt(plainText, 'process.env.REACT_APP_ENCRYPT_KEY').toString();

    return encryptedString;
  }

  static decrypt(encryptedString: string) {
    let bytes = CryptoTS.AES.decrypt(encryptedString, 'process.env.REACT_APP_ENCRYPT_KEY');

    let plainText = bytes.toString(CryptoTS.enc.Utf8);

    return plainText;
  }
}
