import { ExportLinkProps } from './types';
import ExcelJS from 'exceljs';
import Encoding from 'encoding-japanese';

function ExportLink({
  data,
  headers,
  filename,
  format = 'csv',
  charcode = 'SJIS',
  children,
  ...rests
}: ExportLinkProps) {
  function handleClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();

    (async () => {
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet('sheet1');
      const worksheet = workbook.getWorksheet('sheet1');
      worksheet.columns = headers;
      worksheet.addRows(data);

      const uint8Array =
        format === 'xlsx'
          ? await workbook.xlsx.writeBuffer()
          : charcode === 'UTF8'
          ? await workbook.csv.writeBuffer()
          : new Uint8Array(
              Encoding.convert(new Uint8Array(await workbook.csv.writeBuffer()), {
                from: 'UTF8',
                to: 'SJIS',
              })
            );

      const blob = new Blob([uint8Array], {
        type: 'application/octet-binary',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      a.remove();
    })();
  }

  return (
    <a {...rests} target="_self" onClick={handleClick}>
      {children}
    </a>
  );
}

export default ExportLink;
