import ExportLink from 'components/common/ExportLink';
import { useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa';
import { MovementHistoryCSV } from '../models/MovementHistoryCSV';
interface ExportCSVProps {
  rows: MovementHistoryCSV[];
}

export default function ExportCSV({ rows }: ExportCSVProps) {
  const { t } = useTranslation();

  const headers = [
    { header: t('MovementHistoryFeature.Table.moving_day'), key: 'applied_start_date' },
    { header: t('MovementHistoryFeature.Table.control_number'), key: 'management_no' },
    { header: t('MovementHistoryFeature.Table.accounting_number'), key: 'accounting_no' },
    { header: t('MovementHistoryFeature.Table.classification'), key: 'classification' },
    { header: t('MovementHistoryFeature.Table.manufacturer'), key: 'manufacturer' },
    { header: t('MovementHistoryFeature.Table.model_name'), key: 'model' },
    { header: t('MovementHistoryFeature.Table.usage'), key: 'current_use' },
    { header: t('MovementHistoryFeature.Table.purchase_date'), key: 'purchased_date' },
    { header: t('MovementHistoryFeature.Table.purchase_price'), key: 'purchased_price' },
    { header: t('MovementHistoryFeature.Table.source_company'), key: 'from_company' },
    { header: t('MovementHistoryFeature.Table.source_department'), key: 'from_department' },
    { header: t('MovementHistoryFeature.Table.moving_source'), key: 'from_storage_place' },
    { header: t('MovementHistoryFeature.Table.source_user'), key: 'from_employee' },
    { header: t('MovementHistoryFeature.Table.destination_company'), key: 'to_company' },
    { header: t('MovementHistoryFeature.Table.destination_department'), key: 'to_department' },
    { header: t('MovementHistoryFeature.Table.destination'), key: 'to_storage_place' },
    { header: t('MovementHistoryFeature.Table.destination_user'), key: 'to_employee' },
    { header: t('MovementHistoryFeature.Table.update_date'), key: 'updated_date' },
    { header: t('MovementHistoryFeature.Table.changer'), key: 'updated_employee' },
  ];

  return (
    <>
      <ExportLink
        target="_blank"
        filename={'movement_history.csv'}
        data={rows}
        headers={headers}
        className="btn btn-primary csv-export"
      >
        <FaDownload />
        &nbsp;
        <span className="d-inline-block align-middle">{t('InspectionHistoryFeature.csv_output')}</span>
      </ExportLink>
    </>
  );
}
