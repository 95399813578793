export const statusHeaderTranslationJP = {
  Status_Header: {
    login_information: 'ログイン情報',
    department_name: '部門名',
    employee_name: '社員名',
    employee_code: '社員コード',
    company_name: '会社名',
    job_name: '職務',
    return: '戻る',
    logout: 'ログアウト',
  },
};
