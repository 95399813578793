import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CommonResponse } from 'models';
import { Inventory, Department, SearchInventoryHistory, SearchDepartment } from './models/inventory';
import _ from 'lodash';
import { AREA_DEPARTMENT_CLASSIFICATION, BLOCK_DEPARTMENT_CLASSIFICATION } from 'utils/constants';

//inventory state class
export interface InventoryState {
  loading: boolean;
  inventory_list: Inventory[];
  department_list: Array<Department>;
}

// init model state
const initialState: InventoryState = {
  loading: false,
  inventory_list: [] as Array<Inventory>,
  department_list: [] as Array<Department>,
};

// slice inventory
const inventoryHistorySlice = createSlice({
  name: 'inventoryHistory',
  initialState,
  reducers: {
    fetchInventory(state, action: PayloadAction<SearchInventoryHistory>) {
      state.loading = true;
    },
    fetchInventorySuccess(state, action: PayloadAction<CommonResponse<Array<Inventory>>>) {
      state.inventory_list = action.payload.data;
    },
    fetchInventoryFailed(state) {
      state.loading = false;
      state.inventory_list = [];
    },
    fetchSaleDepartment(state, action: PayloadAction<SearchDepartment>) {
      state.loading = true;
    },
    fetchSaleDepartmentSuccess(state, action: PayloadAction<CommonResponse<Array<Department>>>) {
      state.department_list = action.payload.data;
    },
    fetchSaleDepartmentFailed(state) {
      state.loading = false;
      state.department_list = [] as Array<Department>;
    },
  },
});

// Actions
export const inventoryHistoryActions = inventoryHistorySlice.actions;

// Selectors
export const selectInventoryList = (state: RootState) => state.inventoryHistory.inventory_list;
export const selectDepartmentList = (state: RootState) => state.inventoryHistory.department_list;

export const selectCompanyList = createSelector(selectDepartmentList, (departments) => {
  let company_list = departments.map((department: Department) => department.company);
  return _.uniqBy(company_list, 'cd');
});

export const selectAreaDepartment = createSelector(selectDepartmentList, (departments) => {
  return departments.filter((department: Department) => department.classification === AREA_DEPARTMENT_CLASSIFICATION);
});

export const selectBlockDepartment = createSelector(selectDepartmentList, (departments) => {
  return departments.filter((department: Department) => department.classification === BLOCK_DEPARTMENT_CLASSIFICATION);
});

// Reducer
export const inventoryHistoryReducer = inventoryHistorySlice.reducer;
