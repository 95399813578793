import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { AssetModel, AssetModelFilterPayload, UploadImageAssetResponse } from './models/assetModel';

const assetModelrService = {
  getAll(params: AssetModelFilterPayload | undefined): Promise<CommonResponse<AssetModel[]>> {
    const url = '/asset-models';
    return axiosClient.get(url, { params });
  },
  add(payload: FormData): Promise<CommonResponse<any>> {
    const url = '/asset-models';
    return axiosClient.post(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  update(payload: Partial<AssetModel>[]): Promise<CommonResponse<any>> {
    const url = '/asset-models';
    return axiosClient.patch(url, payload);
  },
  uploadImageAsset(id: number, payload: FormData): Promise<CommonResponse<UploadImageAssetResponse>> {
    const url = '/asset-models/upload-image-asset/' + id;
    return axiosClient.put(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export default assetModelrService;
