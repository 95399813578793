import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { authActions, selectEmployeeInfo, selectJobInfo } from 'features/Auth/authSlice';
export interface StatusHeaderProps {
  actionName?: 'back' | 'logout';
  onAction?: () => void;
}

function StatusHeader({ actionName = 'back', onAction }: StatusHeaderProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // state
  const employeeInfo = useAppSelector(selectEmployeeInfo);
  const employeeJobInfo = useAppSelector(selectJobInfo);

  const handleActionClick = () => {
    if (actionName === 'logout') {
      dispatch(authActions.actionLogout());
    } else {
      onAction && onAction();
    }
  };

  return (
    <div className="status-header">
      <Container fluid>
        <div className="status-header__wrapper d-flex align-items-center justify-content-between">
          <div className="status-header__info d-inline-flex">
            <a className="me-4 text-white text-decoration-none" href="/">
              {t('App.app_name')}
            </a>
            <div className="me-4">{t('Status_Header.login_information')}</div>
            <div className="me-4">
              {t('Status_Header.company_name')}:&nbsp;
              <span className="status-header__value">{employeeJobInfo?.company_name}</span>
            </div>
            <div className="me-4">
              {t('Status_Header.department_name')}:&nbsp;
              <span className="status-header__value">{employeeJobInfo?.department_name}</span>
            </div>
            <div className="me-4">
              {t('Status_Header.job_name')}:&nbsp;
              <span className="status-header__value">{employeeJobInfo?.job_id ? employeeJobInfo?.job_name : '-'}</span>
            </div>
            <div className="me-4">
              {t('Status_Header.employee_name')}:&nbsp;
              <span className="status-header__value">{employeeInfo?.employee_name}</span>
            </div>
            <div className="me-4">
              {t('Status_Header.employee_code')}:&nbsp;
              <span className="status-header__value">{employeeInfo?.employee_cd}</span>
            </div>
          </div>
          <div className="status-header__actions">
            <Button variant="light" onClick={handleActionClick}>
              {actionName === 'back' ? t('Status_Header.return') : t('Status_Header.logout')}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default StatusHeader;
