import { AxiosError } from 'axios';
import { errorNotificationActions } from 'components/common/ErrorNotification/errorNotificationSlice';
import assetClassificationSaga from 'features/AdminMaster/assetClassificationSaga';
import assetStatusSaga from 'features/AdminMaster/assetStatusSaga';
import assetUseSaga from 'features/AdminMaster/assetUseSaga';
import storagePlaceSaga from 'features/AdminMaster/storagePlaceSaga';
import assetModelSaga from 'features/AssetModel/assetModelSaga';
import manufacturerSaga from 'features/AssetModel/manufacturerSaga';
import assetAddSaga from 'features/AssetScreen/pages/add/assetAddSaga';
import assetEditSaga from 'features/AssetScreen/pages/edit/assetEditSaga';
import assetSaga from 'features/AssetScreen/pages/index/assetSaga';
import authSaga from 'features/Auth/authSaga';
import approvalStatusSaga from 'features/common/approvalStatusSaga';
import companySaga from 'features/common/companySaga';
import inspectionSaga from 'features/Inspection/inspectionSaga';
import inventorySaga from 'features/Inventory/inventorySaga';
import inventoryHistorySaga from 'features/InventoryHistoryConfirmation/inventoryHistoryConfirmationSaga';
import { all, put } from 'redux-saga/effects';

export function* handleResponseError(e: AxiosError) {
  yield put(errorNotificationActions.setError(e));
}

export default function* rootSaga() {
  yield all([
    companySaga(),
    authSaga(),
    assetSaga(),
    inventorySaga(),
    inspectionSaga(),
    manufacturerSaga(),
    assetModelSaga(),
    assetAddSaga(),
    assetEditSaga(),
    assetStatusSaga(),
    storagePlaceSaga(),
    assetClassificationSaga(),
    assetUseSaga(),
    approvalStatusSaga(),
    inventoryHistorySaga(),
  ]);
}
