export const departmentSearchModalTranslationEN = {
  Department_Search_Modal: {
    title: 'Department Search',
    close: 'Close',
    reflect: 'Reflect',
    search: 'Search',
    company: 'Company',
    departmentcd: 'Department code',
    department_name: 'Department name',
    how_to_use: 'Search results (select a selection and press the reflection button)',
    select: 'Select',
    company_name: 'Company name',
    select_company: 'All companies',
  },
};
