import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { CommonResponse } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import manufacturerService from './manufacturerService';
import { manufacturerActions } from './manufacturerSlice';
import { Manufacturer } from './models/manufacturer';

function* fetchManufacturerList() {
  try {
    const response: CommonResponse<Manufacturer[]> = yield call(manufacturerService.getAll);
    yield put(manufacturerActions.fetchManufacturerSuccess(response));
  } catch (e) {
    yield put(manufacturerActions.fetchManufacturerFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* manufacturerSaga() {
  yield takeLatest(manufacturerActions.fetchManufacturerList.type, fetchManufacturerList);
}
