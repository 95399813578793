import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { Asset } from 'features/AssetScreen/models/Asset';
import ItemStatus from './ItemStatus';
import ItemTransfer from './ItemTransfer';
import ItemDelete from './ItemDelete';

interface AssetHistoryProps {
  asset: Asset;
}

function AssetHistory({ asset }: AssetHistoryProps) {
  const { t } = useTranslation();
  return (
    <Card className="mt-3">
      <Card.Header as="h5">{t('AssetScreen.history.title')}</Card.Header>
      <Card.Body>
        <div className="sticky-table" style={{ maxHeight: '300px' }}>
          <Table hover>
            <thead>
              <tr>
                <th>{t('AssetScreen.history.applied_start_date')}</th>
                <th>{t('AssetScreen.history.updated_employee')}</th>
                <th>{t('AssetScreen.history.status')}</th>
                <th>{t('AssetScreen.history.note')}</th>
                <th>{t('AssetScreen.history.updated_date')}</th>
              </tr>
            </thead>
            <tbody>
              {asset.delete_flg && <ItemDelete asset={asset}></ItemDelete>}
              {asset.status_histories.map((item: any, index) => {
                const type = item.is_type;
                // if type is zero then return template status
                if (type === 0) {
                  return <ItemStatus key={index} item={item} idx={index}></ItemStatus>;
                } else {
                  // return template transfer
                  return <ItemTransfer key={index} item={item} idx={index}></ItemTransfer>;
                }
              })}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
}

export default AssetHistory;
