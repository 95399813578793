import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { AssetClassification } from './models/assetClassification';

const assetClassificationService = {
  getAll(): Promise<CommonResponse<AssetClassification[]>> {
    const url = '/asset-classifications';
    return axiosClient.get(url);
  },
  add(cd: string, name: string): Promise<CommonResponse<any>> {
    const url = '/asset-classifications';
    return axiosClient.post(url, { cd, name });
  },
  delete(id: number): Promise<CommonResponse<null>> {
    const url = '/asset-classification/' + id;
    return axiosClient.delete(url);
  },
  update(payload: Partial<AssetClassification>[]) {
    const url = '/asset-classifications';
    return axiosClient.patch(url, payload);
  },
  restore(id: number): Promise<CommonResponse<null>> {
    const url = '/asset-classification/' + id + '/restore';
    return axiosClient.put(url);
  },
};

export default assetClassificationService;
