import { CommonResponse } from 'models/common';
import axiosClient from 'utils/axiosClient';
import { ApprovalStatus } from './models/approvalStatus';

const approvalStatusService = {
  getAll(): Promise<CommonResponse<{ approval_status: ApprovalStatus[] }>> {
    const url = '/master-data?types=approval_status';
    return axiosClient.get(url);
  },
};

export default approvalStatusService;
