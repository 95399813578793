import { Route, Switch, useRouteMatch } from 'react-router-dom';
import MainMenuPage from './pages/MainMenuPage';

function MainMenu() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <MainMenuPage />
      </Route>
    </Switch>
  );
}

export default MainMenu;
