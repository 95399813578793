import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GlobalModalContext } from '../GlobalModal';
import './style.scss';

export interface ConfirmModalProps {
  title: string;
  message: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

function ConfirmModal({ title, message, onCancel, onConfirm }: ConfirmModalProps) {
  const { t } = useTranslation();
  const { closeGlobalModal } = GlobalModalContext.useGlobalModalContext();

  function handleCancel() {
    onCancel && onCancel();
    closeGlobalModal();
  }

  function handleConfirm() {
    onConfirm && onConfirm();
    closeGlobalModal();
  }

  return (
    <Modal size="sm" className="confirm-modal" show backdrop="static" centered>
      <Modal.Header className="bg-primary justify-content-center">
        <Modal.Title className="fs-6 text-white">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div
          className="confirm-modal__message text-center d-flex justify-content-center align-items-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button variant="light" className="me-3" onClick={handleCancel}>
          {t('Confirm_Modal.cancel')}
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          {t('Confirm_Modal.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
