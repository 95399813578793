import StatusHeader from 'components/common/StatusHeader';
import { useHistory } from 'react-router-dom';
import AssetClassificationFeature from '../components/AssetClassification';
import AssetStatusFeature from '../components/AssetStatus';
import AssetUseFeature from '../components/AssetUse';
import StoragePlaceFeature from '../components/StoragePlace';
import './AdminMasterPage.scss';

function AdminMasterPage() {
  const history = useHistory();

  function handlePageBack() {
    history.push('/');
  }

  return (
    <div className="admin-master-page page-wrapper">
      <div className="page-header">
        <StatusHeader onAction={handlePageBack} />
      </div>
      <div className="page-main">
        <div className="page-content d-flex flex-column">
          <div className="fit-content flex-grow-1 flex-shrink-1">
            <div className="row" style={{ height: '100%' }}>
              <div className="col-2 col-xxl-3 d-flex flex-column" style={{ height: '100%' }}>
                <AssetStatusFeature />
              </div>
              <div className="col-4 col-xxl-3 d-flex flex-column" style={{ height: '100%' }}>
                <AssetClassificationFeature />
              </div>
              <div className="col-4 col-xxl-3 d-flex flex-column" style={{ height: '100%' }}>
                <AssetUseFeature />
              </div>
              <div className="col-2 col-xxl-3 d-flex flex-column" style={{ height: '100%' }}>
                <StoragePlaceFeature />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminMasterPage;
