import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { AssetUse } from './models/assetUse';

const assetUseService = {
  getAll(): Promise<CommonResponse<AssetUse[]>> {
    const url = '/asset-uses';
    return axiosClient.get(url);
  },
  add(name: string, inventoryFlg: boolean): Promise<CommonResponse<any>> {
    const url = '/asset-uses';
    return axiosClient.post(url, { name, inventory_flg: inventoryFlg });
  },
  delete(id: number): Promise<CommonResponse<null>> {
    const url = '/asset-use/' + id;
    return axiosClient.delete(url);
  },
  update(payload: Partial<AssetUse>[]) {
    const url = '/asset-uses';
    return axiosClient.patch(url, payload);
  },
  restore(id: number): Promise<CommonResponse<null>> {
    const url = '/asset-use/' + id + '/restore';
    return axiosClient.put(url);
  },
};

export default assetUseService;
