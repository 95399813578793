import { CommonResponse } from 'models/common';
import axiosClient from 'utils/axiosClient';
import { Company } from './models/company';

const companyService = {
  getAll(dataDate?: string): Promise<CommonResponse<Company[]>> {
    let url = '/companies';
    if (dataDate) {
      url += '?data_date=' + dataDate;
    }
    return axiosClient.get(url);
  },
};

export default companyService;
