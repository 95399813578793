import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './style.scss';

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer-section">
      <Container fluid>
        <span>{t('App.app_name')}</span>
      </Container>
    </div>
  );
}

export default Footer;
