import { Route, Switch, useRouteMatch } from 'react-router-dom';
import InventoryHistoryConfirmation from './pages/InventoryHistoryConfirmation';

export default function InventoryHistoryConfirmationFeature() {
  const { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <InventoryHistoryConfirmation />
        </Route>
      </Switch>
    </div>
  );
}
