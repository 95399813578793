import { Route, Switch, useRouteMatch } from 'react-router-dom';
import MovementHistory from './pages/MovementHistory';

export default function MovementHistoryFeature() {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <MovementHistory />
        </Route>
      </Switch>
    </div>
  );
}
