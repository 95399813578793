export const mainMenuFeatureTranslationJP = {
  MainMenuFeature: {
    todo_list: 'TODOリスト',
    added_date: '追加日',
    item: '項目',
    deadline: '期限',
    work_content: '作業内容',
    asset_search: '資産検索',
    asset_search_note: '資産の状況を確認する時にご利用ください。',
    system_administrators: 'システム管理者用',
    inventory: '棚卸',
    legal_equipment_inspection: '法定設備点検',
    check_inventory_history: '棚卸履歴確認',
    check_movement_history: '移動履歴確認',
    model_master: '機種・型式マスタ',
    asset_detail: '資産新規登録',
    confirmation_of_status_equipment: '法定設備点検履歴確認',
    admin_master: 'マスタ管理',
    deadline_unknown: 'なるはや',
  },
};
