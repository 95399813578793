import { PayloadAction } from '@reduxjs/toolkit';
import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { Company } from 'features/common/models/company';
import { CommonResponse } from 'models';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import authService from './authService';
import { authActions } from './authSlice';
import { Permission } from './models/login';

function* yieldLogout() {
  try {
    yield call(authService.postLogout);
    yield put(authActions.actionLogoutSuccess());
    yield put(push('/login'));
  } catch (e) {
    yield put(authActions.actionLogoutFailed(e as AxiosError));
    yield handleResponseError(e as AxiosError);
  }
}

function* getPermission(action: PayloadAction<string>) {
  try {
    const params = action.payload;
    const response: CommonResponse<Permission> = yield call(authService.getPermission, params);
    yield put(authActions.fetchPermissionSuccess(response));
  } catch (e) {
    yield put(authActions.fetchPermissionFailed());
    yield handleResponseError(e as AxiosError);
  }
}

function* yieldFetchCompanyList() {
  try {
    const response: CommonResponse<Company[]> = yield call(authService.getCompanies);
    yield put(authActions.fetchCompanySuccess(response));
  } catch (e) {
    yield put(authActions.fetchCompanyFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.actionLogout.type, yieldLogout);
  yield takeEvery(authActions.fetchPermission.type, getPermission);
  yield takeLatest(authActions.fetchCompanyList.type, yieldFetchCompanyList);
}
