import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import StatusHeader from 'components/common/StatusHeader';
import { useEffect, useState } from 'react';
import { assetActions, selectAssetList, selectPreDataLoad, selectAssetAdd, selectAssetEdit } from './assetListSlice';
import { selectJobInfo } from 'features/Auth/authSlice';
import TableAsset from './components/TableAsset';
import FormSearch from './components/FormSearch';
import { AssetFormSearch } from 'features/AssetScreen/models/AssetFormSearch';
import { Row, Col } from 'react-bootstrap';
import './scss/AssetListPage.scss';
import { Asset, AssetResponse } from 'features/AssetScreen/models/Asset';
import ExportCSV from './components/ExportCSV';
import { MODAL_TYPES } from 'utils';
import { CommonResponse } from 'models';
import { useTranslation } from 'react-i18next';
import { AlertModalProps } from 'components/common/AlertModal';
import { GlobalModalContext } from 'components/common/GlobalModal';
import { useHistory } from 'react-router-dom';
import { selectPermissionByFeature } from 'features/Auth/authSlice';

function AssetListPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const assetList: AssetResponse = useAppSelector(selectAssetList);
  const assetAdd: CommonResponse<Asset> = useAppSelector(selectAssetAdd);
  const assetEdit: CommonResponse<Asset> = useAppSelector(selectAssetEdit);
  const jobInfo = useAppSelector(selectJobInfo);
  const preDataLoad = useAppSelector(selectPreDataLoad);
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const permissions = useAppSelector(selectPermissionByFeature('asset'));

  function handleShowAlertAddSuccess() {
    showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
      title: t('App.app_name'),
      message: t('AssetScreen.add.status.success'),
    });
  }
  function handleShowAlertEditSuccess() {
    showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
      title: t('App.app_name'),
      message: t('AssetScreen.edit.status.success'),
    });
  }
  useEffect(() => {
    if (assetEdit.status && assetEdit.status === 'success') {
      handleShowAlertEditSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetEdit]);

  useEffect(() => {
    if (assetAdd.status && assetAdd.status === 'success') {
      handleShowAlertAddSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetAdd]);
  const defaultParamsQuery: AssetFormSearch = {
    asset_management_no: '',
    restaurant_cd: '',
    restaurant_name: '',
    current_storage_place_id: '',
    current_possession_company_cd: '',
    current_possession_department_cd: '',
    current_possession_employee_cd: '',
    current_possession_department_name: '',
    current_possession_employee_name: '',
    classification_id: '',
    current_use_id: '',
    current_status_id: '',
    specific_data_date: moment(new Date()).format('YYYY-MM-DD'),
    from_data_date: moment(new Date()).format('YYYY-MM-DD'),
    to_data_date: moment(new Date()).format('YYYY-MM-DD'),
    option_search_date: '1',
    obsolete_department_asset_flg: false,
    accounting_no: '',
    accounting_class_id: '',
    choose_department_id: jobInfo?.department_id,
    choose_company_cd: jobInfo?.company_cd,
  };
  const [paramsQuery, setParamsQuery] = useState(defaultParamsQuery);

  useEffect(() => {
    dispatch(assetActions.fetchPreLoad());
    const query = Object.entries(paramsQuery)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    dispatch(assetActions.fetchAssetList(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageBack = () => {
    history.push('/');
  };

  return (
    <div className="page-wrapper">
      <div className="page-header">
        <StatusHeader actionName="back" onAction={handlePageBack} />
      </div>
      <div className="page-main">
        <div className="page-content">
          <div className="d-flex flex-column">
            <div className="flex-grow-0">
              <FormSearch paramsQuery={paramsQuery} setParamsQuery={setParamsQuery} preDataLoad={preDataLoad} />
              <Row className="mb-3">
                <Col>
                  <div>
                    <span className="d-inline-block me-4">{t('MovementHistoryFeature.Text.search_result')}</span>
                    <span>
                      {assetList.data.length} &nbsp;
                      {t('MovementHistoryFeature.Text.subject')}
                    </span>
                  </div>
                </Col>
                {permissions && permissions.includes('show_btn_csv') && (
                  <Col className="d-flex justify-content-end">
                    <div className="align-self-end">
                      <ExportCSV assets={assetList} />
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </div>
          <TableAsset assets={assetList} />
        </div>
      </div>
    </div>
  );
}

export default AssetListPage;
