import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AxiosError } from 'axios';
import { AlertModalProps } from 'components/common/AlertModal';
import { ConfirmModalProps } from 'components/common/ConfirmModal';
import { GlobalModalContext } from 'components/common/GlobalModal';
import assetClassificationService from 'features/AdminMaster/assetClassificationService';
import {
  assetClassificationActions,
  selectAssetClassificationList,
} from 'features/AdminMaster/assetClassificationSlice';
import { AssetClassification } from 'features/AdminMaster/models/assetClassification';
import { CommonResponse } from 'models';
import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPES } from 'utils';
import { handleServiceError } from 'utils/common';

function AssetClassificationFeature() {
  const dispatch = useAppDispatch();
  const [validated, setValidated] = useState(false);
  const { t } = useTranslation();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();

  //state
  const assetClassificationList = useAppSelector(selectAssetClassificationList);

  function displayError(error: AxiosError) {
    if (error) {
      const errorData = error.response?.data as CommonResponse<any>;
      if (!errorData.data) {
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: errorData.message,
          title: t('App.error'),
        });
      } else {
        let errorMessage = '';
        for (const property in errorData.data) {
          errorMessage += errorData.data[property].join('<br />');
        }

        if (errorMessage.length > 0) {
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: errorMessage,
            title: t('App.error'),
          });
        }
      }
    }
  }

  useEffect(() => {
    dispatch(assetClassificationActions.fetchAssetClassificationList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget as HTMLFormElement;
    if (!form.checkValidity()) {
      setValidated(true);
    } else {
      const cd = form['asset_classification_cd'].value.trim();
      const name = form['asset_classification_name'].value.trim();

      (async () => {
        try {
          await assetClassificationService.add(cd, name);
          dispatch(assetClassificationActions.fetchAssetClassificationList());
          form.reset();
          setValidated(false);
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('AdminMasterFeature.save_data_successful'),
            title: t('App.info'),
          });
        } catch (e) {
          if (!handleServiceError(showGlobalModal, e as AxiosError)) {
            displayError(e as AxiosError);
            setValidated(true);
          }
        }
      })();
    }
  }

  function handleInputNameBlur() {
    setValidated(false);
  }

  function handleInputCdBlur() {
    setValidated(false);
  }

  function handleChangeName(e: React.ChangeEvent<HTMLInputElement>) {
    const errorHelperElem = e.currentTarget.parentElement?.querySelector('.error-helper');
    if (errorHelperElem) errorHelperElem.innerHTML = '';
  }

  function handleChangeCd(e: React.ChangeEvent<HTMLInputElement>) {
    const errorHelperElem = e.currentTarget.parentElement?.querySelector('.error-helper');
    if (errorHelperElem) errorHelperElem.innerHTML = '';
  }

  function handleUpdate(rowIndex: number) {
    const inputCdElem = document.querySelector('#asset-classification-cd-' + rowIndex) as HTMLInputElement;
    const inputNameElem = document.querySelector('#asset-classification-name-' + rowIndex) as HTMLInputElement;
    if (inputCdElem && inputNameElem) {
      const cd = inputCdElem.value.trim();
      const name = inputNameElem.value.trim();
      const data: Partial<AssetClassification> = {
        id: assetClassificationList[rowIndex].id,
        name: name,
        cd: cd,
      };

      (async () => {
        try {
          await assetClassificationService.update([data]);
          dispatch(assetClassificationActions.fetchAssetClassificationList());
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('AdminMasterFeature.save_data_successful'),
            title: t('App.info'),
          });
        } catch (e) {
          if (!handleServiceError(showGlobalModal, e as AxiosError)) {
            const errorResponse = (e as AxiosError).response?.data as CommonResponse<any>;
            const errorData = errorResponse.data.error_data;
            const rowError = document.querySelector('#asset-status-row-' + errorData.id);
            if (rowError) {
              rowError.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
              });
              for (const errorKey in errorResponse.data.errors) {
                const errorControlTextElem = rowError.querySelector('.error-' + errorKey);
                if (errorControlTextElem) {
                  errorControlTextElem.innerHTML = errorResponse.data.errors[errorKey];
                }
              }
            }
          }
        }
      })();
    }
  }

  function handleDelete(rowIndex: number) {
    if (assetClassificationList[rowIndex]) {
      showGlobalModal<ConfirmModalProps>(MODAL_TYPES.CONFIRM_MODAL, {
        title: t('App.warning'),
        message: t('AdminMasterFeature.confirm_delete'),
        onConfirm: function () {
          (async () => {
            try {
              await assetClassificationService.delete(assetClassificationList[rowIndex].id);
              dispatch(assetClassificationActions.fetchAssetClassificationList());
              showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
                message: t('AdminMasterFeature.save_data_successful'),
                title: t('App.info'),
              });
            } catch (e) {
              if (!handleServiceError(showGlobalModal, e as AxiosError)) {
                displayError(e as AxiosError);
              }
            }
          })();
        },
      });
    }
  }

  function handleRestore(rowIndex: number) {
    if (assetClassificationList[rowIndex]) {
      showGlobalModal<ConfirmModalProps>(MODAL_TYPES.CONFIRM_MODAL, {
        title: t('App.warning'),
        message: t('AdminMasterFeature.confirm_restore'),
        onConfirm: function () {
          (async () => {
            try {
              await assetClassificationService.restore(assetClassificationList[rowIndex].id);
              dispatch(assetClassificationActions.fetchAssetClassificationList());
              showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
                message: t('AdminMasterFeature.save_data_successful'),
                title: t('App.info'),
              });
            } catch (e) {
              if (!handleServiceError(showGlobalModal, e as AxiosError)) {
                displayError(e as AxiosError);
              }
            }
          })();
        },
      });
    }
  }

  return (
    <>
      <Table bordered hover className="mb-0">
        <thead className="bg-green">
          <tr>
            <th colSpan={3}>{t('AdminMasterFeature.asset_classification_head_title')}</th>
          </tr>
          <tr>
            <th style={{ width: '25%' }}>{t('AdminMasterFeature.classification_code')}</th>
            <th style={{ width: '45%' }}>{t('AdminMasterFeature.name')}</th>
            <th style={{ width: '30%' }}>{t('AdminMasterFeature.action')}</th>
          </tr>
        </thead>
      </Table>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Table bordered className="mb-0" style={{ borderTop: '1px solid #fff' }}>
          <tbody>
            <tr>
              <td style={{ width: '25%' }}>
                <InputGroup className="flex-grow-1 flex-shrink-1 me-4" size="sm" hasValidation>
                  <Form.Control
                    id="asset_classification_cd"
                    name="asset_classification_cd"
                    type="text"
                    required
                    onBlur={handleInputCdBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t('AdminMasterFeature.warning_required_field')}
                  </Form.Control.Feedback>
                </InputGroup>
              </td>
              <td style={{ width: '45%' }}>
                <InputGroup className="flex-grow-1 flex-shrink-1 me-4" size="sm" hasValidation>
                  <Form.Control
                    id="asset-classification-name"
                    name="asset_classification_name"
                    type="text"
                    required
                    onBlur={handleInputNameBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t('AdminMasterFeature.warning_required_field')}
                  </Form.Control.Feedback>
                </InputGroup>
              </td>
              <td style={{ width: '30%' }}>
                <Button type="submit" variant="primary" size="sm" className="btn-action">
                  {t('AdminMasterFeature.sign_up')}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
      <div className="fit-content flex-grow-1 flex-shrink-1">
        <Table bordered className="mb-0" style={{ borderTop: '1px solid #fff' }}>
          <tbody>
            {assetClassificationList.map(function (item, index) {
              return (
                <tr
                  id={`asset-classification-row-${item.id}`}
                  key={item.id}
                  className={item.stopped_flg ? 'table-secondary' : ''}
                >
                  <td style={{ width: '25%' }}>
                    <Form.Control
                      id={`asset-classification-cd-${index}`}
                      defaultValue={item.cd}
                      data-index={index}
                      onChange={handleChangeCd}
                      size="sm"
                    ></Form.Control>
                    <span className="error-helper error-cd small text-danger"></span>
                  </td>
                  <td style={{ width: '45%' }}>
                    <Form.Control
                      id={`asset-classification-name-${index}`}
                      defaultValue={item.name}
                      data-index={index}
                      onChange={handleChangeName}
                      size="sm"
                    ></Form.Control>
                    <span className="error-helper error-name small text-danger"></span>
                  </td>
                  <td style={{ width: '30%' }}>
                    <Button className="btn--green btn-action me-1" size="sm" onClick={handleUpdate.bind(null, index)}>
                      {t('AdminMasterFeature.change')}
                    </Button>
                    {!item.stopped_flg && (
                      <Button
                        className="btn--red btn-action"
                        size="sm"
                        disabled={!item.can_delete}
                        onClick={handleDelete.bind(null, index)}
                      >
                        {t('AdminMasterFeature.stop')}
                      </Button>
                    )}
                    {item.stopped_flg && (
                      <Button className="btn--yellow btn-action" size="sm" onClick={handleRestore.bind(null, index)}>
                        {t('AdminMasterFeature.valid')}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default AssetClassificationFeature;
