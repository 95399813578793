import { useAppSelector } from 'app/hooks';
import { selectAppLoading } from 'features/common/appSlice';
import { useTranslation } from 'react-i18next';
import './style.scss';

function LoadingIndicator() {
  const { t } = useTranslation();
  const appLoading = useAppSelector(selectAppLoading);

  return appLoading ? (
    <div className="blockui">
      <div className="blockui-wrapper">
        <div className="text-center">
          <h6 className="mb-0">{t('App.loading')}</h6>
        </div>
      </div>
    </div>
  ) : null;
}

export default LoadingIndicator;
