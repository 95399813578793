import React, { useRef, createContext, useContext, useCallback, useSyncExternalStore } from 'react';
import { AlertModalProps } from '../AlertModal';
import { ConfirmModalProps } from '../ConfirmModal';
import { DepartmentSearchModalProps } from '../DepartmentSearchModal';
import { EmployeeSearchModalProps } from '../EmployeeSearchModal';
import { AssetImageModalProps } from '../AssetImageModal';

export type GlobalModalComponentType =
  | AlertModalProps
  | ConfirmModalProps
  | DepartmentSearchModalProps
  | EmployeeSearchModalProps
  | AssetImageModalProps;
export type GlobalModalStoreType<T> = {
  modalType: string | null;
  modalProps: T;
};
export type GlobalModalStore = GlobalModalStoreType<GlobalModalComponentType>;

export default function createGlobalModalContext(initialState: GlobalModalStore) {
  function useGlobalModalStoreData(): {
    get: () => GlobalModalStore;
    set: (value: Partial<GlobalModalStore>) => void;
    showGlobalModal: <T extends GlobalModalComponentType>(modalType: string, modalProps: T) => void;
    closeGlobalModal: () => void;
    subscribe: (callback: () => void) => () => void;
  } {
    const store = useRef(initialState);

    const get = useCallback(() => store.current, []);

    const subscribers = useRef(new Set<() => void>());

    const set = useCallback((value: Partial<GlobalModalStore>) => {
      store.current = { ...store.current, ...value };
      subscribers.current.forEach((callback) => callback());
    }, []);

    const showGlobalModal = useCallback(<T extends GlobalModalComponentType>(modalType: string, modalProps: T) => {
      store.current = { ...store.current, modalType, modalProps };
      subscribers.current.forEach((callback) => callback());
    }, []);

    const closeGlobalModal = useCallback(() => {
      store.current = {
        ...store.current,
        modalType: null,
        modalProps: {
          title: '',
          message: '',
        },
      };
      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback: () => void) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    return {
      get,
      set,
      showGlobalModal,
      closeGlobalModal,
      subscribe,
    };
  }

  type UseGlobalModalStoreDataReturnType = ReturnType<typeof useGlobalModalStoreData>;

  const StoreContext = createContext<UseGlobalModalStoreDataReturnType | null>(null);

  function GlobalModalProvider({ children }: { children: React.ReactNode }) {
    return <StoreContext.Provider value={useGlobalModalStoreData()}>{children}</StoreContext.Provider>;
  }

  function useGlobalModalState<SelectorOutput>(
    selector: (store: GlobalModalStore) => SelectorOutput
  ): [SelectorOutput, (value: Partial<GlobalModalStore>) => void] {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error('Store not found');
    }

    const state = useSyncExternalStore(store.subscribe, () => selector(store.get()));

    return [state, store.set];
  }

  function useGlobalModalContext(): {
    showGlobalModal: <T extends GlobalModalComponentType>(modeType: string, modalProps: T) => void;
    closeGlobalModal: () => void;
  } {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error('Store not found');
    }

    return {
      showGlobalModal: store.showGlobalModal,
      closeGlobalModal: store.closeGlobalModal,
    };
  }

  return {
    GlobalModalProvider,
    useGlobalModalState,
    useGlobalModalContext,
  };
}
