export const adminMasterFeatureTranslationJP = {
  AdminMasterFeature: {
    sign_up: '新規登録',
    name: '名称',
    action: '操作',
    change: '変更',
    stop: '停止',
    valid: '有効',

    //warning
    confirm_delete: '停止してもよろしいですか？',
    save_data_successful: 'データを正常に保存',
    no_data_changed: 'データは変更されていません',
    confirm_restore: 'データを回復してもよろしいですか?',

    //form
    warning_required_field: 'この項目は必須です',

    //asset_status
    asset_status_headtitle: '資産ステータスマスタ',

    //asset_classification
    classification_code: 'コード',
    asset_storage_place_headtitle: '保管場所マスタ',
    asset_use_head_title: '資産用途マスタ',
    asset_classification_head_title: '資産分類マスタ',

    //asset_use
    is_inventory: '棚卸対象',
  },
};
