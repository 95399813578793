import { OptionType } from './models/optionType';

export const ASSET_USE_INVENTORY_FLG_SOURCE: {
  [key: string]: Array<OptionType>;
} = {
  en: [
    {
      code: 1,
      label: 'Shelf target',
    },
    {
      code: 0,
      label: 'Out of shelf',
    },
  ],
  jp: [
    {
      code: 1,
      label: '棚対象',
    },
    {
      code: 0,
      label: '棚対象外',
    },
  ],
};
