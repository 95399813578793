import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ERROR_NOTIFICATION_CODES } from 'utils';

export interface errorNotificationState {
  isOpen: boolean;
  error: AxiosError | null;
}

const initialState: errorNotificationState = {
  isOpen: false,
  error: null,
};

const errorNotificationSlice = createSlice({
  name: 'error_notification',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<AxiosError>) {
      if (ERROR_NOTIFICATION_CODES.includes(Number(action.payload.response?.status))) {
        state.isOpen = true;
        state.error = action.payload;
      }
    },
    hideError(state) {
      state.isOpen = false;
      state.error = null;
    },
  },
});

// Actions
export const errorNotificationActions = errorNotificationSlice.actions;

// Selectors
export const selectErrorNotificationIsOpen = (state: RootState) => state.errorNotification.isOpen;
export const selectErrorNotificationError = (state: RootState) => state.errorNotification.error;

// Reducer
export const errorNotificationReducer = errorNotificationSlice.reducer;
