import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import {
  InventoryApprovalHistory,
  InventoryDetailPayload,
  InventoryInfo,
  InventoryMasterDataInfo,
  InventorySearchPayload,
  InventoryStatusInfo,
  InventoryStatusPayload,
  ModifiedInventoryDetailPayload,
  InventoryFinalConfirmationFlagPayload,
} from './models/inventory';

const inventoryService = {
  getInventoryList(params: InventorySearchPayload): Promise<CommonResponse<InventoryInfo>> {
    const url = `inventories`;
    return axiosClient.get(url, { params });
  },
  createInventoryList(params: InventoryDetailPayload): Promise<CommonResponse<null>> {
    const url = `inventories`;
    return axiosClient.post(url, params);
  },
  updateInventoryList(params: ModifiedInventoryDetailPayload[]): Promise<CommonResponse<null>> {
    const url = `inventories`;
    return axiosClient.put(url, { data: params });
  },
  validateInventory(params: string): Promise<CommonResponse<null>> {
    const url = `inventory-validation?${params}`;
    return axiosClient.get(url);
  },
  getInventoryApprovalHistoryList(params: string): Promise<CommonResponse<InventoryApprovalHistory[]>> {
    const url = `inventory-approval-histories?${params}`;
    return axiosClient.get(url);
  },
  getInventoryMasterData(): Promise<CommonResponse<InventoryMasterDataInfo>> {
    const url = `master-data?types=inventory_status`;
    return axiosClient.get(url);
  },
  updateInventoryStatus(params: InventoryStatusPayload): Promise<CommonResponse<null>> {
    const url = `inventory-status`;
    return axiosClient.put(url, params);
  },
  updateInventoryFinalConfirmationFlg(params: InventoryFinalConfirmationFlagPayload): Promise<CommonResponse<null>> {
    const url = `inventory-final-confirmation-flg`;
    return axiosClient.put(url, params);
  },
  getInventoryStatusList(params: InventorySearchPayload): Promise<CommonResponse<InventoryStatusInfo>> {
    const url = `inventory-status-list`;
    return axiosClient.get(url, { params });
  },
  exportInventoryDetail(params: InventorySearchPayload): Promise<any> {
    const query = Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    const url = `export-inventory-detail?${query}`;
    return axiosClient.get(url, { responseType: 'blob' }).then((response: any) => {
      // const regExpFilename = /filename="(?<filename>.*)"/;
      // const filename = regExpFilename.exec(response.headers['content-disposition'])?.groups?.filename ?? null;
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `InventoryDetail_${params.year + '' + params.month}.pdf`);
      document.body.appendChild(link);
      link.click();
      return response;
    });
  },
};

export default inventoryService;
