import { AxiosError } from 'axios';
import { DepartmentSearchModalProps, DepartmentSearchModalReturnData } from 'components/common/DepartmentSearchModal';
import { GlobalModalContext } from 'components/common/GlobalModal';
import StatusHeader from 'components/common/StatusHeader';
import companyService from 'features/common/companyService';
import { Company } from 'features/common/models/company';
import moment from 'moment';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MODAL_TYPES } from 'utils';
import { getInspectionStateDisplay, handleServiceError } from 'utils/common';
import ExportCSV from '../components/ExportCSV';
import inspectionHistoryService from '../inspectionHistoryService';
import { InspectionHistory, InspectionHistoryDisplay } from '../models/inspectionHistory';
import { getDepartmentInfo } from '../utils';
import './InspectionHistoryPage.scss';
import CustomDatePicker from 'components/common/DatePickerCustom/CustomDatePicker';
import CustomInputDatePicker from '../components/CustomInputDatePicker';

function InspectionHistoryPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();

  // state
  const [startDate, setStartDate] = useState(new Date());
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [inspectionHistory, setInspectionHistory] = useState<InspectionHistory[]>([]);

  // ref
  const searchCompanycdRef = useRef<HTMLSelectElement>(null);
  const searchDepartmentcdRef = useRef<HTMLInputElement>(null);
  const searchDepartmentNameRef = useRef<HTMLInputElement>(null);
  const searchStateNormalRef = useRef<HTMLInputElement>(null);
  const searchStateAbnormalRef = useRef<HTMLInputElement>(null);

  //useEffect
  //load master data companyList
  useEffect(() => {
    (async () => {
      try {
        var lastDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
        const response = await companyService.getAll(moment(lastDayOfMonth).format('YYYY-MM-DD'));
        setCompanyList(response.data);
      } catch (error) {
        handleServiceError(showGlobalModal, error as AxiosError);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    (async () => {
      try {
        const response = await inspectionHistoryService.getAll({
          month: startDate.getMonth() + 1,
          year: startDate.getFullYear(),
          state_normal: searchStateNormalRef.current ? searchStateNormalRef.current.checked : false,
          state_abnormal: searchStateAbnormalRef.current ? searchStateAbnormalRef.current.checked : false,
        });
        setInspectionHistory(response.data);
      } catch (e) {
        handleServiceError(showGlobalModal, e as AxiosError);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // callback
  function handlePageBack() {
    history.push('/');
  }

  function handleSearchSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const searchMonth = startDate.getMonth() + 1;
    const searchYear = startDate.getFullYear();
    const searchCompanycd = searchCompanycdRef.current?.value;
    const searchDepartmentcd = searchDepartmentcdRef.current?.value.trim();
    const searchDepartmentName = searchDepartmentNameRef.current?.value.trim();
    const searchStateNormal = searchStateNormalRef.current?.checked || false;
    const searchStateAbnormal = searchStateAbnormalRef.current?.checked || false;

    (async () => {
      try {
        const response = await inspectionHistoryService.getAll({
          month: searchMonth,
          year: searchYear,
          company_cd: searchCompanycd,
          department_cd: searchDepartmentcd,
          department_name: searchDepartmentName,
          state_normal: searchStateNormal,
          state_abnormal: searchStateAbnormal,
        });
        setInspectionHistory(response.data);
      } catch (e) {
        handleServiceError(showGlobalModal, e as AxiosError);
      }
    })();
  }

  function handleDateChanged(newDate: Date) {
    setStartDate(newDate);

    //reset search department
    if (searchDepartmentcdRef.current) searchDepartmentcdRef.current.value = '';
    if (searchDepartmentNameRef.current) searchDepartmentNameRef.current.value = '';
  }

  function handleShowDepartmentSearchModal() {
    var lastDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

    showGlobalModal<DepartmentSearchModalProps>(MODAL_TYPES.DEPARTMENT_SEARCH_MODAL, {
      dataDate: moment(lastDayOfMonth).format('YYYY-MM-DD'),
      companycd: (searchCompanycdRef.current && searchCompanycdRef.current.value) || '',
      departmentcd: (searchDepartmentcdRef.current && searchDepartmentcdRef.current.value) || '',
      onReflect: (data: DepartmentSearchModalReturnData) => {
        if (searchCompanycdRef.current) {
          searchCompanycdRef.current.value = data.company.cd;
        }
        if (searchDepartmentcdRef.current) {
          searchDepartmentcdRef.current.value = data.cd;
        }
        if (searchDepartmentNameRef.current) {
          searchDepartmentNameRef.current.value = data.name;
        }
      },
    });
  }

  function searchCompanyChanged() {
    if (searchDepartmentcdRef.current) {
      searchDepartmentcdRef.current.value = '';
    }
    if (searchDepartmentNameRef.current) {
      searchDepartmentNameRef.current.value = '';
    }
  }

  // compose data for render and export
  const dataRender: InspectionHistoryDisplay[] = [];
  for (const item of inspectionHistory) {
    const departmentInfo = getDepartmentInfo(item);
    dataRender.push({
      month: item.month.toString().slice(0, 4) + '/' + item.month.toString().slice(4, 6),
      company_name: item.company_name,
      area: departmentInfo.area,
      block: departmentInfo.block,
      department_cd: item.department_cd,
      department_name: item.department_name,
      management_no: item.management_no,
      classification_name: item.classification_name,
      manufacturer_name: item.manufacturer_name,
      model_name: item.model_name,
      extreme_rust_state: getInspectionStateDisplay(item.extreme_rust_state),
      installation_state: getInspectionStateDisplay(item.installation_state),
      dripping_state: getInspectionStateDisplay(item.dripping_state),
      unusual_sound_state: getInspectionStateDisplay(item.unusual_sound_state),
      abnormal_vibration_state: getInspectionStateDisplay(item.abnormal_vibration_state),
      operating_state: getInspectionStateDisplay(item.operating_state),
      comment: item.comment,
    });
  }

  return (
    <div className="leih-page page-wrapper">
      <div className="page-header">
        <StatusHeader onAction={handlePageBack} />
      </div>
      <div className="page-main">
        <div className="page-content d-flex flex-column">
          <div className="mb-3">
            <Form onSubmit={handleSearchSubmit}>
              <div className="bg-main p-3 rounded">
                <div className="d-flex flex-row mb-3 ">
                  <Form.Label className="col-form-label me-3 fw-bold text-primary" style={{ minWidth: '5rem' }}>
                    {t('InspectionHistoryFeature.search_condition')}
                  </Form.Label>
                  <Form.Label className="col-form-label text-danger">
                    {t('InspectionHistoryFeature.required_field')}
                  </Form.Label>
                </div>
                <div className="d-flex flex-row mb-3 ">
                  <div className="mb-3 me-3">
                    <Form.Label className="col-form-label me-3" style={{ minWidth: '5rem' }}>
                      <span className="text-danger">※</span>
                      <span>{t('InspectionHistoryFeature.implementation_month')}</span>
                    </Form.Label>
                    <div className="search-month d-inline-block">
                      <CustomDatePicker
                        value={startDate}
                        onChange={handleDateChanged}
                        customInputComponent={<CustomInputDatePicker />}
                        showMonthYearPicker={true}
                        dateFormat="yyyy/MM"
                      />
                    </div>
                  </div>
                </div>
                <div className="row g-3 align-items-center mb-3">
                  <div className="col-auto row">
                    <Form.Label column sm="auto" className="col-form-label me-1" style={{ minWidth: '5em' }}>
                      {t('InspectionHistoryFeature.affiliation_selection')}
                    </Form.Label>
                    <Col sm="auto">
                      <Form.Select
                        ref={searchCompanycdRef}
                        className="d-inline-block w-auto"
                        style={{ minWidth: '200px' }}
                        onChange={searchCompanyChanged}
                      >
                        <option value="">{t('Department_Search_Modal.select_company')}</option>
                        {companyList.map((company) => {
                          return (
                            <option key={company.id} value={company.cd}>
                              {company.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                  </div>
                  <div className="col-auto row">
                    <Form.Label column sm="auto" className="col-form-label">
                      {t('InspectionHistoryFeature.affiliation_code')}
                    </Form.Label>
                    <Col sm="auto">
                      <Form.Control
                        ref={searchDepartmentcdRef}
                        className="d-inline-block"
                        style={{ maxWidth: '200px' }}
                      ></Form.Control>
                    </Col>
                  </div>
                  <div className="col-auto row">
                    <Form.Label column sm="auto" className="col-form-label">
                      {t('InspectionHistoryFeature.affiliation_name')}
                    </Form.Label>
                    <Col sm="auto">
                      <Form.Control
                        ref={searchDepartmentNameRef}
                        className="d-inline-block"
                        style={{ maxWidth: '200px' }}
                      ></Form.Control>
                    </Col>
                  </div>
                  <div className="col-auto row">
                    <Button type="button" variant="primary" onClick={handleShowDepartmentSearchModal}>
                      {t('InspectionHistoryFeature.affiliation_search')}
                    </Button>
                  </div>
                </div>
                <div className="d-flex flex-row mb-3">
                  <Form.Label className="col-form-label me-3" style={{ minWidth: '5rem' }}>
                    {t('InspectionHistoryFeature.abnormality')}
                  </Form.Label>
                  <div>
                    <Form.Check
                      ref={searchStateAbnormalRef}
                      className="col-form-label d-inline-block me-3"
                      type="checkbox"
                      defaultChecked
                      id="search-state-normal"
                      label={t('InspectionHistoryFeature.normal')}
                    />
                    <Form.Check
                      ref={searchStateNormalRef}
                      className="col-form-label d-inline-block"
                      type="checkbox"
                      defaultChecked
                      id="search-state-abnormal"
                      label={t('InspectionHistoryFeature.abnormal')}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <Button type="submit" variant="primary">
                    {t('InspectionHistoryFeature.search')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>

          <div className="mb-3 text-end">
            <ExportCSV data={dataRender} />
          </div>
          <div className="sticky-table flex-grow-1 flex-shrink-1">
            <Table hover>
              <thead>
                <tr>
                  <th>{t('InspectionHistoryFeature.month_of_inspection')}</th>
                  <th>{t('InspectionHistoryFeature.company_name')}</th>
                  <th>{t('InspectionHistoryFeature.area_sales_department')}</th>
                  <th>{t('InspectionHistoryFeature.block_area')}</th>
                  <th>{t('InspectionHistoryFeature.store_code')}</th>
                  <th>{t('InspectionHistoryFeature.store_name')}</th>
                  <th>{t('InspectionHistoryFeature.asset_management_number')}</th>
                  <th>{t('InspectionHistoryFeature.device_name')}</th>
                  <th>{t('InspectionHistoryFeature.manufacturer')}</th>
                  <th>{t('InspectionHistoryFeature.model')}</th>
                  <th>{t('InspectionHistoryFeature.extreme_rust')}</th>
                  <th>{t('InspectionHistoryFeature.installation_state')}</th>
                  <th>{t('InspectionHistoryFeature.liquid_sauce')}</th>
                  <th>{t('InspectionHistoryFeature.unusual_sound')}</th>
                  <th>{t('InspectionHistoryFeature.abnormal_vibration')}</th>
                  <th>{t('InspectionHistoryFeature.operating_state')}</th>
                  <th>{t('InspectionHistoryFeature.remarks')}</th>
                </tr>
              </thead>
              <tbody>
                {dataRender.map(function (item, rowIndex) {
                  return (
                    <tr key={rowIndex}>
                      <td>{item.month}</td>
                      <td>{item.company_name}</td>
                      <td>{item.area}</td>
                      <td>{item.block}</td>
                      <td>{item.department_cd}</td>
                      <td>{item.department_name}</td>
                      <td>{item.management_no}</td>
                      <td>{item.classification_name}</td>
                      <td>{item.manufacturer_name}</td>
                      <td>{item.model_name}</td>
                      <td>{item.extreme_rust_state}</td>
                      <td>{item.installation_state}</td>
                      <td>{item.dripping_state}</td>
                      <td>{item.unusual_sound_state}</td>
                      <td>{item.abnormal_vibration_state}</td>
                      <td>{item.operating_state}</td>
                      <td>{item.comment}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InspectionHistoryPage;
