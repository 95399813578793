import { PayloadAction } from '@reduxjs/toolkit';
import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import inventoryService from './inventoryHistoryConfirmationService';
import { inventoryHistoryActions } from './inventoryHistoryConfirmationSlice';
import { Inventory, Department, SearchInventoryHistory, SearchDepartment } from './models/inventory';
import { CommonResponse } from 'models';

function* getInventoryList(action: PayloadAction<SearchInventoryHistory>) {
  try {
    const params = action.payload;
    if (params.fake_api_call === true) {
      yield put(inventoryHistoryActions.fetchInventoryFailed());
    } else {
      const response: CommonResponse<Array<Inventory>> = yield call(inventoryService.getInventoryList, params);
      yield put(inventoryHistoryActions.fetchInventorySuccess(response));
    }
  } catch (e) {
    yield put(inventoryHistoryActions.fetchInventoryFailed());
    yield handleResponseError(e as AxiosError);
  }
}

function* getSaleDepartmentList(action: PayloadAction<SearchDepartment>) {
  try {
    const params = action.payload;
    const response: CommonResponse<Array<Department>> = yield call(inventoryService.getDepartmentList, params);
    yield put(inventoryHistoryActions.fetchSaleDepartmentSuccess(response));
  } catch (e) {
    yield put(inventoryHistoryActions.fetchSaleDepartmentFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* inventoryHistorySaga() {
  yield takeLatest(inventoryHistoryActions.fetchInventory.type, getInventoryList);
  yield takeEvery(inventoryHistoryActions.fetchSaleDepartment.type, getSaleDepartmentList);
}
