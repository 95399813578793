import { useAppSelector } from 'app/hooks';
import { AxiosError } from 'axios';
import { selectCompanyList } from 'features/common/companySlice';
import * as HttpStatus from 'http-status-codes';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { handleServiceError } from 'utils/common';
import { GlobalModalContext } from '../GlobalModal';
import departmentService from './service';
import './style.scss';

export interface DepartmentSearchModalReturnData {
  id: number;
  cd: string;
  name: string;
  company: {
    id: number;
    cd: string;
    name: string;
  };
}

export interface DepartmentSearchModalProps {
  dataDate?: string;
  companycd?: string;
  departmentcd?: string;
  onClose?: () => void;
  onReflect?: (data: DepartmentSearchModalReturnData) => void;
}

function DepartmentSearchModal({
  dataDate = undefined,
  companycd = '',
  departmentcd = '',
  onClose,
  onReflect,
}: DepartmentSearchModalProps) {
  const { t } = useTranslation();
  const { showGlobalModal, closeGlobalModal } = GlobalModalContext.useGlobalModalContext();

  //ref
  const tableDataRef = useRef<HTMLDivElement>(null);
  const buttonReflectRef = useRef<HTMLButtonElement>(null);

  //state
  const companyList = useAppSelector(selectCompanyList);
  const [departmentList, setDepartmentList] = useState<DepartmentSearchModalReturnData[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await departmentService.getAll({
          data_date: dataDate,
          company_cd: companycd && companycd.trim().length > 0 ? companycd : undefined,
          department_cd: departmentcd && departmentcd.trim().length > 0 ? departmentcd : undefined,
        });
        setDepartmentList(response.data);
        if (buttonReflectRef.current) buttonReflectRef.current.disabled = true;
      } catch (e) {
        if ((e as AxiosError).response?.status === HttpStatus.StatusCodes.UNAUTHORIZED) {
          closeGlobalModal();
        } else {
          handleServiceError(showGlobalModal, e as AxiosError);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClose() {
    onClose && onClose();
    closeGlobalModal();
  }

  function handleReflect() {
    let selectedRowIndex = -1;

    const selectedInput = document.querySelector('input[name="dsm-selected"]:checked') as HTMLInputElement;
    if (selectedInput) selectedRowIndex = Number(selectedInput.value);

    if (selectedRowIndex >= 0) {
      onReflect && onReflect(departmentList[selectedRowIndex]);
      closeGlobalModal();
    }
  }

  function handleSearchSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const companycd = e.currentTarget['company_cd'].value;
    const departmentcd = (e.currentTarget['department_cd'].value as string).trim();
    const departmentName = (e.currentTarget['department_name'].value as string).trim();

    (async () => {
      try {
        const response = await departmentService.getAll({
          data_date: dataDate,
          company_cd: companycd,
          department_cd: departmentcd,
          department_name: departmentName,
        });
        setDepartmentList(response.data);
        if (buttonReflectRef.current) buttonReflectRef.current.disabled = true;

        const selectedInput = document.querySelector('input[name="dsm-selected"]:checked') as HTMLInputElement;
        if (selectedInput) selectedInput.checked = false;
      } catch (e) {
        if ((e as AxiosError).response?.status === HttpStatus.StatusCodes.UNAUTHORIZED) {
          closeGlobalModal();
        } else {
          handleServiceError(showGlobalModal, e as AxiosError);
        }
      }
    })();
  }

  useEffect(() => {
    const rows = tableDataRef.current?.querySelectorAll('tbody > tr');
    function rowEventClick(rowEvent: any) {
      const radioInput = rowEvent.currentTarget.querySelector('.form-check-input');
      if (radioInput) {
        radioInput.checked = true;
        if (buttonReflectRef.current) buttonReflectRef.current.disabled = false;
      }
    }
    if (rows) {
      rows.forEach((row) => {
        (row as HTMLTableRowElement).addEventListener('click', rowEventClick);
      });
    }

    return () => {
      if (rows) {
        rows.forEach((row) => {
          row.removeEventListener('click', rowEventClick);
        });
      }
    };
  }, [departmentList]);

  return (
    <Modal className="department-search-modal" show backdrop="static" centered>
      <Modal.Header className="bg-primary justify-content-center">
        <Modal.Title className="fs-6 text-white">{t('Department_Search_Modal.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className="mb-4" onSubmit={handleSearchSubmit}>
          <Row className="mb-3">
            <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="dsm-company">
              {t('Department_Search_Modal.company')}
            </Form.Label>
            <Col xs sm="6">
              <Form.Select id="dsm-company" name="company_cd" defaultValue={companycd}>
                <option value="">{t('Department_Search_Modal.select_company')}</option>
                {companyList.map((company) => {
                  return (
                    <option key={company.id} value={company.cd}>
                      {company.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="dsm-departmentcd">
              {t('Department_Search_Modal.departmentcd')}
            </Form.Label>
            <Col xs sm="6">
              <Form.Control id="dsm-departmentcd" name="department_cd" defaultValue={departmentcd}></Form.Control>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="dsm-department-name">
              {t('Department_Search_Modal.department_name')}
            </Form.Label>
            <Col xs sm="6">
              <Form.Control id="dsm-department-name" name="department_name"></Form.Control>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs sm="12" className="text-center">
              <Button type="submit" variant="primary" className="me-3">
                {t('Department_Search_Modal.search')}
              </Button>
            </Col>
          </Row>
        </Form>
        <Alert className="mb-3" variant="info">
          {t('Department_Search_Modal.how_to_use')}
        </Alert>
        <div className="sticky-table" ref={tableDataRef} style={{ maxHeight: '210px' }}>
          <Table hover>
            <thead>
              <tr>
                <th style={{ width: '10%' }}>{t('Department_Search_Modal.select')}</th>
                <th style={{ width: '35%' }}>{t('Department_Search_Modal.company_name')}</th>
                <th style={{ width: '55%' }}>{t('Department_Search_Modal.department_name')}</th>
              </tr>
            </thead>
            <tbody>
              {departmentList.map(function (department, rowIndex) {
                return (
                  <tr key={department.id}>
                    <td>
                      <Form.Check
                        className="d-flex justify-content-center"
                        type="radio"
                        label=""
                        name="dsm-selected"
                        value={rowIndex}
                      />
                    </td>
                    <td>{department.company.name}</td>
                    <td>{department.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button variant="primary" className="me-3" onClick={handleReflect} ref={buttonReflectRef}>
          {t('Department_Search_Modal.reflect')}
        </Button>
        <Button variant="light" onClick={handleClose}>
          {t('Department_Search_Modal.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DepartmentSearchModal;
