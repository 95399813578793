import axiosClient from 'utils/axiosClient';
import { CommonResponse } from 'models';
import { DataLoadForm } from 'features/AssetScreen/models/DataLoadForm';
import { ManufacturerPayload } from 'features/AssetScreen/models/Manufacturer';
import { AssetModelPayload } from 'features/AssetScreen/models/AssetModel';
import { DepartmentShort } from 'features/AssetScreen/models/Department';
import { EmployeeShort } from 'features/AssetScreen/models/Employee';
import { StoragePlaceShort } from 'features/AssetScreen/models/StoragePlace';
import { AssetForm, Asset } from 'features/AssetScreen/models/Asset';
const assetEditService = {
  getPreDataFormEdit(): Promise<DataLoadForm> {
    const url = 'assets-data-load-form';
    return axiosClient.get(url);
  },

  fetchManufactures(params: string): Promise<ManufacturerPayload> {
    const url = 'assets-get-manufactures?' + params;
    return axiosClient.get(url);
  },

  fetchModels(params: string): Promise<AssetModelPayload> {
    const url = 'assets-get-models?' + params;
    return axiosClient.get(url);
  },

  fetchDepartment(params: string): Promise<CommonResponse<DepartmentShort>> {
    const url = 'assets-get-department?' + params;
    return axiosClient.get(url);
  },

  async fetchEmployee(params: string): Promise<CommonResponse<EmployeeShort>> {
    const url = 'assets-get-employee?' + params;
    return axiosClient.get(url);
  },
  async fetchStoragePlace(params: string): Promise<CommonResponse<StoragePlaceShort>> {
    const url = 'assets-get-storage-place?' + params;
    return axiosClient.get(url);
  },
  async saveAsset(body: AssetForm): Promise<ManufacturerPayload> {
    const url = 'asset';
    return axiosClient.put(url, body);
  },
  async checkAssetCode(params: string): Promise<CommonResponse<StoragePlaceShort>> {
    const url = 'assets-get-with-code?' + params;
    return axiosClient.get(url);
  },
  async getAssetById(id: string): Promise<CommonResponse<Asset>> {
    const url = 'asset/' + id;
    return axiosClient.get(url);
  },
  async importCSV(body: any) {
    const url = 'asset/import-csv';
    return axiosClient.postForm(url, body);
  },
};

export default assetEditService;
