import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CommonResponse } from 'models';
import { StoragePlace } from './models/storagePlace';

export interface StoragePlaceState {
  loading: boolean;
  list: StoragePlace[];
}

const initialState: StoragePlaceState = {
  loading: false,
  list: [],
};

const storagePlaceSlice = createSlice({
  name: 'storage_place',
  initialState,
  reducers: {
    fetchStoragePlaceList(state) {
      state.loading = true;
    },
    fetchStoragePlaceSuccess(state, action: PayloadAction<CommonResponse<StoragePlace[]>>) {
      state.list = action.payload.data;
      state.loading = false;
    },
    fetchStoragePlaceFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const storagePlaceActions = storagePlaceSlice.actions;

// Selectors
export const selectStoragePlaceLoading = (state: RootState) => state.storagePlace.loading;
export const selectStoragePlaceList = (state: RootState) => state.storagePlace.list;

// Reducer
export const storagePlaceReducer = storagePlaceSlice.reducer;
