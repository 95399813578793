export const employeeSearchModalTranslationEN = {
  Employee_Search_Modal: {
    title: 'Employee Search',
    close: 'Close',
    reflect: 'Reflect',
    search: 'Search',
    company: 'Company',
    employee_code: 'Employee code',
    employee_name: 'Employee name',
    department_code: 'Department code',
    department_name: 'Department name',
    how_to_use: 'Search results (select a selection and press the reflection button)',
    select: 'Select',
    company_name: 'Company name',
    select_company: 'All companies',
  },
};
