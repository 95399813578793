import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CommonResponse } from 'models';
import { Manufacturer } from './models/manufacturer';

export interface ManufacturerState {
  loading: boolean;
  list: Manufacturer[];
}

const initialState: ManufacturerState = {
  loading: false,
  list: [],
};

const manufacturerSlice = createSlice({
  name: 'manufacturer',
  initialState,
  reducers: {
    fetchManufacturerList(state) {
      state.loading = true;
    },
    fetchManufacturerSuccess(state, action: PayloadAction<CommonResponse<Manufacturer[]>>) {
      state.list = action.payload.data;
      state.loading = false;
    },
    fetchManufacturerFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const manufacturerActions = manufacturerSlice.actions;

// Selectors
export const selectManufacturerLoading = (state: RootState) => state.manufacturer.loading;
export const selectManufacturerList = (state: RootState) => state.manufacturer.list;
export const selectActiveManufacturerList = createSelector(selectManufacturerList, (manufacturerList) => {
  return manufacturerList.filter((manufactuer) => !manufactuer.stopped_flg);
});
// Reducer
export const manufacturerReducer = manufacturerSlice.reducer;
