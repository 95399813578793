export const departmentSearchModalTranslationJP = {
  Department_Search_Modal: {
    title: '所属検索',
    close: '閉じる',
    reflect: '反映',
    search: '検索',
    company: '会社',
    departmentcd: '所属コード',
    department_name: '所属名',
    how_to_use: '検索結果（選択を選んで、反映ボタンを押下してください）',
    select: '選択',
    company_name: '会社名',
    select_company: 'すべての会社',
  },
};
