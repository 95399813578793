import { Company } from 'features/common/models/company';
import { CommonResponse } from 'models/common';
import axiosClient from 'utils/axiosClient';
import { Login, LoggedInUserInfo, Permission } from './models/login';

const authService = {
  postLogin(data: Login): Promise<CommonResponse<LoggedInUserInfo>> {
    const url = '/login';
    return axiosClient.post(url, data);
  },
  postLogout(): Promise<CommonResponse<null>> {
    const url = '/logout';
    return axiosClient.post(url);
  },
  getPermission(param: string): Promise<CommonResponse<Permission>> {
    const url = `/permissions?${param}`;
    return axiosClient.get(url);
  },
  getCompanies(): Promise<CommonResponse<Company[]>> {
    let url = '/companies-login';
    return axiosClient.get(url);
  },
};

export default authService;
