import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { DepartmentSearchModalReturnData } from '.';
import { DepartmentFilterPayload } from './models/common';

const departmentService = {
  getAll(params: DepartmentFilterPayload): Promise<CommonResponse<DepartmentSearchModalReturnData[]>> {
    const url = '/departments';
    return axiosClient.get(url, { params });
  },
};

export default departmentService;
