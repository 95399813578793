import { UNIT_DEPARTMENT_CLASSIFICATION } from 'utils';
import { InspectionHistory } from './models/inspectionHistory';

export function getDepartmentInfo(inspectionDetail: InspectionHistory) {
  let area = '';
  let block = '';

  if (inspectionDetail.department_parent1_classification === UNIT_DEPARTMENT_CLASSIFICATION) {
    block = inspectionDetail.department_parent2_name;
    area = inspectionDetail.department_parent3_name;
  } else {
    block = inspectionDetail.department_parent1_name;
    area = inspectionDetail.department_parent2_name;
  }

  return { area, block };
}
