import { useAppDispatch, useAppSelector } from 'app/hooks';
import BranchSidebar from 'components/BranchSidebar';
import { CompanyNode, DepartmentNode, OrganizationTreeFilter } from 'components/BranchSidebar/models';
import { getBranchKey, isCompanyNode } from 'components/BranchSidebar/utils';
import StatusHeader from 'components/common/StatusHeader';
import _ from 'lodash';
import { lazy, Suspense, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  RESTAURANT_DEPARTMENT_CLASSIFICATION,
  SELECT_ORGANIZATION_TREE_TYPE_NOT_STORE,
  SELECT_ORGANIZATION_TREE_TYPE_STORE,
  SIDE_BAR_FOR_INVENTORY,
} from 'utils';
import { inventoryListActions, selectOrganizationData, selectSelectedBranchKey } from '../inventorySlice';
import { InventorySearchPayload } from '../models/inventory';
import './InventoryListPage.scss';

const InventoryListPageForStore = lazy(() => import('../components/InventoryListPageForStore'));
const InventoryListPageForNotStore = lazy(() => import('../components/InventoryListPageForNotStore'));

function InventoryListPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  // const levelOfUser: string = useAppSelector(selectLevel);

  const [paramsQuery, setParamsQuery] = useState({} as InventorySearchPayload);
  const [selectOrgTreeType, setSelectOrgTreeType] = useState(SELECT_ORGANIZATION_TREE_TYPE_NOT_STORE);
  const [selectedDepartment, setSelectedDepartment] = useState<CompanyNode | DepartmentNode | null>(null);
  const selectedBranchKey = useAppSelector(selectSelectedBranchKey);
  const organizationData = useAppSelector(selectOrganizationData);

  const handlePageBack = () => {
    history.push('/');
  };

  function handleBranchChanged(branch: CompanyNode | DepartmentNode, month: number, year: number) {
    let department_id: number | null = null;
    let company_id: number | null = null;

    let department_type: number;
    if (isCompanyNode(branch)) {
      company_id = branch.company_id;
      department_type = SELECT_ORGANIZATION_TREE_TYPE_NOT_STORE;
    } else {
      department_id = branch.department_id;
      branch.classification === RESTAURANT_DEPARTMENT_CLASSIFICATION
        ? (department_type = SELECT_ORGANIZATION_TREE_TYPE_STORE)
        : (department_type = SELECT_ORGANIZATION_TREE_TYPE_NOT_STORE);
    }
    const params: InventorySearchPayload = {
      department_id: department_id,
      company_id: company_id,
      month: month,
      year: year,
    };
    setParamsQuery(params);
    setSelectOrgTreeType(department_type);
    setSelectedDepartment(branch);

    if (!_.isEqual(selectedBranchKey, getBranchKey(branch))) {
      dispatch(inventoryListActions.updateSelectedBranchKey(getBranchKey(branch)));
    }
  }

  const handleMonthChanged = (filter: OrganizationTreeFilter) => {
    dispatch(inventoryListActions.fetchOrganizationData(filter));

    setParamsQuery({} as InventorySearchPayload);
    setSelectedDepartment(null);
  };

  return (
    <div className="page-wrapper">
      <div className="page-header">
        <StatusHeader onAction={handlePageBack} />
      </div>
      <div className="page-main">
        <div className="page-content d-flex flex-column">
          <div className="fit-content flex-grow-1 flex-shrink-1">
            <Row className="h-100">
              <Col xs sm="2" className="sidebar h-100">
                <BranchSidebar
                  featureId={SIDE_BAR_FOR_INVENTORY}
                  organizationData={organizationData}
                  activeBranchKey={selectedBranchKey}
                  onSelectedBranchChanged={handleBranchChanged}
                  onMonthChanged={handleMonthChanged}
                />
              </Col>

              <Col xs sm="10" className="h-100">
                {!selectedDepartment && <Alert variant="warning">{t('Inventory.msg_select_department')}</Alert>}
                {selectedDepartment && (
                  <Suspense>
                    {selectOrgTreeType === SELECT_ORGANIZATION_TREE_TYPE_STORE && (
                      <InventoryListPageForStore paramsQuery={paramsQuery} />
                    )}
                    {selectOrgTreeType === SELECT_ORGANIZATION_TREE_TYPE_NOT_STORE && (
                      <InventoryListPageForNotStore paramsQuery={paramsQuery} />
                    )}
                  </Suspense>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}
export default InventoryListPage;
