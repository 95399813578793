import { useAppSelector } from 'app/hooks';
import { selectApprovalStatusMap } from 'features/common/approvalStatusSlice';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Collapse, ListGroup } from 'react-bootstrap';
import { BranchMenuContext } from '..';
import { DEFAULT_APPROVAL_STATUS_ID } from '../constants';
import { SquareMinusIcon, SquarePlusIcon } from '../icons';
import { CompanyNode } from '../models/company';
import { DepartmentNode } from '../models/department';
import { getBranchKey, isCompanyNode } from '../utils';

export interface BranchMenuItemProps {
  data: DepartmentNode | CompanyNode;
}

export function BranchMenuItem({ data }: BranchMenuItemProps) {
  const branchKey = getBranchKey(data);

  const branchMenuContext = useContext(BranchMenuContext);
  const isExpandable = data.departments && data.departments.length > 0;

  //state
  const approvalStatusMap = useAppSelector(selectApprovalStatusMap);
  const [open, setOpen] = useState(true);
  const [isSelected, setIsSelected] = useState(
    branchMenuContext.path.some((pathItem) => pathItem.id === branchKey.id && pathItem.cd === branchKey.cd)
  );

  const selectedBranchKey =
    branchMenuContext.path.length > 0 ? branchMenuContext.path[branchMenuContext.path.length - 1] : undefined;

  function handleMenuClick(e: React.MouseEvent<HTMLButtonElement>) {
    branchMenuContext.changeSelectedBranch && branchMenuContext.changeSelectedBranch(data);
  }

  function handleToggleCollapse() {
    setOpen(!open);
  }

  useEffect(() => {
    setIsSelected(
      branchMenuContext.path.some((pathItem) => pathItem.id === branchKey.id && pathItem.cd === branchKey.cd)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchMenuContext]);

  const MenuItemRoot = (
    <ListGroup.Item
      id={`branch-${branchKey.id}-${branchKey.cd}`}
      className={`${isExpandable ? 'dt-parent' : ''} ${isSelected ? 'isSelected' : ''} ${open ? 'isOpen' : ''} ${
        _.isEqual(selectedBranchKey, branchKey) ? 'menu-selected' : ''
      }`}
    >
      {isExpandable && (
        <div className="branch-icon" onClick={handleToggleCollapse}>
          {open ? (
            <SquareMinusIcon
              width={14}
              height={16}
              color={isSelected && _.isEqual(selectedBranchKey, branchKey) ? 'white' : 'black'}
            />
          ) : (
            <SquarePlusIcon
              width={14}
              height={16}
              color={isSelected && _.isEqual(selectedBranchKey, branchKey) ? 'white' : 'black'}
            />
          )}
        </div>
      )}
      <button className="branch-name d-flex align-items-center w-100" onClick={handleMenuClick}>
        <span className="branch-link">{isCompanyNode(data) ? data.company_name : data.department_name}</span>
        <div
          className="branch-status"
          style={{
            background: `${
              approvalStatusMap[
                data.approval_status != null ? Number(data.approval_status) : DEFAULT_APPROVAL_STATUS_ID
              ].fill_color
            }`,
            border: `1px solid ${
              approvalStatusMap[
                data.approval_status != null ? Number(data.approval_status) : DEFAULT_APPROVAL_STATUS_ID
              ].line_color
            }`,
          }}
        ></div>
      </button>
    </ListGroup.Item>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open}>
      <ListGroup>
        {data.departments?.map((item) => (
          <BranchMenuItem key={isCompanyNode(item) ? item.company_cd : item.department_cd} data={item} />
        ))}
      </ListGroup>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
}
