import 'react-datepicker/dist/react-datepicker.css';
import { TransferHistory } from 'features/AssetScreen/models/Asset';
import moment from 'moment';

interface AssetHistoryProps {
  idx: number;
  item: TransferHistory;
}

function ItemTransfer({ item, idx }: AssetHistoryProps) {
  function checkFromTransfer(item: TransferHistory) {
    if (item.from_company || item.from_department || item.from_employee || item.from_storage_place) {
      return true;
    }
    return false;
  }

  function checkToTransfer(item: TransferHistory) {
    if (item.to_company || item.to_department || item.to_employee || item.to_storage_place) {
      return true;
    }
    return false;
  }
  return (
    <tr key={idx}>
      <td>{item.applied_start_date}</td>
      <td>{item.updated_employee?.username}</td>
      <td>{'移動処理'}</td>
      <td>
        {checkToTransfer(item) ? '移動先' : ''}
        <p className="pl-7">
          <strong>{item.to_company?.name ? '会社:' : ''}</strong> {item.to_company?.name}
        </p>
        <p className="pl-7">
          <strong>{item.to_department?.name ? '所属:' : ''}</strong> {item.to_department?.name}
        </p>
        <p className="pl-7">
          <strong>{item.to_employee?.username ? '社員:' : ''}</strong> {item.to_employee?.username}
        </p>
        <p className="pl-7">
          <strong>{item.to_storage_place?.name ? '保管場所:' : ''}</strong>
          {item.to_storage_place?.name}
        </p>
        {checkFromTransfer(item) ? '移動元' : ''}
        <p className="pl-7">
          <strong> {item.from_company?.name ? ' 会社:' : ''}</strong> {item.from_company?.name}{' '}
        </p>
        <p className="pl-7">
          <strong>{item.from_department?.name ? ' 所属:' : ''}</strong> {item.from_department?.name}{' '}
        </p>
        <p className="pl-7">
          <strong>{item.from_employee?.username ? ' 社員:' : ''}</strong> {item.from_employee?.name}{' '}
        </p>
        <p className="pl-7">
          <strong>{item.from_storage_place?.name ? ' 保管場所:' : ''}</strong> {item.from_storage_place?.name}{' '}
        </p>
      </td>
      <td>{moment(item.updated_date).format('YYYY-MM-DD')}</td>
    </tr>
  );
}
export default ItemTransfer;
