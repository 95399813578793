export const inspectionHistoryFeatureTranslationJP = {
  InspectionHistoryFeature: {
    search_condition: '検索条件',
    required_field: '※は必須項目です。',
    implementation_month: '実施月',

    affiliation_selection: '所属選択',
    affiliation_code: '所属コード',
    affiliation_name: '所属名称',
    affiliation_search: '所属検索',

    abnormality: '異常の有無',
    normal: '異常あり',
    abnormal: '異常なし',

    search: '検索',
    csv_output: 'CSV出力',

    month_of_inspection: '点検実施月',
    company_name: '会社名',
    area_sales_department: 'エリア／営業部',
    block_area: 'ブロック／エリア',
    store_code: '店舗コード',
    store_name: '店舗名',
    asset_management_number: '資産管理番号',
    device_name: '機器名',
    manufacturer: 'メーカー',
    model: '型式',
    extreme_rust: '極度な錆',
    installation_state: '設置状態',
    liquid_sauce: '液タレ',
    unusual_sound: '異常な音',
    abnormal_vibration: '異常な振動',
    operating_state: '動作状態',
    remarks: '備考',
  },
};
