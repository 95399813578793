import { useAppSelector } from 'app/hooks';
import { Asset, AssetListProps } from 'features/AssetScreen/models/Asset';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { selectPermissionByFeature } from 'features/Auth/authSlice';
import moment from 'moment';

function TableAsset({ assets }: AssetListProps) {
  const { t } = useTranslation();
  const permissions = useAppSelector(selectPermissionByFeature('asset'));
  const linkToAssetEdit = (value: number, management_no: string) => {
    if (permissions && permissions.includes('show_link_asset_edit')) {
      return (
        <a target="_blank" rel="noreferrer" href={`/asset/edit/${value}`}>
          {management_no}
        </a>
      );
    } else {
      return <>{management_no}</>;
    }
  };
  return (
    <div className="sticky-table" style={{ maxHeight: '500px' }}>
      <Table hover>
        <thead>
          <tr>
            <th>{t('AssetScreen.index.table.current_status')}</th>
            <th>{t('AssetScreen.index.table.current_possession_company_name')}</th>
            <th>{t('AssetScreen.index.table.current_possession_department_cd')}</th>
            <th>{t('AssetScreen.index.table.restaurant_cd')}</th>
            <th>{t('AssetScreen.index.table.current_possession_department_name')}</th>
            <th>{t('AssetScreen.index.table.current_possession_employee_name')}</th>
            <th>{t('AssetScreen.index.table.current_storage_place_name')}</th>
            <th>{t('AssetScreen.index.table.management_no')}</th>
            <th>{t('AssetScreen.index.table.accounting_no')}</th>
            <th>{t('AssetScreen.index.table.classification_name')}</th>
            <th>{t('AssetScreen.index.table.manufacturer_name')}</th>
            <th>{t('AssetScreen.index.table.model_name')}</th>
            <th>{t('AssetScreen.index.table.use_name')}</th>
            <th>{t('AssetScreen.index.table.purchase_date')}</th>
            <th>{t('AssetScreen.index.table.purchase_price')}</th>
            <th>{t('AssetScreen.index.table.updated_date')}</th>
            <th>{t('AssetScreen.index.table.updated_employee')}</th>
          </tr>
        </thead>
        <tbody>
          {assets &&
            assets.data.map((asset: Asset, idx) => {
              return (
                <tr key={idx}>
                  <td>{asset.current_status?.name}</td>
                  <td>{asset.current_possession_company?.name}</td>
                  <td>{asset.current_possession_department?.cd}</td>
                  <td>{asset.current_possession_department?.restaurant_cd}</td>
                  <td>{asset.current_possession_department?.name}</td>
                  <td>{asset.current_possession_employee?.username}</td>
                  <td>{asset.current_storage_place?.name}</td>
                  <td>{linkToAssetEdit(asset.id, asset.management_no)}</td>
                  <td>{asset.accounting_no}</td>
                  <td>{asset.classification?.name}</td>
                  <td>{asset.manufacturer?.name}</td>
                  <td>{asset.model?.name}</td>
                  <td>{asset.current_use?.name}</td>
                  <td>{moment(asset.purchased_date).format('YYYY-MM-DD')}</td>
                  <td>{new Intl.NumberFormat().format(asset.purchased_price)}</td>
                  <td>{moment(asset.updated_date).format('YYYY-MM-DD')}</td>
                  <td>{asset.updated_employee?.name}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}
export default TableAsset;
