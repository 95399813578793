import { useAppSelector } from 'app/hooks';
import { AxiosError } from 'axios';
import { selectCompanyList } from 'features/common/companySlice';
import * as HttpStatus from 'http-status-codes';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { handleServiceError } from 'utils/common';
import { GlobalModalContext } from '../GlobalModal';
import employeeService from './service';
import './style.scss';

export interface EmployeeSearchModalReturnData {
  companyid: number;
  company_cd: string;
  company_name: string;
  departmentid: number;
  department_cd: string;
  department_name: string;
  employeeid: number;
  employee_cd: string;
  employee_name: string;
}

export interface EmployeeSearchModalProps {
  dataDate?: string;
  companycd?: string;
  employeecd?: string;
  departmentcd?: string;
  onClose?: () => void;
  onReflect?: (data: EmployeeSearchModalReturnData) => void;
}

function EmployeeSearchModal({
  dataDate = undefined,
  companycd = '',
  employeecd = '',
  departmentcd = '',
  onClose,
  onReflect,
}: EmployeeSearchModalProps) {
  const { t } = useTranslation();
  const { showGlobalModal, closeGlobalModal } = GlobalModalContext.useGlobalModalContext();

  //ref
  const tableDataRef = useRef<HTMLDivElement>(null);
  const buttonReflectRef = useRef<HTMLButtonElement>(null);

  //state
  const companyList = useAppSelector(selectCompanyList);
  const [employeeList, setEmployeeList] = useState<EmployeeSearchModalReturnData[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await employeeService.getAll({
          data_date: dataDate,
          company_cd: companycd && companycd.trim().length > 0 ? companycd : undefined,
          employee_cd: employeecd && employeecd.trim().length > 0 ? employeecd : undefined,
          department_cd: departmentcd && departmentcd.trim().length > 0 ? departmentcd : undefined,
        });
        setEmployeeList(response.data);
        if (buttonReflectRef.current) buttonReflectRef.current.disabled = true;
      } catch (e) {
        if ((e as AxiosError).response?.status === HttpStatus.StatusCodes.UNAUTHORIZED) {
          closeGlobalModal();
        } else {
          handleServiceError(showGlobalModal, e as AxiosError);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClose() {
    onClose && onClose();
    closeGlobalModal();
  }

  function handleReflect() {
    let selectedRowIndex = -1;

    const selectedInput = document.querySelector('input[name="esm-selected"]:checked') as HTMLInputElement;
    if (selectedInput) selectedRowIndex = Number(selectedInput.value);

    if (selectedRowIndex >= 0) {
      onReflect && onReflect(employeeList[selectedRowIndex]);
      closeGlobalModal();
    }
  }

  function handleSearchSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const companycd = e.currentTarget['company_cd'].value;
    const departmentcd = (e.currentTarget['department_cd'].value as string).trim();
    const departmentName = (e.currentTarget['department_name'].value as string).trim();
    const employeecd = (e.currentTarget['employee_cd'].value as string).trim();
    const employeeName = (e.currentTarget['employee_name'].value as string).trim();

    (async () => {
      try {
        const response = await employeeService.getAll({
          data_date: dataDate,
          company_cd: companycd,
          department_cd: departmentcd,
          department_name: departmentName,
          employee_cd: employeecd,
          employee_name: employeeName,
        });
        setEmployeeList(response.data);
        if (buttonReflectRef.current) buttonReflectRef.current.disabled = true;

        const selectedInput = document.querySelector('input[name="dsm-selected"]:checked') as HTMLInputElement;
        if (selectedInput) selectedInput.checked = false;
      } catch (e) {
        if ((e as AxiosError).response?.status === HttpStatus.StatusCodes.UNAUTHORIZED) {
          closeGlobalModal();
        } else {
          handleServiceError(showGlobalModal, e as AxiosError);
        }
      }
    })();
  }

  useEffect(() => {
    const rows = tableDataRef.current?.querySelectorAll('.employee-search-row');
    function rowEventClick(rowEvent: any) {
      const radioInput = rowEvent.currentTarget.querySelector('.form-check-input');
      if (radioInput) {
        radioInput.checked = true;
        if (buttonReflectRef.current) buttonReflectRef.current.disabled = false;
      }
    }
    if (rows) {
      rows.forEach((row) => {
        (row as HTMLTableRowElement).addEventListener('click', rowEventClick);
      });
    }

    return () => {
      if (rows) {
        rows.forEach((row) => {
          row.removeEventListener('click', rowEventClick);
        });
      }
    };
  }, [employeeList]);

  return (
    <Modal size="lg" className="employee-search-modal" show backdrop="static" centered>
      <Modal.Header className="bg-primary justify-content-center">
        <Modal.Title className="fs-6 text-white">{t('Employee_Search_Modal.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className="mb-4" onSubmit={handleSearchSubmit}>
          <Row>
            <Col sm={{ span: 4, offset: 2 }}>
              <Row className="mb-3">
                <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="esm-company">
                  {t('Employee_Search_Modal.company')}
                </Form.Label>
                <Col xs sm="8">
                  <Form.Select id="esm-company" name="company_cd" defaultValue={companycd}>
                    <option value="">{t('Department_Search_Modal.select_company')}</option>
                    {companyList.map((company) => {
                      return (
                        <option key={company.id} value={company.cd}>
                          {company.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mb-3">
                <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="dsm-department-code">
                  {t('Employee_Search_Modal.department_code')}
                </Form.Label>
                <Col xs sm="8">
                  <Form.Control
                    id="dsm-department-code"
                    name="department_cd"
                    defaultValue={departmentcd}
                  ></Form.Control>
                </Col>
              </Row>
              <Row className="mb-3">
                <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="dsm-department-name">
                  {t('Employee_Search_Modal.department_name')}
                </Form.Label>
                <Col xs sm="8">
                  <Form.Control id="dsm-department-name" name="department_name"></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col sm="4">
              <Row className="mb-3">
                <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="esm-employeecd">
                  {t('Employee_Search_Modal.employee_code')}
                </Form.Label>
                <Col xs sm="8">
                  <Form.Control id="esm-employeecd" name="employee_cd" defaultValue={employeecd}></Form.Control>
                </Col>
              </Row>
              <Row className="mb-3">
                <Form.Label className="col-sm-4 col-form-label text-end" htmlFor="dsm-employee-name">
                  {t('Employee_Search_Modal.employee_name')}
                </Form.Label>
                <Col xs sm="8">
                  <Form.Control id="dsm-employee-name" name="employee_name"></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs sm="12" className="text-center">
              <Button type="submit" variant="primary" className="me-3">
                {t('Employee_Search_Modal.search')}
              </Button>
            </Col>
          </Row>
        </Form>
        <Alert className="mb-3" variant="info">
          {t('Employee_Search_Modal.how_to_use')}
        </Alert>
        <div
          id="search-employee-table"
          className="sticky-table d-flex flex-column"
          ref={tableDataRef}
          style={{ height: '210px' }}
        >
          <div className="div-table">
            <div className="div-thead">
              <div className="div-tr">
                <div className="div-th" style={{ width: '10%' }}>
                  {t('Employee_Search_Modal.select')}
                </div>
                <div className="div-th border-start-0" style={{ width: '30%' }}>
                  {t('Employee_Search_Modal.company_name')}
                </div>
                <div className="div-th border-start-0" style={{ width: '30%' }}>
                  {t('Employee_Search_Modal.department_name')}
                </div>
                <div className="div-th border-start-0" style={{ width: '15%' }}>
                  {t('Employee_Search_Modal.employee_code')}
                </div>
                <div className="div-th border-start-0" style={{ width: '15%' }}>
                  {t('Employee_Search_Modal.employee_name')}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-grow-1 flex-shrink-1">
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  className="fixed-size-list"
                  width={width}
                  height={height}
                  itemSize={32}
                  itemCount={employeeList.length}
                  itemData={employeeList}
                  onScroll={() => {
                    const rows = tableDataRef.current?.querySelectorAll('.employee-search-row');
                    function rowEventClick(rowEvent: any) {
                      const radioInput = rowEvent.currentTarget.querySelector('.form-check-input');
                      if (radioInput) {
                        radioInput.checked = true;
                        if (buttonReflectRef.current) buttonReflectRef.current.disabled = false;
                      }
                    }
                    if (rows) {
                      rows.forEach((row) => {
                        (row as HTMLTableRowElement).removeEventListener('click', rowEventClick);
                        (row as HTMLTableRowElement).addEventListener('click', rowEventClick);
                      });
                    }
                  }}
                >
                  {({ style, index }) => {
                    const employee = employeeList[index];

                    return (
                      <div
                        key={index}
                        id={`row-${index}`}
                        className={`employee-search-row div-tr d-flex`}
                        style={style}
                      >
                        <div className="div-td" style={{ width: '10%' }}>
                          <Form.Check
                            className="d-flex justify-content-center"
                            type="radio"
                            label=""
                            name="esm-selected"
                            value={index}
                          />
                        </div>
                        <div className="div-td" style={{ width: '30%' }}>
                          {employee.company_name}
                        </div>
                        <div className="div-td" style={{ width: '30%' }}>
                          {employee.department_name}
                        </div>
                        <div className="div-td" style={{ width: '15%' }}>
                          {employee.employee_cd}
                        </div>
                        <div className="div-td" style={{ width: '15%' }}>
                          {employee.employee_name}
                        </div>
                      </div>
                    );
                  }}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button variant="primary" className="me-3" onClick={handleReflect} ref={buttonReflectRef}>
          {t('Employee_Search_Modal.reflect')}
        </Button>
        <Button variant="light" onClick={handleClose}>
          {t('Employee_Search_Modal.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EmployeeSearchModal;
