import { Route, Switch, useRouteMatch } from 'react-router-dom';
import InspectionHistoryPage from './pages/InspectionHistoryPage';

export default function InspectionHistoryFeature() {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <InspectionHistoryPage />
        </Route>
      </Switch>
    </div>
  );
}
