import { OptionType } from './models/optionType';

export const ASSET_MODEL_STOPPED_FLG_SOURCE: {
  [key: string]: Array<OptionType>;
} = {
  en: [
    {
      code: 1,
      label: 'Stop',
    },
    {
      code: 0,
      label: 'Active',
    },
  ],
  jp: [
    {
      code: 1,
      label: '停止',
    },
    {
      code: 0,
      label: '有効',
    },
  ],
};
