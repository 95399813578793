import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AxiosError } from 'axios';
import { AlertModalProps } from 'components/common/AlertModal';
import { ConfirmModalProps } from 'components/common/ConfirmModal';
import { GlobalModalContext } from 'components/common/GlobalModal';
import assetUseService from 'features/AdminMaster/assetUseService';
import { assetUseActions, selectAssetUseList } from 'features/AdminMaster/assetUseSlice';
import { ASSET_USE_INVENTORY_FLG_SOURCE } from 'features/AdminMaster/constants';
import { AssetUse } from 'features/AdminMaster/models/assetUse';
import { CommonResponse } from 'models';
import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPES } from 'utils';
import { handleServiceError } from 'utils/common';
import './style.scss';

function AssetUseFeature() {
  const dispatch = useAppDispatch();
  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();

  // source
  const inventoryFlgSource = ASSET_USE_INVENTORY_FLG_SOURCE[i18n.language];

  //state
  const assetUseList = useAppSelector(selectAssetUseList);

  function displayError(error: AxiosError) {
    if (error) {
      const errorData = error.response?.data as CommonResponse<any>;
      if (!errorData.data) {
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: errorData.message,
          title: t('App.error'),
        });
      } else {
        let errorMessage = '';
        for (const property in errorData.data) {
          errorMessage += errorData.data[property].join('<br />');
        }

        if (errorMessage.length > 0) {
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: errorMessage,
            title: t('App.error'),
          });
        }
      }
    }
  }

  useEffect(() => {
    dispatch(assetUseActions.fetchAssetUseList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget as HTMLFormElement;
    if (!form.checkValidity()) {
      setValidated(true);
    } else {
      const name = form['asset_use_name'].value.trim();
      const inventoryFlg = form['asset_use_inventory_flg'].value === '1';

      (async () => {
        try {
          await assetUseService.add(name, inventoryFlg);
          dispatch(assetUseActions.fetchAssetUseList());
          form.reset();
          setValidated(false);
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('AdminMasterFeature.save_data_successful'),
            title: t('App.info'),
          });
        } catch (e) {
          if (!handleServiceError(showGlobalModal, e as AxiosError)) {
            displayError(e as AxiosError);
            setValidated(true);
          }
        }
      })();
    }
  }

  function handleInputNameBlur() {
    setValidated(false);
  }

  function handleChangeName(e: React.ChangeEvent<HTMLInputElement>) {
    const errorHelperElem = e.currentTarget.parentElement?.querySelector('.error-helper');
    if (errorHelperElem) errorHelperElem.innerHTML = '';
  }

  function handleUpdate(rowIndex: number) {
    const inputNameElem = document.querySelector('#asset-use-name-' + rowIndex) as HTMLInputElement;
    const inputInventoryFlgElem = document.querySelector('#asset-use-inventory-flg-' + rowIndex) as HTMLSelectElement;

    if (inputNameElem && inputInventoryFlgElem) {
      const name = inputNameElem.value.trim();
      const inventoryFlg = inputInventoryFlgElem.value === '1';
      const data: Partial<AssetUse> = {
        id: assetUseList[rowIndex].id,
        name: name,
        inventory_flg: inventoryFlg,
      };

      (async () => {
        try {
          await assetUseService.update([data]);
          dispatch(assetUseActions.fetchAssetUseList());
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('AdminMasterFeature.save_data_successful'),
            title: t('App.info'),
          });
        } catch (e) {
          if (!handleServiceError(showGlobalModal, e as AxiosError)) {
            const errorResponse = (e as AxiosError).response?.data as CommonResponse<any>;
            const errorData = errorResponse.data.error_data;
            const rowError = document.querySelector('#asset-status-row-' + errorData.id);
            if (rowError) {
              rowError.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
              });
              for (const errorKey in errorResponse.data.errors) {
                const errorControlTextElem = rowError.querySelector('.error-' + errorKey);
                if (errorControlTextElem) {
                  errorControlTextElem.innerHTML = errorResponse.data.errors[errorKey];
                }
              }
            }
          }
        }
      })();
    }
  }

  function handleDelete(rowIndex: number) {
    if (assetUseList[rowIndex]) {
      showGlobalModal<ConfirmModalProps>(MODAL_TYPES.CONFIRM_MODAL, {
        title: t('App.warning'),
        message: t('AdminMasterFeature.confirm_delete'),
        onConfirm: function () {
          (async () => {
            try {
              await assetUseService.delete(assetUseList[rowIndex].id);
              dispatch(assetUseActions.fetchAssetUseList());
              showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
                message: t('AdminMasterFeature.save_data_successful'),
                title: t('App.info'),
              });
            } catch (e) {
              if (!handleServiceError(showGlobalModal, e as AxiosError)) {
                displayError(e as AxiosError);
              }
            }
          })();
        },
      });
    }
  }

  function handleRestore(rowIndex: number) {
    if (assetUseList[rowIndex]) {
      showGlobalModal<ConfirmModalProps>(MODAL_TYPES.CONFIRM_MODAL, {
        title: t('App.warning'),
        message: t('AdminMasterFeature.confirm_restore'),
        onConfirm: function () {
          (async () => {
            try {
              await assetUseService.restore(assetUseList[rowIndex].id);
              dispatch(assetUseActions.fetchAssetUseList());
              showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
                message: t('AdminMasterFeature.save_data_successful'),
                title: t('App.info'),
              });
            } catch (e) {
              if (!handleServiceError(showGlobalModal, e as AxiosError)) {
                displayError(e as AxiosError);
              }
            }
          })();
        },
      });
    }
  }

  return (
    <>
      <Table bordered hover className="mb-0">
        <thead className="bg-yellow">
          <tr>
            <th colSpan={3}>{t('AdminMasterFeature.asset_use_head_title')}</th>
          </tr>
          <tr>
            <th style={{ width: '40%' }}>{t('AdminMasterFeature.name')}</th>
            <th style={{ width: '30%' }}>{t('AdminMasterFeature.is_inventory')}</th>
            <th style={{ width: '30%' }}>{t('AdminMasterFeature.action')}</th>
          </tr>
        </thead>
      </Table>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Table bordered className="mb-0" style={{ borderTop: '1px solid #fff' }}>
          <tbody>
            <tr>
              <td style={{ width: '40%' }}>
                <InputGroup className="flex-grow-1 flex-shrink-1 me-4" size="sm" hasValidation>
                  <Form.Control
                    id="asset-use-name"
                    name="asset_use_name"
                    type="text"
                    required
                    onBlur={handleInputNameBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t('AdminMasterFeature.warning_required_field')}
                  </Form.Control.Feedback>
                </InputGroup>
              </td>
              <td style={{ width: '30%' }}>
                <Form.Select
                  id="asset-use-inventory-flg"
                  className="inventory-flg"
                  defaultValue={1}
                  name="asset_use_inventory_flg"
                  size="sm"
                >
                  {inventoryFlgSource.map(function (flg) {
                    return (
                      <option key={flg.code} value={flg.code}>
                        {flg.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </td>
              <td style={{ width: '30%' }}>
                <Button type="submit" variant="primary" size="sm" className="btn-action">
                  {t('AdminMasterFeature.sign_up')}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
      <div className="fit-content flex-grow-1 flex-shrink-1">
        <Table bordered className="mb-0" style={{ borderTop: '1px solid #fff' }}>
          <tbody>
            {assetUseList.map(function (item, index) {
              return (
                <tr id={`asset-use-row-${item.id}`} key={item.id} className={item.stopped_flg ? 'table-secondary' : ''}>
                  <td style={{ width: '40%' }}>
                    <Form.Control
                      id={`asset-use-name-${index}`}
                      defaultValue={item.name}
                      data-index={index}
                      onChange={handleChangeName}
                      size="sm"
                    ></Form.Control>
                    <span className="error-helper error-name small text-danger"></span>
                  </td>
                  <td style={{ width: '30%' }}>
                    <Form.Select
                      id={`asset-use-inventory-flg-${index}`}
                      defaultValue={item.inventory_flg ? 1 : 0}
                      data-index={index}
                      size="sm"
                    >
                      {inventoryFlgSource.map(function (flg) {
                        return (
                          <option key={flg.code} value={flg.code}>
                            {flg.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </td>
                  <td style={{ width: '30%' }}>
                    <Button className="btn--green btn-action me-1" size="sm" onClick={handleUpdate.bind(null, index)}>
                      {t('AdminMasterFeature.change')}
                    </Button>
                    {!item.stopped_flg && (
                      <Button
                        className="btn--red btn-action"
                        size="sm"
                        disabled={!item.can_delete}
                        onClick={handleDelete.bind(null, index)}
                      >
                        {t('AdminMasterFeature.stop')}
                      </Button>
                    )}
                    {item.stopped_flg && (
                      <Button className="btn--yellow btn-action" size="sm" onClick={handleRestore.bind(null, index)}>
                        {t('AdminMasterFeature.valid')}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default AssetUseFeature;
