import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CompanyNode, DepartmentNode, OrganizationTreeFilter } from 'components/BranchSidebar/models';
import { findNode } from 'components/BranchSidebar/utils';
import { BranchKey, CommonResponse } from 'models';
import { InventoryMasterDataInfo } from './models/inventory';

export interface InventoryState {
  loading: boolean;
  master_data: InventoryMasterDataInfo;

  //organization tree data
  organizationData: CompanyNode[] | DepartmentNode[];
  selectedBranchKey: BranchKey;
}

const initialState: InventoryState = {
  loading: false,
  master_data: {
    inventory_status: [],
  },

  //organization tree data
  organizationData: [],
  selectedBranchKey: {
    id: 0,
    cd: '',
  },
};

const inventoryListSlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    fetchInventoryMasterData(state) {
      state.loading = true;
    },
    fetchInventoryMasterDataSuccess(state, action: PayloadAction<CommonResponse<InventoryMasterDataInfo>>) {
      state.master_data = action.payload.data;
      state.loading = false;
    },
    fetchInventoryMasterDataFailed(state) {
      state.loading = false;
    },
    fetchOrganizationData(state, action: PayloadAction<OrganizationTreeFilter>) {
      state.loading = true;
    },
    fetchOrganizationDataSuccess(state, action: PayloadAction<CommonResponse<CompanyNode[] | DepartmentNode[]>>) {
      state.organizationData = action.payload.data;
      state.selectedBranchKey = {
        ...state.selectedBranchKey,
        id: 0,
        cd: '',
      };
    },
    fetchOrganizationDataFailed(state) {
      state.loading = false;
    },
    updateSelectedBranchKey(state, action: PayloadAction<BranchKey>) {
      state.selectedBranchKey = {
        ...state.selectedBranchKey,
        id: action.payload.id,
        cd: action.payload.cd,
      };
    },
    reloadOrganizationData(state, action: PayloadAction<OrganizationTreeFilter>) {
      state.loading = true;
    },
    reloadOrganizationDataSuccess(state, action: PayloadAction<CommonResponse<CompanyNode[] | DepartmentNode[]>>) {
      state.organizationData = action.payload.data;
    },
    reloadOrganizationDataFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const inventoryListActions = inventoryListSlice.actions;

// Selectors
export const selectInventoryListLoading = (state: RootState) => state.inventory.loading;
export const selectInventoryMasterData = (state: RootState) => state.inventory.master_data;
export const selectOrganizationData = (state: RootState) => state.inventory.organizationData;
export const selectSelectedBranchKey = (state: RootState) => state.inventory.selectedBranchKey;
export const selectSelectedBranch = createSelector(
  [selectOrganizationData, selectSelectedBranchKey],
  (organizationData, selectedBranchKey) => {
    return findNode(organizationData, selectedBranchKey.id, selectedBranchKey.cd);
  }
);

// Reducer
export const inventoryReducer = inventoryListSlice.reducer;
