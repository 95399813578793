import { CommonResponse } from 'models/common';
import axiosClient from 'utils/axiosClient';
import { Todo, TodoFilterPayload } from './models/todo';

const todoService = {
  getAll(params: TodoFilterPayload): Promise<CommonResponse<Todo[]>> {
    const url = '/todos';
    return axiosClient.get(url, { params });
  },
};

export default todoService;
