import { PayloadAction } from '@reduxjs/toolkit';
import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { CommonResponse } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import assetModelrService from './assetModelService';
import { assetModelActions } from './assetModelSlice';
import { AssetModel, AssetModelFilterPayload } from './models/assetModel';

function* fetchAssetModelList(action: PayloadAction<AssetModelFilterPayload | undefined>) {
  try {
    const response: CommonResponse<AssetModel[]> = yield call(assetModelrService.getAll, action.payload);
    yield put(assetModelActions.fetchAssetModelSuccess(response));
  } catch (e) {
    yield put(assetModelActions.fetchAssetModelFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* assetModelSaga() {
  yield takeLatest(assetModelActions.fetchAssetModelList.type, fetchAssetModelList);
}
