import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { EmployeeSearchModalReturnData } from '.';
import { EmployeeFilterPayload } from './models/common';

const employeeService = {
  getAll(params: EmployeeFilterPayload): Promise<CommonResponse<EmployeeSearchModalReturnData[]>> {
    const url = '/employees';
    return axiosClient.get(url, { params });
  },
};

export default employeeService;
