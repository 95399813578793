import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { AssetStatus } from './models/assetStatus';

const assetStatusService = {
  getAll(): Promise<CommonResponse<AssetStatus[]>> {
    const url = '/asset-statuses';
    return axiosClient.get(url);
  },
  add(name: string): Promise<CommonResponse<any>> {
    const url = '/asset-statuses';
    return axiosClient.post(url, { name });
  },
  delete(id: number): Promise<CommonResponse<null>> {
    const url = '/asset-status/' + id;
    return axiosClient.delete(url);
  },
  update(payload: Partial<AssetStatus>[]) {
    const url = '/asset-statuses';
    return axiosClient.patch(url, payload);
  },
  restore(id: number): Promise<CommonResponse<null>> {
    const url = '/asset-status/' + id + '/restore';
    return axiosClient.put(url);
  },
};

export default assetStatusService;
