import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GlobalModalContext } from '../GlobalModal';
import './style.scss';

export interface AlertModalProps {
  title: string;
  message: string;
  size?: 'sm' | 'lg' | 'xl';
  onClose?: () => void;
}

function AlertModal({ title, message, onClose, size = 'sm' }: AlertModalProps) {
  const { t } = useTranslation();
  const { closeGlobalModal } = GlobalModalContext.useGlobalModalContext();

  function handleClose() {
    onClose && onClose();
    closeGlobalModal();
  }

  return (
    <Modal size={size} className="alert-modal" show backdrop="static" centered>
      <Modal.Header className="bg-primary justify-content-center">
        <Modal.Title className="fs-6 text-white">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div
          className="alert-modal__message text-center d-flex justify-content-center align-items-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button variant="light" onClick={handleClose}>
          {t('Alert_Modal.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AlertModal;
