import { useAppDispatch, useAppSelector } from 'app/hooks';
import CustomDatePicker from 'components/common/DatePickerCustom/CustomDatePicker';
import StatusHeader from 'components/common/StatusHeader';
import { selectEmployeeInfo, selectJobInfo, selectPermissionByFeature } from 'features/Auth/authSlice';
import { inventoryListActions, selectInventoryMasterData } from 'features/Inventory/inventorySlice';
import { InventoryStatus } from 'features/Inventory/models/inventory';
import {
  inventoryHistoryActions,
  selectAreaDepartment,
  selectBlockDepartment,
  selectCompanyList,
  selectDepartmentList,
  selectInventoryList,
} from 'features/InventoryHistoryConfirmation/inventoryHistoryConfirmationSlice';
import _ from 'lodash';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import ExportCSV from '../components/ExportCSV';
import { Company, Department, Inventory, SearchInventoryHistory } from '../models/inventory';
import './InventoryHistoryConfirmation.scss';

function InventoryHistoryConfirmation() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const inventorHistoryListSelector = useAppSelector(selectInventoryList);
  const selectedJob = useAppSelector(selectJobInfo);
  const selectedUser = useAppSelector(selectEmployeeInfo);
  const selectedInventoryMasterData = useAppSelector(selectInventoryMasterData);
  const companiesSelector = useAppSelector(selectCompanyList);
  const areaDepartmentsSelector = useAppSelector(selectAreaDepartment);
  const blockDepartmentsSelector = useAppSelector(selectBlockDepartment);
  const departmentsSelector = useAppSelector(selectDepartmentList);
  const permissions = useAppSelector(selectPermissionByFeature('inventory_history'));

  const [selectedInventoryStatus, setSelectedInventoryStatus] = useState<Array<number>>([]);
  const [unSelectedInventoryStatus, setUnSelectedInventoryStatus] = useState<Array<number>>([]);
  const [saleDepartmentList, setSaleDepartmentList] = useState<Department[]>();
  const [saleBlockList, setSaleBlockList] = useState<Department[]>();

  const [assetManagementCode, setAssetManagementCode] = useState('');
  const [storeCode, setStoreCode] = useState('');
  const [storeName, setStoreName] = useState('');
  const [companySelected, setCompanySelected] = useState<string>('-1');
  const [saleDepartmentSelected, setSaleDepartmentSelected] = useState<string>('-1');
  const [saleBlockSelected, setSaleBlockSelected] = useState<string>('-1');
  const [inventoryMonth, setInventoryMonth] = useState(new Date());
  const [inventoryListSearch, setInventoryListSearch] = useState<Inventory[]>();

  useEffect(() => {
    async function initData() {
      dispatch(inventoryListActions.fetchInventoryMasterData());
      const year = parseInt(String(selectedUser?.data_date).substring(0, 4));
      const month = parseInt(String(selectedUser?.data_date).substring(4, 6));
      const day = parseInt(String(selectedUser?.data_date).substring(6, 8));
      const str_date = `${year}-${month}-${day}`;
      setInventoryMonth(new Date(str_date));
      const filter = {
        department_id: selectedJob?.department_id || null,
        month: month,
        year: year,
      };
      dispatch(inventoryHistoryActions.fetchSaleDepartment(filter));
    }
    initData();
    handleSearchInventory(true);
    return () => {
      setCompanySelected('-1');
      setSaleDepartmentSelected('-1');
      setSaleBlockSelected('-1');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inventorHistoryListSelector && inventorHistoryListSelector.length > 0) {
      setInventoryListSearch(inventorHistoryListSelector);
    } else {
      setInventoryListSearch([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventorHistoryListSelector]);

  useEffect(() => {
    if (selectedInventoryMasterData && selectedInventoryMasterData.inventory_status.length > 0) {
      const status = selectedInventoryMasterData.inventory_status.map((s: InventoryStatus) => s.id);
      setSelectedInventoryStatus(status);
      setUnSelectedInventoryStatus(Object.assign([], status));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInventoryMasterData]);

  useEffect(() => {
    if (companiesSelector && companiesSelector.length > 0) {
      const company = companiesSelector.find((company: Company) => company.cd === selectedJob?.company_cd);
      let company_id = company?.id || '';
      if (companiesSelector.length === 1) {
        company_id = company_id !== '' && companiesSelector.length === 1 ? companiesSelector[0].id : 0;
      } else {
        company_id = '';
      }
      setCompanySelected(company_id.toString());
    } else {
      if (departmentsSelector === undefined || (departmentsSelector && departmentsSelector.length === 0)) {
        setCompanySelected('0');
      } else {
        setCompanySelected('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentsSelector]);

  useEffect(() => {
    if (companySelected && areaDepartmentsSelector.length > 0) {
      const saleDepartments = areaDepartmentsSelector.filter(
        (department: Department) => department.company.id === parseInt(companySelected)
      );
      const area_department = saleDepartments?.find((d: Department) => d.cd === selectedJob?.department_cd);
      let department_id = area_department?.id || '';
      if (saleDepartments.length === 1) {
        department_id = saleDepartments[0].id;
      }
      setSaleDepartmentList(saleDepartments);
      setSaleDepartmentSelected(department_id.toString());
    } else {
      setSaleDepartmentList(undefined);
      if (departmentsSelector === undefined || (departmentsSelector && departmentsSelector.length === 0)) {
        setSaleDepartmentSelected('0');
      } else {
        setSaleDepartmentSelected('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentsSelector, companySelected]);

  useEffect(() => {
    if (saleDepartmentSelected && blockDepartmentsSelector.length > 0) {
      const saleBlocks = blockDepartmentsSelector.filter(
        (department: Department) => department.up_department_id === parseInt(saleDepartmentSelected)
      );
      const block_department = saleBlocks?.find((d: Department) => d.cd === selectedJob?.department_cd);
      let department_id = block_department?.id || '';
      if (blockDepartmentsSelector.length === 1) {
        department_id = blockDepartmentsSelector[0].id;
      }
      setSaleBlockList(saleBlocks);
      setSaleBlockSelected(department_id.toString());
    } else {
      setSaleBlockList(undefined);
      if (departmentsSelector === undefined || (departmentsSelector && departmentsSelector.length === 0)) {
        setSaleBlockSelected('0');
      } else {
        setSaleBlockSelected('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleDepartmentSelected]);

  const handleAssetManagementNumberSelected = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value;
    setAssetManagementCode(value);
  };

  const handleStoreCodeSelected = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value;
    setStoreCode(value);
  };

  const handleStoreNameSelected = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value;
    setStoreName(value);
  };

  const handleCompanySelected = (event: ChangeEvent<HTMLSelectElement>) => {
    let value = event.currentTarget.value;
    setCompanySelected(value);
  };

  const handleSaleDepartmentSelected = (event: ChangeEvent<HTMLSelectElement>) => {
    let value = event.currentTarget.value;
    setSaleDepartmentSelected(value);
  };

  const handleSaleBlockSelected = (event: React.FormEvent<HTMLSelectElement>) => {
    let value = event.currentTarget.value;
    setSaleBlockSelected(value);
  };

  const handleCheckboxInventoryStatus = (event: ChangeEvent<HTMLInputElement>) => {
    let arr_inventory_status = selectedInventoryStatus;
    const index = arr_inventory_status.indexOf(parseInt(event.currentTarget.id));
    if (event.currentTarget.checked === false && index !== -1) {
      arr_inventory_status.splice(index, 1);
    } else {
      arr_inventory_status.push(parseInt(event.currentTarget.id));
    }
    setSelectedInventoryStatus(arr_inventory_status);
  };

  const handleSearchInventory = (fake_api_call = false) => {
    const not_in_inventory_status = _.difference(unSelectedInventoryStatus, selectedInventoryStatus);
    const company = companiesSelector.find((company) => company.id === (parseInt(companySelected) || 0));
    const department = saleDepartmentList?.find(
      (department) => department.id === (parseInt(saleDepartmentSelected) || 0)
    );
    const block = saleBlockList?.find((department) => department.id === (parseInt(saleBlockSelected) || 0));
    const month = inventoryMonth.getMonth() + 1;
    const year = inventoryMonth.getFullYear();
    let search_inventory = {
      inventory_month: year + '' + (month > 9 ? month : '0' + month),
      inventory_status: selectedInventoryStatus,
      asset_manage_code: assetManagementCode,
      store_code: storeCode,
      store_name: storeName,
      company_id: company?.id || null,
      sale_department_id: department?.id || null,
      block_department_id: block?.id || null,
      store_department_id: selectedJob?.department_id || null,
      not_in_inventory_status: not_in_inventory_status,
      fake_api_call: fake_api_call,
    } as SearchInventoryHistory;
    dispatch(inventoryHistoryActions.fetchInventory(search_inventory));
  };

  const handleChangeInventoryMonth = (date: Date) => {
    setInventoryMonth(date);
    const filter = {
      department_id: selectedJob?.department_id || null,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    dispatch(inventoryHistoryActions.fetchSaleDepartment(filter));
  };

  const linkToAssetDetail = (value: string, management_no: string) => {
    if (permissions && permissions.includes('show_asset_detail_link')) {
      return (
        <a target="_blank" rel="noreferrer" href={`/asset/edit/${value}`}>
          {management_no}
        </a>
      );
    } else {
      return <>{management_no}</>;
    }
  };

  const CustomInputDatePicker = forwardRef((props: any, ref: any) => {
    // You can style this further
    return (
      <>
        <div style={{ position: 'relative' }}>
          <FaCalendarAlt style={{ position: 'absolute', top: '8px', left: '10px' }} onClick={props.onClick} />
          <input
            className="form-control"
            onClick={props.onClick}
            style={{ paddingLeft: '32px' }}
            value={props.value}
            onChange={props.onChange}
            type="text"
          />
        </div>
      </>
    );
  });

  return (
    <div className="page-wrapper">
      <div className="page-header">
        <StatusHeader onAction={() => history.push('/')} />
      </div>
      <div className="page-main">
        <div className="page-content">
          <Card className="mb-5">
            <Card.Header className="text-left">{t('InventoryHistoryFeature.Text.search_condition')}</Card.Header>
            <Card.Body>
              <Accordion className="mb-3" defaultActiveKey={['0', '1', '2']} alwaysOpen>
                <Accordion.Item eventKey="0" className="movement-information">
                  <Accordion.Header>{t('InventoryHistoryFeature.Text.inventory_information')} </Accordion.Header>
                  <Accordion.Body>
                    <div className="mb-3 p-3">
                      <Row>
                        <Col xs={2}></Col>
                        <Col xs={6} className="d-flex flex-column">
                          <div className="area-search-date">
                            <div className="d-flex mb-3 align-items-center">
                              <div>
                                <span>
                                  <span>{t('InventoryHistoryFeature.Text.inventory_month')}</span>&nbsp;&nbsp;
                                </span>
                              </div>
                              <div>
                                <div className="d-flex" style={{ maxWidth: '100px' }}>
                                  <CustomDatePicker
                                    value={inventoryMonth}
                                    onChange={(date: Date) => handleChangeInventoryMonth(date)}
                                    customInputComponent={<CustomInputDatePicker />}
                                    dateFormat="yyyy/MM"
                                    showMonthYearPicker={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div>
                                <span>
                                  <span>{t('InventoryHistoryFeature.Text.inventory_status')}</span>&nbsp;&nbsp;
                                </span>
                              </div>
                              <div className="d-flex">
                                {selectedInventoryMasterData.inventory_status?.map((status: InventoryStatus, index) => {
                                  return (
                                    <Form.Check
                                      key={index}
                                      type="checkbox"
                                      id={status.id.toString()}
                                      defaultChecked={true}
                                      onChange={handleCheckboxInventoryStatus}
                                      name={status.name}
                                      label={status.name}
                                      className="ms-4"
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="movement-information">
                  <Accordion.Header>{t('InventoryHistoryFeature.Text.basic_information')} </Accordion.Header>
                  <Accordion.Body>
                    <div className="mb-3 p-3">
                      <Row>
                        <Col xs={2} className="d-flex justify-content-center align-items-center"></Col>
                        <Col xs={6} className="d-flex flex-column">
                          <Row className="mb-3">
                            <Form.Label className="col-2 col-form-label">
                              {t('InventoryHistoryFeature.Text.asset_management_number')}
                            </Form.Label>
                            <Col xs={3}>
                              <Form.Control onChange={handleAssetManagementNumberSelected} />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Form.Label className="col-2 col-form-label">
                              {t('InventoryHistoryFeature.Text.store_code')}
                            </Form.Label>
                            <Col xs={3}>
                              <Form.Control onChange={handleStoreCodeSelected} />
                            </Col>
                            <Form.Label className="col-2 col-form-label">
                              {t('InventoryHistoryFeature.Text.store_name')}
                            </Form.Label>
                            <Col xs={3}>
                              <Form.Control onChange={handleStoreNameSelected} />
                            </Col>
                          </Row>
                          <Row className="d-flex  align-items-center">
                            <Form.Label className="col-form-label col-md-2  col-xs-2">
                              {t('InventoryHistoryFeature.Text.organization_selection')}
                            </Form.Label>
                            <div className="form-group col-xs-3 col-md-3">
                              <label className="control-label float-start p-1">
                                {' '}
                                {t('InventoryHistoryFeature.Text.company_selection')}
                              </label>
                              <Form.Select
                                onChange={handleCompanySelected}
                                value={companySelected || ''}
                                disabled={companiesSelector && companiesSelector.length <= 1}
                              >
                                {(companiesSelector === undefined ||
                                  (companiesSelector && companiesSelector.length <= 1)) && <option value="0"></option>}
                                {<option value="">{t('InventoryHistoryFeature.Text.all')}</option>}
                                {companiesSelector &&
                                  companiesSelector.length > 0 &&
                                  companiesSelector.map((company, index) => {
                                    return (
                                      <option key={index} value={company.id}>
                                        {company.name}
                                      </option>
                                    );
                                  })}
                              </Form.Select>
                            </div>

                            <div className="form-group col-xs-3 col-md-3">
                              <label className="control-label float-start p-1">
                                {' '}
                                {t('InventoryHistoryFeature.Text.select_area_or_sales_department')}
                              </label>

                              <Form.Select
                                onChange={handleSaleDepartmentSelected}
                                value={saleDepartmentSelected || ''}
                                disabled={
                                  saleDepartmentList === undefined ||
                                  (saleDepartmentList && saleDepartmentList.length <= 1)
                                }
                              >
                                {(saleDepartmentList === undefined ||
                                  (saleDepartmentList && saleDepartmentList.length <= 1)) && (
                                  <option value="0"></option>
                                )}
                                {<option value="">{t('InventoryHistoryFeature.Text.all')}</option>}
                                {saleDepartmentList &&
                                  saleDepartmentList.length > 0 &&
                                  saleDepartmentList.map((department, index) => {
                                    return (
                                      <option key={index} value={department.id}>
                                        {department.name}
                                      </option>
                                    );
                                  })}
                              </Form.Select>
                            </div>

                            <div className="form-group col-xs-3 col-md-3">
                              <label className="control-label float-start p-1">
                                {t('InventoryHistoryFeature.Text.block_or_area_selection')}
                              </label>
                              <Form.Select
                                onChange={handleSaleBlockSelected}
                                value={saleBlockSelected || ''}
                                disabled={saleBlockList === undefined || (saleBlockList && saleBlockList.length <= 1)}
                              >
                                {(saleBlockList === undefined || (saleBlockList && saleBlockList.length <= 1)) && (
                                  <option value="0"></option>
                                )}
                                {<option value="">{t('InventoryHistoryFeature.Text.all')}</option>}
                                {saleBlockList &&
                                  saleBlockList.length > 0 &&
                                  saleBlockList.map((department, index) => {
                                    return (
                                      <option key={index} value={department.id}>
                                        {department.name}
                                      </option>
                                    );
                                  })}
                              </Form.Select>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="text-center">
                <Button
                  onClick={() => {
                    handleSearchInventory();
                  }}
                >
                  {t('InventoryHistoryFeature.Component.search')}
                </Button>
              </div>
            </Card.Body>
          </Card>
          <Row className="mb-2">
            <Col className="d-flex justify-content-start">
              <div className="align-self-end">
                <span className="me-4">{t('InventoryHistoryFeature.Text.number_of_searches')}</span>
                <span>
                  {inventoryListSearch ? inventoryListSearch.length : 0} &nbsp;
                  {t('InventoryHistoryFeature.Text.subject')}
                </span>
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="align-self-end">
                <ExportCSV data={inventoryListSearch ?? []} />
              </div>
            </Col>
          </Row>
          <div className="sticky-table table-movement-history" style={{ maxHeight: '500px' }}>
            <Table hover>
              <thead>
                <tr>
                  <th>{t('InventoryHistoryFeature.Table.inventory_taking_month')}</th>
                  <th>{t('InventoryHistoryFeature.Table.inventory_status')}</th>
                  <th>{t('InventoryHistoryFeature.Table.company_name')}</th>
                  <th>{t('InventoryHistoryFeature.Table.area_sales_department')}</th>
                  <th>{t('InventoryHistoryFeature.Table.block_area')}</th>
                  <th>{t('InventoryHistoryFeature.Table.store_name')}</th>
                  <th>{t('InventoryHistoryFeature.Table.department_cd')}</th>
                  <th>{t('InventoryHistoryFeature.Table.restaurant_cd')}</th>
                  <th>{t('InventoryHistoryFeature.Table.user')}</th>
                  <th>{t('InventoryHistoryFeature.Table.asset_management_number')}</th>
                  <th>{t('InventoryHistoryFeature.Table.comment')}</th>
                  <th>
                    <span>{t('InventoryHistoryFeature.Table.system')}</span>
                    <span>{t('InventoryHistoryFeature.Table.message')}</span>
                  </th>
                  <th>{t('InventoryHistoryFeature.Table.classification')}</th>
                  <th>{t('InventoryHistoryFeature.Table.maker')}</th>
                  <th>{t('InventoryHistoryFeature.Table.model_name')}</th>
                  <th>{t('InventoryHistoryFeature.Table.usage')}</th>
                </tr>
              </thead>
              <tbody>
                {inventoryListSearch && inventoryListSearch.length > 0 ? (
                  inventoryListSearch.map((inventory, index) => (
                    <tr key={index} style={inventory.del_flag === true ? { backgroundColor: '#e2e3e5' } : {}}>
                      <td className="text-center">{`${
                        String(inventory.inventory_month).substring(0, 4) +
                        '/' +
                        String(inventory.inventory_month).substring(4, 6)
                      }`}</td>
                      <td className="text-center">{inventory.inventory_status_name}</td>
                      <td className="text-center">{inventory.company_name}</td>
                      <td className="text-center">{inventory.area_name}</td>
                      <td className="text-center">{inventory.block_name}</td>
                      <td className="text-center">{inventory.store_name}</td>
                      <td className="text-center">{inventory.department_cd}</td>
                      <td className="text-center">{inventory.store_cd}</td>
                      <td className="text-center">{inventory.current_possession_employee}</td>
                      <td className="text-center">
                        {linkToAssetDetail(inventory.asset_management_id, inventory.asset_management_no)}
                      </td>
                      <td className="text-center">{inventory.store_cmt}</td>
                      <td className="text-center">{inventory.system_mess}</td>
                      <td className="text-center">{inventory.department_classification}</td>
                      <td className="text-center">{inventory.manufacturer}</td>
                      <td className="text-center">{inventory.model_name}</td>
                      <td className="text-center">{inventory.current_use}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center"></td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryHistoryConfirmation;
