export const authFeatureTranslationEN = {
  Login: {
    title: 'Asset management system',
    company_name: 'company name',
    employee_code: 'Employee code',
    password: 'password',
    login_with_previous_month_organization: 'Login in the previous month of organization',
    selection: 'Selection',
    sign_in: 'Login',
    concurrent_post_proxy_authority_exists: 'Concurrent post proxy authority exists',
    please_select_a_job: 'Please select your duties.',
    anrakutei_AAA_block_block_length: 'AAAA block block length',
    manager_of_anrakutei: 'Anrakutei Urayasu store manager',
    please_select_a_company: 'please select a company',
  },
  Error: {
    password: 'please enter password',
    company: 'please enter company',
    employee_id: 'please enter employee code',
  },
};
