import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AxiosError } from 'axios';
import { GlobalModalContext } from 'components/common/GlobalModal';
import { selectEmployeeInfo, selectJobInfo } from 'features/Auth/authSlice';
import approvalStatusService from 'features/common/approvalStatusService';
import { approvalStatusActions, selectApprovalStatusList } from 'features/common/approvalStatusSlice';
import { ApprovalStatus } from 'features/common/models/approvalStatus';
import _ from 'lodash';
import { BranchKey, CommonResponse } from 'models';
import { createContext, useEffect, useState } from 'react';
import { handleServiceError } from 'utils/common';
import { BranchMenu, BranchMonthPicker } from './components';
import { FEATURE_APPROVAL_STATUS, INSPECTION_APPROVAL_STATUS, INVENTORY_APPROVAL_STATUS } from './constants';
import { OrganizationTreeFilter } from './models/common';
import { CompanyNode } from './models/company';
import { DepartmentNode } from './models/department';
import './style.scss';
import { findNode, findParentKeys, getBranchKey } from './utils';
import { SIDE_BAR_FOR_INVENTORY } from 'utils';
import moment from 'moment';

type BranchMenuContextType = {
  path: BranchKey[];
  activeBranchKey?: BranchKey;
  changeSelectedBranch: ((data: CompanyNode | DepartmentNode) => void) | undefined;
};

export const BranchMenuContext = createContext<BranchMenuContextType>({
  path: [],
  activeBranchKey: undefined,
  changeSelectedBranch: undefined,
});

export interface BranchSidebarProps {
  featureId: number;
  organizationData: CompanyNode[] | DepartmentNode[];
  activeBranchKey?: BranchKey;
  onSelectedBranchChanged?: (branch: CompanyNode | DepartmentNode, month: number, year: number) => void;
  onMonthChanged?: (filter: OrganizationTreeFilter) => void;
}

function BranchSidebar({
  featureId,
  organizationData,
  activeBranchKey = { id: 0, cd: '' },
  onSelectedBranchChanged,
  onMonthChanged,
}: BranchSidebarProps) {
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const dispatch = useAppDispatch();

  //state
  const [loading, setLoading] = useState<boolean>(true);
  const approvalStatusList = useAppSelector(selectApprovalStatusList);
  const selectedJob = useAppSelector(selectJobInfo);
  const employeeInfo = useAppSelector(selectEmployeeInfo);
  const defaultDate = employeeInfo?.previous_month_flg === 1 ? moment().subtract(1, 'months').toDate() : new Date();
  const [filter, setFilter] = useState<OrganizationTreeFilter>({
    departmentId: selectedJob?.department_id || 0,
    month: defaultDate.getMonth() + 1,
    year: defaultDate.getFullYear(),
    type: featureId === SIDE_BAR_FOR_INVENTORY ? INVENTORY_APPROVAL_STATUS : INSPECTION_APPROVAL_STATUS,
  });
  const initPathMenu = findParentKeys(organizationData, activeBranchKey.id, activeBranchKey.cd);
  const [pathMenu, setPathMenu] = useState<BranchKey[]>(initPathMenu ? [...initPathMenu, activeBranchKey] : []);
  const [selectedBranch, setSelectedBranch] = useState<CompanyNode | DepartmentNode | undefined>();

  useEffect(() => {
    const initPathMenu = findParentKeys(organizationData, activeBranchKey.id, activeBranchKey.cd);
    setPathMenu(initPathMenu ? [...initPathMenu, activeBranchKey] : []);
  }, [organizationData, activeBranchKey]);

  useEffect(() => {
    if (selectedBranch) {
      const branchKey = getBranchKey(selectedBranch);
      setSelectedBranch(findNode(organizationData, branchKey.id, branchKey.cd));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData]);

  useEffect(() => {
    (async () => {
      try {
        //load master data approval status
        const response: CommonResponse<{ approval_status: ApprovalStatus[] }> = await approvalStatusService.getAll();
        dispatch(approvalStatusActions.fetchApprovalStatusSuccess(response));
      } catch (e) {
        handleServiceError(showGlobalModal, e as AxiosError);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onMonthChanged && onMonthChanged(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (
      activeBranchKey.id !== 0 &&
      activeBranchKey.cd !== '' &&
      selectedBranch &&
      !_.isEqual(activeBranchKey, getBranchKey(selectedBranch))
    ) {
      const branch = findNode(organizationData, activeBranchKey.id, activeBranchKey.cd);
      if (branch) {
        onSelectedBranchChanged && onSelectedBranchChanged(branch, filter.month, filter.year);
        //scroll to node
        document.getElementById(`branch-${activeBranchKey.id}-${activeBranchKey.cd}`)?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBranchKey]);

  function handleMonthChanged(date: Date) {
    const newFilter = {
      ...filter,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    setFilter(newFilter);
  }

  function handleSelectedBranchChanged(branch: CompanyNode | DepartmentNode) {
    const branchKey = getBranchKey(branch);
    const parentMenuKeys = findParentKeys(organizationData, branchKey.id, branchKey.cd);
    if (pathMenu.some((pathItem) => pathItem.id === branchKey.id && pathItem.cd === branchKey.cd)) {
      if (parentMenuKeys) {
        setPathMenu([...parentMenuKeys, branchKey]);
      } else {
        setPathMenu([]);
      }
    } else {
      if (parentMenuKeys) {
        setPathMenu([...parentMenuKeys, branchKey]);
      } else {
        setPathMenu([branchKey]);
      }
    }
    setSelectedBranch(branch);

    onSelectedBranchChanged && onSelectedBranchChanged(branch, filter.month, filter.year);
  }

  if (loading) {
    return null;
  }

  return (
    <div className="branch-menu">
      <div className="mb-4">
        <BranchMonthPicker onMonthChanged={handleMonthChanged} />
      </div>
      <ul className="approval-status-list list-unstyled mb-4 d-flex flex-wrap">
        {approvalStatusList
          .filter((status) => FEATURE_APPROVAL_STATUS[featureId].includes(status.id))
          .map(function (item) {
            return (
              <li key={`approval-status-${item.id}`} className="approval-status-item mb-2 me-2">
                <span
                  className="branch-status me-1"
                  style={{
                    background: `${item.fill_color}`,
                    border: `1px solid ${item.line_color}`,
                  }}
                ></span>
                <span>{item.name}</span>
              </li>
            );
          })}
      </ul>

      <BranchMenuContext.Provider
        value={{
          path: pathMenu,
          activeBranchKey: activeBranchKey,
          changeSelectedBranch: handleSelectedBranchChanged,
        }}
      >
        <BranchMenu data={organizationData} />
      </BranchMenuContext.Provider>
    </div>
  );
}

export default BranchSidebar;
