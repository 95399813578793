import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CompanyNode, DepartmentNode, OrganizationTreeFilter } from 'components/BranchSidebar/models';
import { findNode } from 'components/BranchSidebar/utils';
import { BranchKey, CommonResponse } from 'models';

export interface InspectionState {
  loading: boolean;
  //organization tree data
  organizationData: CompanyNode[] | DepartmentNode[];
  selectedBranchKey: BranchKey;
}

const initialState: InspectionState = {
  loading: false,

  //organization tree data
  organizationData: [],
  selectedBranchKey: {
    id: 0,
    cd: '',
  },
};

const inspectionListSlice = createSlice({
  name: 'inspection',
  initialState,
  reducers: {
    fetchOrganizationData(state, action: PayloadAction<OrganizationTreeFilter>) {
      state.loading = true;
    },
    fetchOrganizationDataSuccess(state, action: PayloadAction<CommonResponse<CompanyNode[] | DepartmentNode[]>>) {
      state.organizationData = action.payload.data;
      state.selectedBranchKey = {
        ...state.selectedBranchKey,
        id: 0,
        cd: '',
      };
    },
    fetchOrganizationDataFailed(state) {
      state.loading = false;
    },
    updateSelectedBranchKey(state, action: PayloadAction<BranchKey>) {
      state.selectedBranchKey = {
        ...state.selectedBranchKey,
        id: action.payload.id,
        cd: action.payload.cd,
      };
    },
    reloadOrganizationData(state, action: PayloadAction<OrganizationTreeFilter>) {
      state.loading = true;
    },
    reloadOrganizationDataSuccess(state, action: PayloadAction<CommonResponse<CompanyNode[] | DepartmentNode[]>>) {
      state.organizationData = action.payload.data;
    },
    reloadOrganizationDataFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const inspectionListActions = inspectionListSlice.actions;

// Selectors
export const selectInspectionListLoading = (state: RootState) => state.inspection.loading;
export const selectOrganizationData = (state: RootState) => state.inspection.organizationData;
export const selectSelectedBranchKey = (state: RootState) => state.inspection.selectedBranchKey;
export const selectSelectedBranch = createSelector(
  [selectOrganizationData, selectSelectedBranchKey],
  (organizationData, selectedBranchKey) => {
    return findNode(organizationData, selectedBranchKey.id, selectedBranchKey.cd);
  }
);

// Reducer
export const inspectionReducer = inspectionListSlice.reducer;
