import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { DataLoadForm } from '../../models/DataLoadForm';
import { Asset } from '../../models/Asset';
import { ManufacturerPayload } from '../../models/Manufacturer';
import { AssetModelPayload } from '../../models/AssetModel';
export interface AssetState {
  loading: boolean;
  asset: Asset | {};
  add: any;
  dataLoadForm: DataLoadForm;
  manufacturers: ManufacturerPayload;
  models: AssetModelPayload;
}

const initialState: AssetState = {
  loading: false,
  asset: {
    status: '',
    message: '',
    data: [],
  },
  dataLoadForm: {
    status: '',
    message: '',
    data: {
      asset_type: [],
      asset_status: [],
      asset_uses: [],
      companies: [],
      storage_places: [],
      asset_classifications: [],
      accounting_class: [],
      purchased_method: [],
    },
  },
  add: {
    status: '',
    message: '',
    data: {},
  },
  manufacturers: {
    status: '',
    message: '',
    data: [],
  },
  models: {
    status: '',
    message: '',
    data: [],
  },
};

const assetAddSlice = createSlice({
  name: 'asset-add',
  initialState,
  reducers: {
    // fetch data of form
    fetchPreLoadFromAdd(state) {
      state.dataLoadForm = initialState.dataLoadForm;
    },
    fetchPreLoadFromAddSuccess(state, action: PayloadAction<DataLoadForm>) {
      state.dataLoadForm = action.payload;
    },
    fetchPreLoadFromAddFailed(state) {
      state.dataLoadForm = initialState.dataLoadForm;
    },

    // fetch data manufacturer
    fetchDataManufacturer(state, action) {
      state.manufacturers = initialState.manufacturers;
    },
    fetchDataManufacturerSuccess(state, action: PayloadAction<ManufacturerPayload>) {
      state.manufacturers = action.payload;
    },
    fetchDataManufacturerFailed(state) {
      state.manufacturers = initialState.manufacturers;
    },

    // fetch data model
    fetchDataModel(state, action) {
      state.models = initialState.models;
    },
    fetchDataModelSuccess(state, action: PayloadAction<AssetModelPayload>) {
      state.models = action.payload;
    },
    fetchDataModelFailed(state) {
      state.models = initialState.models;
    },
    // add asset
    saveAsset(state, action) {
      state.add = initialState.add;
    },
    saveAssetSuccess(state, action) {
      state.add = action.payload;
    },
    saveAssetFailed(state) {
      state.add = initialState.add;
    },
  },
});

// Actions
export const assetAddActions = assetAddSlice.actions;

// Selectors
export const selectDataLoadFormAdd = (state: RootState) => state.assetAdd.dataLoadForm;
export const selectManufacturers = (state: RootState) => state.assetAdd.manufacturers;
export const selectModels = (state: RootState) => state.assetAdd.models;

// Reducer
export const assetAddReducer = assetAddSlice.reducer;
