import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { Manufacturer } from './models/manufacturer';

const manufacturerService = {
  getAll(): Promise<CommonResponse<Manufacturer[]>> {
    const url = '/manufacturers';
    return axiosClient.get(url);
  },
  add(name: string): Promise<CommonResponse<any>> {
    const url = '/manufacturers';
    return axiosClient.post(url, { name });
  },
  delete(id: number): Promise<CommonResponse<null>> {
    const url = '/manufacturer/' + id;
    return axiosClient.delete(url);
  },
  update(payload: Manufacturer[]): Promise<CommonResponse<null>> {
    const url = '/manufacturers';
    return axiosClient.patch(url, payload);
  },
  restore(id: number): Promise<CommonResponse<null>> {
    const url = '/manufacturer/' + id + '/restore';
    return axiosClient.put(url);
  },
};

export default manufacturerService;
