import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { CommonResponse } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import assetUseService from './assetUseService';
import { assetUseActions } from './assetUseSlice';
import { AssetUse } from './models/assetUse';

function* fetchAssetUseList() {
  try {
    const response: CommonResponse<AssetUse[]> = yield call(assetUseService.getAll);
    yield put(assetUseActions.fetchAssetUseSuccess(response));
  } catch (e) {
    yield put(assetUseActions.fetchAssetUseFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* assetUseSaga() {
  yield takeLatest(assetUseActions.fetchAssetUseList.type, fetchAssetUseList);
}
