export const OwnershipChangeModalTranslationJP = {
  OwnershipChangeModal: {
    title: '保有先変更',
    from_company_id: '会社コード',
    from_department_id: '所属コード／名称',
    from_storage_id: '保管場所',
    from_employee_id: '社員コード／名称',
    radio_button_1: '保管場所移動',
    to_storage_place_id: '保管場所選択',
    radio_button_2: '所属移動（店舗／部門への移動）',
    to_company_id: '会社',
    to_department_id: '所属コード',
    to_department_name: '所属名称',
    radio_button_3: '所属移動（利用者指定あり）',
    to_department_name_btn: '所属検索',
    to_employee_id: '社員コード',
    to_employee_name: '社員名称',
    to_employee_name_btn: '社員検索',
    moving_day: '移動日',
    btn_ok: '反映',
    btn_cancel: '戻る',
  },
};
