import { CommonResponse } from 'models/common';
import axiosClient from 'utils/axiosClient';
import { Inventory, SearchInventoryHistory, Department, SearchDepartment } from './models/inventory';

const inventoryService = {
  getInventoryList(data: SearchInventoryHistory): Promise<CommonResponse<Array<Inventory>>> {
    const url = `/inventory-history`;
    return axiosClient.post(url, data);
  },
  getDepartmentList(data: SearchDepartment): Promise<CommonResponse<Array<Department>>> {
    const url = `/department-list`;
    return axiosClient.post(url, data);
  },
};

export default inventoryService;
