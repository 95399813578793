import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface AppState {
  loading: boolean;
}

const initialState: AppState = {
  loading: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showAppLoading(state) {
      state.loading = true;
    },
    hideAppLoading(state) {
      state.loading = false;
    },
  },
});

// Actions
export const appActions = appSlice.actions;

// Selectors
export const selectAppLoading = (state: RootState) => state.app.loading;

// Reducer
export const appReducer = appSlice.reducer;
