import { forwardRef } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';

const CustomInputDatePicker = forwardRef((props: any, ref: any) => {
  // You can style this further
  return (
    <>
      <div style={{ position: 'relative' }}>
        <FaCalendarAlt style={{ position: 'absolute', top: '0.625rem', left: '0.625rem' }} onClick={props.onClick} />
        <input
          className="form-control"
          onClick={props.onClick}
          style={{ paddingLeft: '32px' }}
          value={props.value}
          onChange={props.onChange}
          type="text"
        />
      </div>
    </>
  );
});

export default CustomInputDatePicker;
