export const inspectionHistoryFeatureTranslationEN = {
  InspectionHistoryFeature: {
    search_condition: 'Search condition',
    required_field: '※It is a required field',
    implementation_month: 'Implementation month',

    affiliation_selection: 'Affiliation selection',
    affiliation_code: 'Affiliation code',
    affiliation_name: 'Affiliation name',
    affiliation_search: 'Affiliation search',

    abnormality: 'Abnormality',
    normal: 'normal',
    abnormal: 'abnormal',

    search: 'search',
    csv_output: 'CSV output',

    month_of_inspection: 'Month of inspection',
    company_name: 'Company name',
    area_sales_department: 'Area/Sales Department',
    block_area: 'Block/Area',
    store_code: 'Store code',
    store_name: 'Store name',
    asset_management_number: 'Asset management number',
    device_name: 'Device name',
    manufacturer: 'Manufacturer',
    model: 'Model',
    extreme_rust: 'Extreme rust',
    installation_state: 'Installation state',
    liquid_sauce: 'Liquid sauce',
    unusual_sound: 'Unusual sound',
    abnormal_vibration: 'Abnormal vibration',
    operating_state: 'Operating state',
    remarks: 'Remarks',
  },
};
