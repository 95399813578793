import { push } from 'connected-react-router';
import { put, call, takeEvery } from 'redux-saga/effects';
import { assetAddActions } from './assetAddSlice';
import assetAddService from './assetService';
import { DataLoadForm } from 'features/AssetScreen/models/DataLoadForm';
import { ManufacturerPayload } from 'features/AssetScreen/models/Manufacturer';
import { AssetModelPayload } from 'features/AssetScreen/models/AssetModel';

function* getPreDataFormAdd() {
  try {
    const response: DataLoadForm = yield call(assetAddService.getPreDataFormAdd);
    yield put(assetAddActions.fetchPreLoadFromAddSuccess(response));
  } catch (e) {
    yield put(assetAddActions.fetchPreLoadFromAddFailed());
  }
}

function* fetchManufactures(action: any) {
  try {
    const params = action.payload;
    const response: ManufacturerPayload = yield call(assetAddService.fetchManufactures, params);
    yield put(assetAddActions.fetchDataManufacturerSuccess(response));
  } catch (e) {
    yield put(assetAddActions.fetchPreLoadFromAddFailed());
  }
}

function* fetchDataModels(action: any) {
  try {
    const params = action.payload;
    const response: AssetModelPayload = yield call(assetAddService.fetchModels, params);
    yield put(assetAddActions.fetchDataModelSuccess(response));
  } catch (e) {
    yield put(assetAddActions.fetchDataModelFailed());
  }
}

function* saveAsset(action: any) {
  try {
    const body = action.payload;
    const response: AssetModelPayload = yield call(assetAddService.saveAsset, body);
    yield put(assetAddActions.saveAssetSuccess(response));
    yield put(push('/asset'));
  } catch (e) {
    yield put(assetAddActions.saveAssetFailed());
  }
}

export default function* assetAddSaga() {
  yield takeEvery(assetAddActions.fetchPreLoadFromAdd.type, getPreDataFormAdd);
  yield takeEvery(assetAddActions.fetchDataManufacturer.type, fetchManufactures);
  yield takeEvery(assetAddActions.fetchDataModel.type, fetchDataModels);
  yield takeEvery(assetAddActions.saveAsset.type, saveAsset);
}
