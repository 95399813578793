import { MODAL_TYPES } from 'utils';
import AlertModal from '../AlertModal';
import ConfirmModal from '../ConfirmModal';
import DepartmentSearchModal from '../DepartmentSearchModal';
import EmployeeSearchModal from '../EmployeeSearchModal';
import AssetImageModal from '../AssetImageModal';
import createGlobalModalContext, { GlobalModalComponentType, GlobalModalStoreType } from './createGlobalModalContext';

export interface GlobalModalProps {
  children: React.ReactNode;
}

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.ALERT_MODAL]: AlertModal,
  [MODAL_TYPES.CONFIRM_MODAL]: ConfirmModal,
  [MODAL_TYPES.DEPARTMENT_SEARCH_MODAL]: DepartmentSearchModal,
  [MODAL_TYPES.EMPLOYEE_SEARCH_MODAL]: EmployeeSearchModal,
  [MODAL_TYPES.ASSET_IMAGE_MODAL]: AssetImageModal,
};

const initalState: GlobalModalStoreType<GlobalModalComponentType> = {
  modalType: null,
  modalProps: {
    title: '',
    message: '',
  },
};

export const GlobalModalContext = createGlobalModalContext(initalState);
const { GlobalModalProvider, useGlobalModalState } = GlobalModalContext;

function GlobalModalDisplay() {
  const [modalType] = useGlobalModalState((store) => store.modalType);
  const [modalProps] = useGlobalModalState((store) => store.modalProps);

  if (!modalType) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[modalType];
  if (!ModalComponent) {
    return null;
  }

  return <ModalComponent {...modalProps} />;
}

function GlobalModal({ children }: GlobalModalProps) {
  return (
    <GlobalModalProvider>
      <GlobalModalDisplay />
      {children}
    </GlobalModalProvider>
  );
}

export default GlobalModal;
