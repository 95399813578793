import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AdminMasterPage from './pages/AdminMasterPage';

function AdminMasterFeature() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminMasterPage />
      </Route>
    </Switch>
  );
}

export default AdminMasterFeature;
