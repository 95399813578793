import 'react-datepicker/dist/react-datepicker.css';
import { AssetStatusHistory } from 'features/AssetScreen/models/Asset';
import moment from 'moment';

interface AssetHistoryProps {
  idx: number;
  item: AssetStatusHistory;
}

function ItemStatus({ item, idx }: AssetHistoryProps) {
  return (
    <tr key={idx}>
      <td>{item.applied_start_date}</td>
      <td>{item.updated_employee?.name}</td>
      <td>{item.status?.name}</td>
      <td>資産ステータス情報変更</td>
      <td>{moment(item.updated_date).format('YYYY-MM-DD')}</td>
    </tr>
  );
}
export default ItemStatus;
