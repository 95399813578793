import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  assetClassificationActions,
  selectAssetClassificationList,
} from 'features/AdminMaster/assetClassificationSlice';
import { useLayoutEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { manufacturerActions, selectManufacturerList } from './manufacturerSlice';
import AssetModelPage from './pages/AssetModelPage';

export default function AssetModelFeature() {
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();

  //load master data
  const manufacturerList = useAppSelector(selectManufacturerList);
  const assetClassificationList = useAppSelector(selectAssetClassificationList);

  useLayoutEffect(() => {
    if (manufacturerList.length === 0) {
      dispatch(manufacturerActions.fetchManufacturerList());
    }
    if (assetClassificationList.length === 0) {
      dispatch(assetClassificationActions.fetchAssetClassificationList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route exact path={path}>
        <AssetModelPage />
      </Route>
    </Switch>
  );
}
