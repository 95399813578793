import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPES } from 'utils';
import { AlertModalProps } from '../AlertModal';
import { GlobalModalContext } from '../GlobalModal';
import {
  errorNotificationActions,
  selectErrorNotificationError,
  selectErrorNotificationIsOpen,
} from './errorNotificationSlice';

function ErrorNotification() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectErrorNotificationIsOpen);
  const error = useAppSelector(selectErrorNotificationError);
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();

  function handleClose() {
    dispatch(errorNotificationActions.hideError);
  }

  useEffect(() => {
    if (isOpen && error) {
      showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
        title: t('App.warning'),
        message: error.message,
        onClose: handleClose,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, error]);

  return <></>;
}

export default ErrorNotification;
