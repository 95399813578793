export const movementHistoryFeatureTranslationJP = {
  MovementHistoryFeature: {
    Component: {
      department_search: '所属検索',
      employee_search: '社員検索',
      search: '検索',
      csv_output: 'CSV出力',
    },
    Text: {
      search_condition: '検索条件',
      it_is_a_required_field: '※は必須項目です。',
      movement_information: '移動情報',
      moving_day: '移動日',
      moving_source: '移動元',
      sub_inventory_transfer: '保管場所移動',
      storage_location_selection: '保管場所選択',
      affiliation_transfer: '所属移動（店舗／部門への移動）',
      company: '会社',
      from_department_code: '所属コード',
      from_department_name: '所属名称',
      to_department_code: '所属コード',
      to_department_name: '所属名称',
      affiliation_movement: '所属移動（利用者指定あり）',
      employee_code: '社員コード',
      employee_name: '社員名称',
      basic_information: '基本情報',
      asset_management_number: '資産管理番号',
      accounting_information: '会計情報',
      accounting_number: '会計番号',
      search_result: '検索結果',
      number_of_searches: '検索件数',
      subject: '件',
    },
    Table: {
      moving_day: '移動日',
      control_number: '管理番号',
      accounting_number: '会計番号',
      classification: '分類',
      manufacturer: 'メーカー',
      model_name: '機種名',
      usage: '用途',
      purchase_date: '購入日',
      purchase_price: '購入価格',
      moving_source: '移動元',
      source_company: '移動元会社',
      source_department: '移動元部門',
      source_location: '保管場所',
      source_sub_inventory: '移動元保管場所',
      source_user: '移動元利用者',
      destination_company: '移動先会社',
      destination_department: '移動先部門',
      destination_sub_inventory: '移動先保管場所',
      destination_user: '移動先利用者',
      update_date: '更新日',
      changer: '更新者',
      destination: '移動先',
    },
  },
};
