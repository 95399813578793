import { branchSidebarTranslationEN } from 'components/BranchSidebar/locales';
import { alertModalTranslationEN } from 'components/common/AlertModal/translation/locales';
import { confirmModalTranslationEN } from 'components/common/ConfirmModal/translation/locales';
import { departmentSearchModalTranslationEN } from 'components/common/DepartmentSearchModal/translation/locales';
import { employeeSearchModalTranslationEN } from 'components/common/EmployeeSearchModal/translation/locales';
import { statusHeaderTranslationEN } from 'components/common/StatusHeader/translation/locales';
import { assetModelFeatureTranslationEN } from 'features/AssetModel/translation/locales';
import { AssetScreenTranslationEN } from 'features/AssetScreen/translation/locales';
import { authFeatureTranslationEN } from 'features/Auth/translation/locales';
import { movementHistoryFeatureTranslationEN } from 'features/MovementHistory/translation/locales';
import { mainMenuFeatureTranslationEN } from 'features/MainMenu/translation/locales';
import { inspectionHistoryFeatureTranslationEN } from 'features/InspectionHistory/translation/locales';
import { adminMasterFeatureTranslationEN } from 'features/AdminMaster/translation/locales';
import { inventoryHistoryFeatureTranslationEN } from 'features/InventoryHistoryConfirmation/translation/locales';

const commonTranslationEN = {
  App: {
    app_name: 'Asset management system',
    error: 'Error',
    info: 'Info',
    warning: 'Warning',
    confirm: 'Confirmation',
    loading: 'Loading...',
  },
  ...statusHeaderTranslationEN,
  ...confirmModalTranslationEN,
  ...alertModalTranslationEN,
  ...departmentSearchModalTranslationEN,
  ...employeeSearchModalTranslationEN,
  ...branchSidebarTranslationEN,
};

export const translationEN = {
  ...commonTranslationEN,
  ...AssetScreenTranslationEN,
  ...assetModelFeatureTranslationEN,
  ...authFeatureTranslationEN,
  ...movementHistoryFeatureTranslationEN,
  ...mainMenuFeatureTranslationEN,
  ...inspectionHistoryFeatureTranslationEN,
  ...adminMasterFeatureTranslationEN,
  ...inventoryHistoryFeatureTranslationEN,
};
