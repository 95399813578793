import { TOKEN_KEY } from './constants/authConstant';
import EncryptUtils from '../../utils/EncryptUtils';
import { Token } from './models/login';

const authStorage = {
  getTokens() {
    const storage = localStorage.getItem(TOKEN_KEY);
    let token = storage ? JSON.parse(storage) : null;
    return token;
  },

  getAccessToken() {
    let token = this.getTokens();
    let access = token !== null ? (EncryptUtils.decrypt(token.access) as string) : token;
    return access;
  },

  getRefreshToken() {
    const token = this.getTokens();
    let refresh = token && token.refresh ? (EncryptUtils.decrypt(token.refresh) as string) : null;
    return refresh;
  },

  setTokens(token: Token) {
    const tokenStorage = {
      access: EncryptUtils.encrypt(token.access),
      refresh: EncryptUtils.encrypt(token.refresh),
    };
    localStorage.setItem(TOKEN_KEY, JSON.stringify(tokenStorage));
  },

  removeTokens() {
    localStorage.removeItem(TOKEN_KEY);
  },

  isAuthenticated() {
    const tokens = this.getTokens();
    return tokens && tokens.access;
  },
};

export default authStorage;
