export const mainMenuFeatureTranslationEN = {
  MainMenuFeature: {
    todo_list: 'To-do List',
    added_date: 'Added date',
    item: 'Item',
    deadline: 'Deadline',
    work_content: 'Work content',
    asset_search: 'Asset search',
    asset_search_note: 'Please use it when checking the status of assets.',
    system_administrators: 'For system administrators',
    inventory: 'Inventory',
    legal_equipment_inspection: 'Legal equipment inspection',
    check_inventory_history: 'Check Inventory History',
    check_movement_history: 'Check movement history',
    model_master: 'Model master',
    asset_detail: 'Asset detail',
    confirmation_of_status_equipment: 'Confirmation of statutory equipment inspection history',
    admin_master: 'Master Admin',
    deadline_unknown: 'Unknown',
  },
};
