import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { DataLoadForm } from 'features/AssetScreen/models/DataLoadForm';
import { Asset } from '../../models/Asset';
import { ManufacturerPayload } from '../../models/Manufacturer';
import { AssetModelPayload } from '../../models/AssetModel';
import { CommonResponse } from 'models';
export interface AssetState {
  loading: boolean;
  asset:
    | CommonResponse<Asset>
    | {
        status: '';
        message: '';
        data: {};
      };
  edit: any;
  dataLoadForm: DataLoadForm;
  manufacturers: ManufacturerPayload;
  models: AssetModelPayload;
}

const initialState: AssetState = {
  loading: false,
  asset: {
    status: '',
    message: '',
    data: {},
  },
  dataLoadForm: {
    status: '',
    message: '',
    data: {
      asset_type: [],
      asset_status: [],
      asset_uses: [],
      companies: [],
      storage_places: [],
      asset_classifications: [],
      accounting_class: [],
      purchased_method: [],
    },
  },
  edit: {
    status: '',
    message: '',
    data: [],
  },
  manufacturers: {
    status: '',
    message: '',
    data: [],
  },
  models: {
    status: '',
    message: '',
    data: [],
  },
};

const assetEditSlice = createSlice({
  name: 'asset-edit',
  initialState,
  reducers: {
    // fetch data of form
    fetchPreLoadFromEdit(state) {
      state.dataLoadForm = initialState.dataLoadForm;
    },
    fetchPreLoadFromEditSuccess(state, action: PayloadAction<DataLoadForm>) {
      state.dataLoadForm = action.payload;
    },
    fetchPreLoadFromEditFailed(state) {
      state.dataLoadForm = initialState.dataLoadForm;
    },

    // fetch data manufacturer
    fetchDataManufacturer(state, action) {
      state.manufacturers = initialState.manufacturers;
    },
    fetchDataManufacturerSuccess(state, action: PayloadAction<ManufacturerPayload>) {
      state.manufacturers = action.payload;
    },
    fetchDataManufacturerFailed(state) {
      state.manufacturers = initialState.manufacturers;
    },

    // fetch data model
    fetchDataModel(state, action) {
      state.models = initialState.models;
    },
    fetchDataModelSuccess(state, action: PayloadAction<AssetModelPayload>) {
      state.models = action.payload;
    },
    fetchDataModelFailed(state) {
      state.models = initialState.models;
    },
    // edit asset
    saveAsset(state, action) {
      state.edit = initialState.edit;
    },
    saveAssetSuccess(state, action) {
      state.edit = action.payload;
    },
    saveAssetFailed(state) {},

    // get asset
    getAsset(state, action) {
      state.asset = initialState.asset;
    },
    getAssetSuccess(state, action) {
      state.asset = action.payload;
    },
    getAssetFailed(state, action) {
      state.asset = action.payload;
    },
  },
});

// Actions
export const assetEditActions = assetEditSlice.actions;

// Selectors
export const selectAsset = (state: RootState) => state.assetEdit.asset;
export const selectDataLoadForm = (state: RootState) => state.assetEdit.dataLoadForm;
export const selectManufacturers = (state: RootState) => state.assetEdit.manufacturers;
export const selectModels = (state: RootState) => state.assetEdit.models;

// Reducer
export const assetEditReducer = assetEditSlice.reducer;
