import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { CommonResponse } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import storagePlaceService from './storagePlaceService';
import { storagePlaceActions } from './storagePlaceSlice';
import { StoragePlace } from './models/storagePlace';

function* fetchStoragePlaceList() {
  try {
    const response: CommonResponse<StoragePlace[]> = yield call(storagePlaceService.getAll);
    yield put(storagePlaceActions.fetchStoragePlaceSuccess(response));
  } catch (e) {
    yield put(storagePlaceActions.fetchStoragePlaceFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* storagePlaceSaga() {
  yield takeLatest(storagePlaceActions.fetchStoragePlaceList.type, fetchStoragePlaceList);
}
