import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CommonResponse } from 'models';
import { AssetStatus } from './models/assetStatus';

export interface AssetStatusState {
  loading: boolean;
  list: AssetStatus[];
}

const initialState: AssetStatusState = {
  loading: false,
  list: [],
};

const assetStatusSlice = createSlice({
  name: 'asset_status',
  initialState,
  reducers: {
    fetchAssetStatusList(state) {
      state.loading = true;
    },
    fetchAssetStatusSuccess(state, action: PayloadAction<CommonResponse<AssetStatus[]>>) {
      state.list = action.payload.data;
      state.loading = false;
    },
    fetchAssetStatusFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const assetStatusActions = assetStatusSlice.actions;

// Selectors
export const selectAssetStatusLoading = (state: RootState) => state.assetStatus.loading;
export const selectAssetStatusList = (state: RootState) => state.assetStatus.list;

// Reducer
export const assetStatusReducer = assetStatusSlice.reducer;
