import { Route, Switch, useRouteMatch } from 'react-router-dom';
import InventoryListPage from './pages/InventoryListPage';

export default function Inventory() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <InventoryListPage />
      </Route>
    </Switch>
  );
}
