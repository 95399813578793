export const adminMasterFeatureTranslationEN = {
  AdminMasterFeature: {
    sign_up: 'Register',
    name: 'Name',
    action: 'Action',
    change: 'Update',
    stop: 'Stop',

    //warning
    confirm_delete: 'Are you sure to delete it?',
    save_data_successful: 'Save data successfully',
    no_data_changed: 'No data changed',

    //form
    warning_required_field: 'This field is required',

    //asset_status
    asset_status_headtitle: 'Asset status master',

    //asset_classification
    classification_code: 'Code',
    asset_classification_headtitle: 'Asset classification master',

    //storage_place
    is_inventory: 'Is inventory',
  },
};
