import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { CommonResponse } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import assetClassificationService from './assetClassificationService';
import { assetClassificationActions } from './assetClassificationSlice';
import { AssetClassification } from './models/assetClassification';

function* fetchAssetClassificationList() {
  try {
    const response: CommonResponse<AssetClassification[]> = yield call(assetClassificationService.getAll);
    yield put(assetClassificationActions.fetchAssetClassificationSuccess(response));
  } catch (e) {
    yield put(assetClassificationActions.fetchAssetClassificationFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* assetClassificationSaga() {
  yield takeLatest(assetClassificationActions.fetchAssetClassificationList.type, fetchAssetClassificationList);
}
