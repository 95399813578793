export const inventoryHistoryFeatureTranslationJP = {
  InventoryHistoryFeature: {
    Component: {
      affiliation_search: '所属検索',
      employee_search: '社員検索',
      search: '検索',
      csv_output: 'CSV出力',
    },
    Text: {
      search_condition: '検索条件',
      it_is_a_required_field: '※は必須項目です。',
      inventory_information: '棚卸情報',
      inventory_month: '棚卸月',
      inventory_status: '棚卸ステータス',
      in_use: '使用中',
      out_of_order: '故障中',
      under_repair: '修理中',
      lost: '紛失',
      basic_information: '基本情報',
      asset_management_number: '資産管理番号',
      store_code: '店舗コード',
      store_name: '店舗名称',
      organization_selection: '組織選択',
      company_selection: '会社選択',
      select_area_or_sales_department: 'エリア/営業部選択',
      block_or_area_selection: 'ブロック/エリア選択',
      number_of_searches: '検索件数',
      subject: '件',
      all: '全て',
      please_select_a_company: '会社を選択して下さい',
    },
    Table: {
      inventory_taking_month: '棚卸実施月',
      inventory_status: '棚卸ステータス',
      company_name: '会社名',
      area_sales_department: 'エリア／営業部',
      block_area: 'ブロック／エリア',
      store_name: '店舗名',
      department_cd: '所属コード',
      restaurant_cd: '店舗コード',
      user: '利用者',
      asset_management_number: '資産管理番号',
      comment: 'コメント',
      system: 'システム',
      message: 'メッセージ',
      classification: '分類',
      maker: 'メーカー',
      model_name: '機種名',
      usage: '用途',
      system_messages: 'システムメッセージ',
    },
  },
};
