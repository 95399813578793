import ErrorNotification from 'components/common/ErrorNotification';
import GlobalModal from 'components/common/GlobalModal';
import LoadingIndicator from 'components/common/LoadingIndicator';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { AppLayout } from 'components/layout';
import LoginPage from 'features/Auth/pages/LoginPage';
import { Route, Switch } from 'react-router-dom';
import './App.scss';

function App() {
  return (
    <div className="app">
      <LoadingIndicator />
      <GlobalModal>
        <ErrorNotification />
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route path="/">
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          </Route>
        </Switch>
      </GlobalModal>
    </div>
  );
}

export default App;
