import { useParams, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import StatusHeader from 'components/common/StatusHeader';
import { useEffect, useState } from 'react';
import AssetFormEdit from './components/AssetFormEdit';
import { assetEditActions, selectDataLoadForm } from './assetEditSlice';
import assetEditService from 'features/AssetScreen/pages/edit/assetService';
import { Asset } from 'features/AssetScreen/models/Asset';
import { CommonResponse } from 'models';
import { AxiosError } from 'axios';
import { AlertModalProps } from 'components/common/AlertModal';
import { GlobalModalContext } from 'components/common/GlobalModal';
import { MODAL_TYPES } from 'utils';
import { useTranslation } from 'react-i18next';

function AssetEditPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const dataLoadForm = useAppSelector(selectDataLoadForm);

  const assetDefault = {} as CommonResponse<Asset>;
  const [asset, setAsset] = useState<CommonResponse<Asset>>(assetDefault);
  let { id } = useParams<{ id: string }>();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const { t } = useTranslation();

  function handleShowAlertErrors(message: string) {
    showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
      title: t('ERROR'),
      message: message,
      onClose: handlePageBack,
    });
  }

  useEffect(() => {
    const getAsset = async (id: any) => {
      await assetEditService
        .getAssetById(id)
        .then((asset) => {
          setAsset(asset);
        })
        .catch((err: AxiosError) => {
          const data: any = err.response?.data as CommonResponse<Asset>;
          handleShowAlertErrors(data?.detail);
        });
    };
    getAsset(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(assetEditActions.fetchPreLoadFromEdit());
  }, [dispatch]);

  const handlePageBack = () => {
    history.push('/asset');
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-header">
          <StatusHeader actionName="back" onAction={handlePageBack} />
        </div>
        <div className="page-main">
          <div className="page-content">
            {asset.status === 'success' && <AssetFormEdit dataLoad={dataLoadForm} asset={asset.data} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default AssetEditPage;
