import ExportLink from 'components/common/ExportLink';
import { useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa';
import { InspectionHistoryDisplay } from '../models/inspectionHistory';

interface ExportCSVProps {
  data: InspectionHistoryDisplay[];
}

export default function ExportCSV({ data }: ExportCSVProps) {
  const { t } = useTranslation();

  const headers = [
    { header: t('InspectionHistoryFeature.month_of_inspection'), key: 'month' },
    { header: t('InspectionHistoryFeature.company_name'), key: 'company_name' },
    { header: t('InspectionHistoryFeature.area_sales_department'), key: 'area' },
    { header: t('InspectionHistoryFeature.block_area'), key: 'block' },
    { header: t('InspectionHistoryFeature.store_code'), key: 'department_cd' },
    { header: t('InspectionHistoryFeature.store_name'), key: 'department_name' },
    { header: t('InspectionHistoryFeature.asset_management_number'), key: 'management_no' },
    { header: t('InspectionHistoryFeature.device_name'), key: 'classification_name' },
    { header: t('InspectionHistoryFeature.manufacturer'), key: 'manufacturer_name' },
    { header: t('InspectionHistoryFeature.model'), key: 'model_name' },
    { header: t('InspectionHistoryFeature.extreme_rust'), key: 'extreme_rust_state' },
    { header: t('InspectionHistoryFeature.installation_state'), key: 'installation_state' },
    { header: t('InspectionHistoryFeature.liquid_sauce'), key: 'dripping_state' },
    { header: t('InspectionHistoryFeature.unusual_sound'), key: 'unusual_sound_state' },
    { header: t('InspectionHistoryFeature.abnormal_vibration'), key: 'abnormal_vibration_state' },
    { header: t('InspectionHistoryFeature.operating_state'), key: 'operating_state' },
    { header: t('InspectionHistoryFeature.remarks'), key: 'comment' },
  ];

  return (
    <>
      <ExportLink
        target="_blank"
        filename={'inspection_history.csv'}
        data={data}
        headers={headers}
        className="btn btn-primary csv-export"
      >
        <FaDownload />
        &nbsp;
        <span className="d-inline-block align-middle">{t('InspectionHistoryFeature.csv_output')}</span>
      </ExportLink>
    </>
  );
}
