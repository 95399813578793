import { useAppDispatch, useAppSelector } from 'app/hooks';
import StatusHeader from 'components/common/StatusHeader';
import { useEffect } from 'react';
import AssetFormAdd from './components/AssetFormAdd';
import { assetAddActions, selectDataLoadFormAdd } from './assetAddSlice';
import { useHistory } from 'react-router-dom';
import './scss/AssetAdd.scss';

function AssetAddPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const dataLoadFormAdd = useAppSelector(selectDataLoadFormAdd);

  useEffect(() => {
    dispatch(assetAddActions.fetchPreLoadFromAdd());
  }, [dispatch]);

  const handlePageBack = () => {
    history.push('/');
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-header">
          <StatusHeader actionName="back" onAction={handlePageBack} />
        </div>
        <div className="page-main">
          <div className="page-content">
            <AssetFormAdd dataLoad={dataLoadFormAdd} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AssetAddPage;
