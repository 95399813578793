import { branchSidebarTranslationJP } from 'components/BranchSidebar/locales';
import { alertModalTranslationJP } from 'components/common/AlertModal/translation/locales';
import { confirmModalTranslationJP } from 'components/common/ConfirmModal/translation/locales';
import { departmentSearchModalTranslationJP } from 'components/common/DepartmentSearchModal/translation/locales';
import { employeeSearchModalTranslationJP } from 'components/common/EmployeeSearchModal/translation/locales';
import { OwnershipChangeModalTranslationJP } from 'components/common/OwnershipChangeModal/translation/locales';
import { statusHeaderTranslationJP } from 'components/common/StatusHeader/translation/locales';
import { assetModelFeatureTranslationJP } from 'features/AssetModel/translation/locales';
import { AssetScreenTranslationJP } from 'features/AssetScreen/translation/locales';
import { authFeatureTranslationJP } from 'features/Auth/translation/locales';
import { movementHistoryFeatureTranslationJP } from 'features/MovementHistory/translation/locales';
import { inventoryTranslationJP } from 'features/Inventory/translation/locales';
import { mainMenuFeatureTranslationJP } from 'features/MainMenu/translation/locales';
import { inspectionHistoryFeatureTranslationJP } from 'features/InspectionHistory/translation/locales';
import { adminMasterFeatureTranslationJP } from 'features/AdminMaster/translation/locales';
import { inventoryHistoryFeatureTranslationJP } from 'features/InventoryHistoryConfirmation/translation/locales';
import { inspectionTranslationJP } from 'features/Inspection/translation/locales';

const commonTranslationJP = {
  App: {
    app_name: '資産管理システム',
    error: 'エラー',
    info: '情報',
    warning: '警告',
    confirm: '確認',
    loading: '読み込み中...',
  },
  ...statusHeaderTranslationJP,
  ...confirmModalTranslationJP,
  ...alertModalTranslationJP,
  ...departmentSearchModalTranslationJP,
  ...employeeSearchModalTranslationJP,
  ...branchSidebarTranslationJP,
};

export const translationJP = {
  ...commonTranslationJP,
  ...AssetScreenTranslationJP,
  ...authFeatureTranslationJP,
  ...movementHistoryFeatureTranslationJP,
  ...inventoryTranslationJP,
  ...assetModelFeatureTranslationJP,
  ...mainMenuFeatureTranslationJP,
  ...inspectionHistoryFeatureTranslationJP,
  ...OwnershipChangeModalTranslationJP,
  ...adminMasterFeatureTranslationJP,
  ...inventoryHistoryFeatureTranslationJP,
  ...inspectionTranslationJP,
};
