import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CommonResponse } from 'models';
import { Company } from './models/company';

export interface CompanyState {
  loading: boolean;
  list: Company[];
}

const initialState: CompanyState = {
  loading: false,
  list: [],
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    fetchCompanyList(state) {
      state.loading = true;
    },
    fetchCompanySuccess(state, action: PayloadAction<CommonResponse<Company[]>>) {
      state.list = action.payload.data;
      state.loading = false;
    },
    fetchCompanyFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const companyActions = companySlice.actions;

// Selectors
export const selectCompanyLoading = (state: RootState) => state.company.loading;
export const selectCompanyList = (state: RootState) => state.company.list;

// Reducer
export const companyReducer = companySlice.reducer;
