import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { PreDataLoad } from 'features/AssetScreen/models/PreDataLoad';
import { AssetResponse } from '../../models/Asset';
export interface AssetState {
  loading: boolean;
  list: AssetResponse;
  PreDataLoad: PreDataLoad;
}

const initialState: AssetState = {
  loading: false,
  list: {
    status: '',
    message: '',
    data: [],
  },
  PreDataLoad: {
    status: '',
    message: '',
    data: {
      asset_status: [],
      asset_uses: [],
      companies: [],
      storage_places: [],
      asset_classifications: [],
      accounting_class: [],
    },
  },
};

const assetListSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    fetchPreLoad(state) {
      state.PreDataLoad = initialState.PreDataLoad;
    },
    fetchPreLoadSuccess(state, action: PayloadAction<PreDataLoad>) {
      state.PreDataLoad = action.payload;
    },
    fetchPreLoadFailed(state) {
      state.PreDataLoad = initialState.PreDataLoad;
    },

    fetchAssetList(state, action) {
      state.loading = true;
    },
    fetchAssetSuccess(state, action: PayloadAction<AssetResponse>) {
      state.list = action.payload;
      state.loading = false;
    },
    fetchAssetFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const assetActions = assetListSlice.actions;

// Selectors
export const selectPreDataLoad = (state: RootState) => state.asset.PreDataLoad;
export const selectAssetLoading = (state: RootState) => state.asset.loading;
export const selectAssetList = (state: RootState) => state.asset.list;
export const selectAssetAdd = (state: RootState) => state.assetAdd.add;
export const selectAssetEdit = (state: RootState) => state.assetEdit.edit;

// Reducer
export const assetListReducer = assetListSlice.reducer;
