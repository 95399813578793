import { PayloadAction } from '@reduxjs/toolkit';
import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { CompanyNode, DepartmentNode, OrganizationTreeFilter } from 'components/BranchSidebar/models';
import organizationTreeService from 'components/BranchSidebar/organizationTreeService';
import { CommonResponse } from 'models';
import { call, put, takeEvery } from 'redux-saga/effects';
import { inspectionListActions } from './inspectionSlice';

function* getOrganizationData(action: PayloadAction<OrganizationTreeFilter>) {
  try {
    const response: CommonResponse<CompanyNode[] | DepartmentNode[]> = yield call(
      organizationTreeService.getAll,
      action.payload
    );
    yield put(inspectionListActions.fetchOrganizationDataSuccess(response));
  } catch (e) {
    yield put(inspectionListActions.fetchOrganizationDataFailed());
    yield handleResponseError(e as AxiosError);
  }
}

function* reloadOrganizationData(action: PayloadAction<OrganizationTreeFilter>) {
  try {
    const response: CommonResponse<CompanyNode[] | DepartmentNode[]> = yield call(
      organizationTreeService.getAll,
      action.payload
    );
    yield put(inspectionListActions.reloadOrganizationDataSuccess(response));
  } catch (e) {
    yield put(inspectionListActions.reloadOrganizationDataFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* inspectionSaga() {
  yield takeEvery(inspectionListActions.fetchOrganizationData.type, getOrganizationData);
  yield takeEvery(inspectionListActions.reloadOrganizationData.type, reloadOrganizationData);
}
