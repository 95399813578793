export const AssetScreenTranslationJP = {
  AssetScreen: {
    index: {
      table: {
        current_status: '資産ステータス',
        current_possession_company_name: '会社名',
        current_possession_department_cd: '所属コード',
        restaurant_cd: '店舗コード',
        current_possession_department_name: '所属名',
        current_possession_employee_name: '利用者',
        current_storage_place_name: '保管場所',
        management_no: '管理番号',
        accounting_no: '会計番号',
        classification_name: '分類',
        manufacturer_name: 'メーカー',
        model_name: '機種名',
        use_name: '用途',
        purchase_date: '購入日',
        purchase_price: '購入価格',
        updated_date: '資産ステータス変更日',
        updated_employee: '資産ステータス変更者',
        is_delete: 'ステータス',
      },
      form_search: {
        title: '検索条件',
        button: '検索',
      },
      form_search_accordion_info: {
        title: '基本情報',
        management_no: '資産管理番号',
        restaurant_name: '店舗名称',
        restaurant_cd: '店舗コード',
        current_storage_place_id: '保管場所選択',
        current_possession_company_id: '所属選択',
        customer_selection: '利用者選択',
        classification_id: '資産分類選択',
        current_use_id: '資産用途選択',
        current_possession_department_cd: '所属コード',
        current_possession_department_name: '所属名',
        affiliation_search: '所属検索',
        current_possession_employee_cd: '社員コード',
        current_possession_employee_name: '社員名',
        employee_search: '社員検索',
      },
      form_search_asset_status_information: {
        title: '資産ステータス情報',
        current_status_id: '資産ステータス',
        specified_date_search: '指定日検索',
        range_search: '更新履歴検索',
        obsolete_department_asset_flg: '廃止組織資産 (管理部門 不明資産)',
      },
      form_search_asset_accounting_information: {
        title: '会計情報',
        accounting_no: '会計番号',
        accounting_class_id: '会計区分',
      },
    },
    add: {
      status: {
        success: '成功を追加',
      },
      form: {
        management_no: '管理番号',
        type: '資産種別',
        purchased_date: '購入日',
        asset_classification_id: '分類',
        manufacturer_id: 'メーカー',
        model: '機種・型式名',
        size: '寸法',
        model_number: '型番',
        manufacturer_serial_number: 'メーカー製造番号',
        mac_address: 'MACアドレス',
        external_order_number: '社外注文番号',
        gas_specie: 'ガス種',
        compressed_output: '圧縮出力',
        power_supply: '電源',
        accounting_class_id: '会計区分',
        purchased_price: '購入価格(￥)',
        accounting_no: '会計番号',
        purchased_method: '購入方法',
        leasing_divided_contract_company: 'リース／分割契約会社',
        contract_expiry_date: '契約満了年月',
        current_use: '用途',
        current_status: '資産ステータス',
        current_installation_location: '設置場所',
        current_use_location: '使用場所',
        current_possession_company: '管理会社',
        current_possession_department: '管理所属',
        current_storage_place: '保管場所',
        current_possession_employee: '利用者',
        button_ok: '更新',
        button_submit_del: '削除',
        apply_date: '適用日',
        required_field: '必須フィールド',
        number_field: '数値である必要があります',
      },
      table: {
        applied_start_date: '適用日',
        updated_employee_id: '変更者',
        status_id: 'ステータス',
        remarks: '備考',
      },
    },
    edit: {
      status: {
        success: '更新成功',
      },
      csv: {
        title_success: '成功CSV',
        success: '成功CSV',
      }
    },
    history: {
      title: '変更履歴',
      applied_start_date: '適用日',
      updated_employee: '変更者',
      status: 'ステータス',
      note: '備考',
      updated_date: '更新日',
    },
  },
};
