import { AxiosError } from 'axios';
import { AlertModalProps } from 'components/common/AlertModal';
import { GlobalModalComponentType } from 'components/common/GlobalModal/createGlobalModalContext';
import {
  ERROR_NOTIFICATION_CODES,
  INSPECTION_STATE_NORMAL,
  MODAL_TYPES,
  STATE_ABNORMAL_TEXT,
  STATE_NORMAL_TEXT,
} from './constants';

export function handleServiceError(
  showGlobalModal: <T extends GlobalModalComponentType>(modeType: string, modalProps: T) => void,
  error: AxiosError
) {
  if (ERROR_NOTIFICATION_CODES.includes(Number(error.response?.status))) {
    showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
      title: 'warning',
      message: error.message,
    });
    return true;
  }

  return false;
}

export function getInspectionStateDisplay(state: number) {
  if (state === INSPECTION_STATE_NORMAL) {
    return STATE_NORMAL_TEXT;
  }

  return STATE_ABNORMAL_TEXT;
}
