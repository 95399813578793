//import { push } from 'connected-react-router';
import { call, put, takeEvery } from 'redux-saga/effects';
import assetService from './assetService';
import { assetActions } from './assetListSlice';
import { AssetResponse } from '../../models/Asset';
import { PreDataLoad } from 'features/AssetScreen/models/PreDataLoad';

function* getPreDataSearch() {
  try {
    const response: PreDataLoad = yield call(assetService.getPreDataSearch);
    yield put(assetActions.fetchPreLoadSuccess(response));
  } catch (e) {
    yield put(assetActions.fetchPreLoadFailed());
  }
}

function* getList(action: any) {
  try {
    const params = action.payload;
    const response: AssetResponse = yield call(assetService.getAll, params);
    yield put(assetActions.fetchAssetSuccess(response));
  } catch (e) {
    yield put(assetActions.fetchAssetFailed());
  }
}

export default function* assetSaga() {
  yield takeEvery(assetActions.fetchPreLoad.type, getPreDataSearch);
  yield takeEvery(assetActions.fetchAssetList.type, getList);
}
