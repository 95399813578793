import { put, call, takeEvery } from 'redux-saga/effects';
import { assetEditActions } from './assetEditSlice';
import assetEditService from './assetService';
import { DataLoadForm } from 'features/AssetScreen/models/DataLoadForm';
import { ManufacturerPayload } from 'features/AssetScreen/models/Manufacturer';
import { AssetModelPayload } from 'features/AssetScreen/models/AssetModel';
import { Asset } from 'features/AssetScreen/models/Asset';
import { CommonResponse } from 'models';

function* getPreDataFormEdit() {
  try {
    const response: DataLoadForm = yield call(assetEditService.getPreDataFormEdit);
    yield put(assetEditActions.fetchPreLoadFromEditSuccess(response));
  } catch (e) {
    yield put(assetEditActions.fetchPreLoadFromEditFailed());
  }
}

function* fetchManufactures(action: any) {
  try {
    const params = action.payload;
    const response: ManufacturerPayload = yield call(assetEditService.fetchManufactures, params);
    yield put(assetEditActions.fetchDataManufacturerSuccess(response));
  } catch (e) {
    yield put(assetEditActions.fetchPreLoadFromEditFailed());
  }
}

function* fetchDataModels(action: any) {
  try {
    const params = action.payload;
    const response: AssetModelPayload = yield call(assetEditService.fetchModels, params);
    yield put(assetEditActions.fetchDataModelSuccess(response));
  } catch (e) {
    yield put(assetEditActions.fetchDataModelFailed());
  }
}

function* saveAsset(action: any) {
  try {
    const body = action.payload;
    const response: AssetModelPayload = yield call(assetEditService.saveAsset, body);
    yield put(assetEditActions.saveAssetSuccess(response));
  } catch (e) {
    yield put(assetEditActions.saveAssetFailed());
  }
}

function* getAsset(action: any) {
  try {
    const id = action.payload;
    const response: CommonResponse<Asset> = yield call(assetEditService.getAssetById, id);
    yield put(assetEditActions.getAssetSuccess(response));
  } catch (e: any) {
    const data = e.response.data;
    yield put(assetEditActions.getAssetFailed(data));
  }
}

export default function* assetEditSaga() {
  yield takeEvery(assetEditActions.fetchPreLoadFromEdit.type, getPreDataFormEdit);
  yield takeEvery(assetEditActions.fetchDataManufacturer.type, fetchManufactures);
  yield takeEvery(assetEditActions.fetchDataModel.type, fetchDataModels);
  yield takeEvery(assetEditActions.saveAsset.type, saveAsset);
  yield takeEvery(assetEditActions.getAsset.type, getAsset);
}
