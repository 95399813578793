import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AxiosError } from 'axios';
import { AlertModalProps } from 'components/common/AlertModal';
import { GlobalModalContext } from 'components/common/GlobalModal';
import StatusHeader from 'components/common/StatusHeader';
import { selectActiveAssetClassificationList } from 'features/AdminMaster/assetClassificationSlice';
import { CommonResponse } from 'models';
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { MODAL_TYPES } from 'utils';
import { handleServiceError } from 'utils/common';
import assetModelrService from '../assetModelService';
import { assetModelActions, selectAssetModelList } from '../assetModelSlice';
import { AssetModelFormModal, ManufacturerMasterModal } from '../components';
import { ASSET_MODEL_STOPPED_FLG_SOURCE } from '../constants';
import { selectActiveManufacturerList } from '../manufacturerSlice';
import { AssetModel, AssetModelFilterPayload } from '../models/assetModel';
import './AssetModelPage.scss';

const DEFAULT_ROW_HEIGHT = 42;

type FormValues = {
  assetModel: AssetModel[];
};

function AssetModelPage() {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();

  // state
  const [showModelFormModal, setShowModelFormModal] = useState(false);
  const [showManufacturerMasterModal, setShowManufacturerMasterModal] = useState(false);
  const assetModelList = useAppSelector(selectAssetModelList);
  // const assetModelEditedList = JSON.parse(JSON.stringify(assetModelList)) as AssetModel[];
  // let assetModelEditedList = new Map();
  const manufacturerList = useAppSelector(selectActiveManufacturerList);
  const assetClassificationList = useAppSelector(selectActiveAssetClassificationList);

  const [filter, setFilter] = useState<AssetModelFilterPayload>({
    sort: 'manufacturer_name',
    order: 'asc',
  });

  // ref
  const searchClassificationNameRef = useRef<HTMLInputElement>(null);
  const searchManufacturerNameRef = useRef<HTMLInputElement>(null);
  const searchNameRef = useRef<HTMLInputElement>(null);

  // source
  const stoppedFlgSource = ASSET_MODEL_STOPPED_FLG_SOURCE[i18n.language];

  //form list state
  const { register, control, handleSubmit, setValue, getValues } = useForm<FormValues>({
    defaultValues: {
      assetModel: [],
    },
    shouldUnregister: false,
  });
  const { fields } = useFieldArray({
    name: 'assetModel',
    control,
    keyName: 'assetModel.id',
  });
  const listRef = useRef<any>();
  const sizeMap = useRef<{ [index: number]: number }>({});
  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current?.resetAfterIndex(index);
  }, []);
  const getSize = (index: number) => sizeMap.current[index] || DEFAULT_ROW_HEIGHT;

  // useEffect
  useEffect(() => {
    dispatch(assetModelActions.fetchAssetModelList(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    setValue('assetModel', assetModelList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetModelList]);

  function handlePageBack() {
    history.push('/');
  }

  function handleSearchSubmit(e: FormEvent) {
    e.preventDefault();
    const searchClassificationName = searchClassificationNameRef.current
      ? searchClassificationNameRef.current.value.trim()
      : undefined;
    const searchManufacturerName = searchManufacturerNameRef.current
      ? searchManufacturerNameRef.current.value.trim()
      : undefined;
    const searchName = searchNameRef.current ? searchNameRef.current.value.trim() : undefined;

    const payload: AssetModelFilterPayload = {
      ...filter,
      classification_name: searchClassificationName,
      manufacturer_name: searchManufacturerName,
      name: searchName,
    };
    setFilter(payload);
  }

  function handleShowModelForm() {
    setShowModelFormModal(true);
  }

  function handleCloseModelForm() {
    setShowModelFormModal(false);
  }

  function handleShowManufacturerMasterModal() {
    setShowManufacturerMasterModal(true);
  }

  function handleCloseManufacturerMasterModal() {
    setShowManufacturerMasterModal(false);
  }

  function handleUpdate(data: FormValues) {
    // reset error text
    const errorHelperElems = document.querySelectorAll('.error-helper');
    errorHelperElems.forEach(function (elem) {
      elem.innerHTML = '';
    });

    const modifiedData = data.assetModel.reduce((assetModels: AssetModel[], assetModel) => {
      return assetModels.concat(
        !assetModelList.some(
          (oldItem) =>
            oldItem.id === assetModel.id &&
            oldItem.name === assetModel.name &&
            oldItem.asset_classification_id === assetModel.asset_classification_id &&
            oldItem.manufacturer_id === assetModel.manufacturer_id &&
            oldItem.size === assetModel.size &&
            oldItem.stopped_flg === assetModel.stopped_flg
        )
          ? assetModel
          : []
      );
    }, []);

    // const modifiedData = _.differenceWith(data.assetModel, assetModelList, _.isEqual);
    if (modifiedData.length === 0) {
      showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
        title: t('App.info'),
        message: t('AssetModelFeature.no_data_changed'),
      });
    } else {
      (async () => {
        try {
          await assetModelrService.update(modifiedData);
          dispatch(assetModelActions.fetchAssetModelList(filter));

          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('AssetModelFeature.save_data_successful'),
            title: t('App.info'),
          });
        } catch (e) {
          if (!handleServiceError(showGlobalModal, e as AxiosError)) {
            const errorResponse = (e as AxiosError).response?.data as CommonResponse<any>;
            const errorData = errorResponse.data.error_data;
            const rowError = document.querySelector('#row-' + errorData.id);
            const rowIndex = Number(rowError?.getAttribute('data-index'));
            if (rowError) {
              rowError.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
              });
              let errorHeight = 0;
              for (const errorKey in errorResponse.data.errors) {
                const errorControlTextElem = rowError.querySelector('.error-' + errorKey);
                if (errorControlTextElem) {
                  errorControlTextElem.innerHTML = errorResponse.data.errors[errorKey];
                  errorHeight += errorControlTextElem.getBoundingClientRect().height;
                }
              }
              setSize(rowIndex, DEFAULT_ROW_HEIGHT + errorHeight);
            }
          }
        }
      })();
    }
  }

  function updateSort(sortName: string) {
    const newFilter = { ...filter };
    if (newFilter.sort === sortName) {
      newFilter.order = newFilter.order === 'asc' ? 'desc' : 'asc';
    } else {
      newFilter.sort = sortName;
      newFilter.order = 'asc';
    }
    setFilter(newFilter);
  }

  function handleChooseFile(assetModelId: number) {
    const row = document.querySelector('#row-' + assetModelId);
    if (row) {
      const inputFile = row.querySelector('.file-img-url') as HTMLInputElement;
      inputFile?.click();
    }
  }

  function displayError(error: AxiosError) {
    if (error) {
      const errorData = error.response?.data as CommonResponse<any>;
      if (!errorData.data) {
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: errorData.message,
          title: t('App.error'),
        });
      } else {
        const errorResponse = (error as AxiosError).response?.data as CommonResponse<any>;
        const errorData = errorResponse.data.error_data;
        const rowError = document.querySelector('#row-' + errorData.id);
        if (rowError) {
          rowError.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center',
          });
          let errorMessage = '';
          for (const property in errorResponse.data.errors) {
            errorMessage += errorResponse.data.errors[property].join('<br />');
          }

          if (errorMessage.length > 0) {
            showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
              message: errorMessage,
              title: t('App.error'),
            });
          }
        }
      }
    }
  }

  function handleFileChange(assetModelId: number) {
    const row = document.querySelector('#row-' + assetModelId);
    if (row) {
      const inputFile = row.querySelector('.file-img-url') as HTMLInputElement;
      const imageLink = row.querySelector('.image-link') as HTMLLinkElement;

      const bodyFormData = new FormData();
      if (inputFile && inputFile.files) {
        const file = inputFile.files[0];
        if (file) {
          bodyFormData.append('img_url', file, file.name);
        }
      }

      (async () => {
        try {
          const response = await assetModelrService.uploadImageAsset(assetModelId, bodyFormData);
          // refetch asset model list
          dispatch(assetModelActions.fetchAssetModelList(filter));
          imageLink.innerHTML = response.data.img_url.split(/[\\]+/).pop() || '';
          imageLink.href = response.data.img_url;

          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('AssetModelFeature.save_data_successful'),
            title: t('App.info'),
          });
        } catch (e) {
          inputFile.value = '';
          // imageLink.innerHTML = '';
          // imageLink.href = '#';
          if (!handleServiceError(showGlobalModal, e as AxiosError)) {
            displayError(e as AxiosError);
          }
        }
      })();
    }
  }

  return (
    <div className="asset-model-page page-wrapper">
      <div className="page-header">
        <StatusHeader onAction={handlePageBack} />
      </div>
      <div className="page-main">
        <div className="page-content d-flex flex-column">
          <div className="mb-3">
            <Button className="btn btn-primary me-3" onClick={handleShowModelForm}>
              {t('AssetModelFeature.add_new')}
            </Button>
            <Button className="btn btn-primary" onClick={handleShowManufacturerMasterModal}>
              {t('AssetModelFeature.manufacturer_management')}
            </Button>
          </div>
          <div className="mt-3 mb-3">
            <Form onSubmit={handleSearchSubmit}>
              <div className="bg-main rounded p-3 row g-3 align-items-center">
                <div className="col-auto row">
                  <Form.Label column sm="auto" className="col-form-label">
                    {t('AssetModelFeature.category_name')}:
                  </Form.Label>
                  <Col sm="auto">
                    <Form.Control
                      ref={searchClassificationNameRef}
                      className="d-inline-block"
                      style={{ maxWidth: '200px' }}
                    ></Form.Control>
                  </Col>
                </div>
                <div className="col-auto row">
                  <Form.Label column sm="auto" className="col-form-label">
                    {t('AssetModelFeature.manufacturer_name')}:
                  </Form.Label>
                  <Col sm="auto">
                    <Form.Control
                      ref={searchManufacturerNameRef}
                      className="d-inline-block"
                      style={{ maxWidth: '200px' }}
                    ></Form.Control>
                  </Col>
                </div>
                <div className="col-auto row">
                  <Form.Label column sm="auto" className="col-form-label">
                    {t('AssetModelFeature.model_name')}:
                  </Form.Label>
                  <Col sm="auto">
                    <Form.Control
                      ref={searchNameRef}
                      className="d-inline-block"
                      style={{ maxWidth: '200px' }}
                    ></Form.Control>
                  </Col>
                </div>
                <div className="col-auto row">
                  <Button type="submit" variant="primary">
                    {t('AssetModelFeature.search')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>

          <form onSubmit={handleSubmit(handleUpdate)} className="d-flex flex-column flex-grow-1 flex-shrink-1">
            <div className="mb-3 text-end">
              <Button variant="primary" type="submit">
                {t('AssetModelFeature.update')}
              </Button>
            </div>

            <div className="div-table">
              <div className="div-thead">
                <div className="div-tr">
                  <div className="div-th" style={{ width: '15%' }}>
                    {t('AssetModelFeature.classification')}
                  </div>
                  <div
                    className="div-th border-start-0 sort"
                    style={{ width: '15%' }}
                    onClick={updateSort.bind(null, 'manufacturer_name')}
                  >
                    <div className="d-flex align-items-center">
                      <span className="flex-fill">{t('AssetModelFeature.manufacturer')}</span>
                      {filter.sort === 'manufacturer_name' && filter.order === 'asc' && <FaSortUp />}
                      {filter.sort === 'manufacturer_name' && filter.order === 'desc' && <FaSortDown />}
                      {filter.sort !== 'manufacturer_name' && <FaSort />}
                    </div>
                  </div>
                  <div
                    className="div-th border-start-0 sort"
                    style={{ width: '20%' }}
                    onClick={updateSort.bind(null, 'name')}
                  >
                    <div className="d-flex align-items-center">
                      <span className="flex-fill">{t('AssetModelFeature.model_name')}</span>
                      {filter.sort === 'name' && filter.order === 'asc' && <FaSortUp />}
                      {filter.sort === 'name' && filter.order === 'desc' && <FaSortDown />}
                      {filter.sort !== 'name' && <FaSort />}
                    </div>
                  </div>
                  <div
                    className="div-th border-start-0 sort"
                    style={{ width: '20%' }}
                    onClick={updateSort.bind(null, 'size')}
                  >
                    <div className="d-flex align-items-center">
                      <span className="flex-fill">{t('AssetModelFeature.size')}</span>
                      {filter.sort === 'size' && filter.order === 'asc' && <FaSortUp />}
                      {filter.sort === 'size' && filter.order === 'desc' && <FaSortDown />}
                      {filter.sort !== 'size' && <FaSort />}
                    </div>
                  </div>
                  <div className="div-th border-start-0" style={{ width: '22%' }}>
                    {t('AssetModelFeature.image')}
                  </div>
                  <div className="div-th border-start-0" style={{ width: '8%' }}>
                    {t('AssetModelFeature.enable_stop')}
                  </div>
                </div>
              </div>
              <div className="div-tbody"></div>
            </div>
            <div className="div-table-wrapper flex-grow-1 flex-shrink-1">
              <AutoSizer>
                {({ height, width }) => (
                  <VariableSizeList
                    ref={listRef}
                    className="div-table1 fixed-size-list"
                    width={width}
                    height={height}
                    itemSize={getSize}
                    itemCount={fields.length}
                    itemData={fields}
                    itemKey={(i) => fields[i].id}
                  >
                    {({ style, index }) => {
                      const field = getValues('assetModel')?.[index];

                      return (
                        <div
                          key={field.id}
                          id={`row-${field.id}`}
                          data-index={index}
                          className={`div-tr d-flex ${field.stopped_flg ? 'table-secondary' : ''}`}
                          style={style}
                        >
                          <div className="div-td" style={{ width: '15%' }}>
                            <Form.Select
                              defaultValue={field.asset_classification_id}
                              {...register(`assetModel.${index}.asset_classification_id` as const)}
                            >
                              {assetClassificationList.map(function (classification) {
                                return (
                                  <option key={classification.id} value={classification.id}>
                                    {classification.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <span className="error-helper error-asset_classification_id small text-danger"></span>
                          </div>
                          <div className="div-td" style={{ width: '15%' }}>
                            <Form.Select
                              defaultValue={field.manufacturer_id}
                              {...register(`assetModel.${index}.manufacturer_id` as const)}
                            >
                              {manufacturerList.map(function (manufacturer) {
                                return (
                                  <option key={manufacturer.id} value={manufacturer.id}>
                                    {manufacturer.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <span className="error-helper error-manufacturer_id small text-danger"></span>
                          </div>
                          <div className="div-td" style={{ width: '20%' }}>
                            <Controller
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Control
                                  onBlur={onBlur}
                                  defaultValue={value}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const errorHelperElems = e.target.parentElement?.querySelectorAll('.error-helper');
                                    errorHelperElems?.forEach(function (errorHelperElem) {
                                      return (errorHelperElem.innerHTML = '');
                                    });
                                    setSize(index, DEFAULT_ROW_HEIGHT);
                                    onChange(e.target.value);
                                  }}
                                />
                              )}
                              name={`assetModel.${index}.name`}
                              control={control}
                            />
                            <div className="error-helper error-name small text-danger"></div>
                            <span className="error-helper error-non_field_errors small text-danger"></span>
                          </div>
                          <div className="div-td" style={{ width: '20%' }}>
                            <Form.Control
                              defaultValue={field.size}
                              {...register(`assetModel.${index}.size` as const)}
                            ></Form.Control>
                            <span className="error-helper error-size small text-danger"></span>
                          </div>
                          <div className="div-td" style={{ width: '22%', whiteSpace: 'pre-wrap' }}>
                            <Button className="btn--yellow me-2" onClick={handleChooseFile.bind(null, field.id)}>
                              {t('AssetModelFeature.change_image')}
                            </Button>
                            <input
                              type="file"
                              className="file-img-url"
                              style={{ display: 'none' }}
                              onChange={handleFileChange.bind(null, field.id)}
                            />
                            <a
                              href={process.env.REACT_APP_IMAGE_URL + field.img_url}
                              className="image-link"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {field.img_url}
                            </a>
                          </div>
                          <div className="div-td" style={{ width: '8%' }}>
                            <Form.Select
                              defaultValue={field.stopped_flg ? 1 : 0}
                              // {...register(`assetModel.${index}.stopped_flg` as const)}
                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                setValue(`assetModel.${index}.stopped_flg`, Number(e.target.value) === 1)
                              }
                            >
                              {stoppedFlgSource.map(function (flg) {
                                return (
                                  <option key={flg.code} value={flg.code}>
                                    {flg.label}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <div className="error-helper error-stopped_flg small text-danger"></div>
                          </div>
                        </div>
                      );
                    }}
                  </VariableSizeList>
                )}
              </AutoSizer>
            </div>
          </form>
        </div>

        {/* add new form modal */}
        {showModelFormModal && <AssetModelFormModal filter={filter} onClose={handleCloseModelForm} />}
        {showManufacturerMasterModal && <ManufacturerMasterModal onClose={handleCloseManufacturerMasterModal} />}
      </div>
    </div>
  );
}

export default AssetModelPage;
