import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CommonResponse } from 'models';
import { AssetClassification } from './models/assetClassification';

export interface AssetClassificationState {
  loading: boolean;
  list: AssetClassification[];
}

const initialState: AssetClassificationState = {
  loading: false,
  list: [],
};

const assetClassificationSlice = createSlice({
  name: 'asset_classification',
  initialState,
  reducers: {
    fetchAssetClassificationList(state) {
      state.loading = true;
    },
    fetchAssetClassificationSuccess(state, action: PayloadAction<CommonResponse<AssetClassification[]>>) {
      state.list = action.payload.data;
      state.loading = false;
    },
    fetchAssetClassificationFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const assetClassificationActions = assetClassificationSlice.actions;

// Selectors
export const selectAssetClassificationLoading = (state: RootState) => state.assetClassification.loading;
export const selectAssetClassificationList = (state: RootState) => state.assetClassification.list;
export const selectActiveAssetClassificationList = createSelector(
  selectAssetClassificationList,
  (assetClassificationList) => {
    return assetClassificationList.filter((classification) => !classification.stopped_flg);
  }
);

// Reducer
export const assetClassificationReducer = assetClassificationSlice.reducer;
