export const assetModelFeatureTranslationEN = {
  AssetModelFeature: {
    add_new: 'Add new',
    manufacturer_management: 'Manufacturer management',
    category_name: 'Category name',
    manufacturer_name: 'Manufacturer name',
    search: 'Search',
    update: 'Save changes',
    change_image: 'Change image',
    classification: 'Classification',
    manufacturer: 'Manufacturer',
    model_name: 'Model name',
    size: 'Size',
    image: 'Image',
    enable_stop: 'Enable/Stop',

    //warning
    confirm_delete: 'Are you sure to delete it?',
    save_data_successful: 'Save data successfully',
    no_data_changed: 'No data changed',

    // form
    form_add_title: 'Add New Model',
    sign_up: 'Register',
    close: 'Close',
    note_required_field: '※ is a required field.',
    warning_required_field: 'This field is required',
    upload_image: 'Upload image',
    restore: 'Restore',

    // manufacturer
    manufacturer_form_title: 'Manufacturer Master',
    change: 'Save',
    stop: 'Stop',
    select: 'Select',
  },
};
