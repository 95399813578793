export const inspectionTranslationJP = {
  Inspection: {
    btn_inspection_output: '棚卸表出力',
    btn_send_inspection: '点検結果送信',
    btn_approve: '承認',
    btn_send_back: '差戻',
    btn_store_report: '店舗報告済',
    btn_register: '登録',
    btn_detail_update: '明細更新',
    btn_final_confirmation: '最終確定',
    btn_confirm_cancellation: '確定取消',
    btn_csv_output: 'CSV出力',
    lbl_inspection_period: '法定設備点検の期間',
    lbl_inspection_entry: '検査エントリー',
    lbl_asset_management_number: '資産管理番号',
    lbl_situation: '状態',
    lbl_comment: 'コメント',
    lbl_fault: '故障中',
    lbl_belongs: '所属',
    lbl_state: '状況',
    lbl_inspection_state: '異常有無',
    lbl_inspection_result_detail: '検査結果詳細',
    lbl_inspection_status_list: '設備点検状況一覧',
    lbl_cancel: '取消',
    lbl_classification: '機器名',
    lbl_use: '用途',
    lbl_manufacturer: 'メーカー',
    lbl_model_name: '型式',
    lbl_status: 'ステータス',
    lbl_store_comment: '店舗コメント',
    lbl_system_message: 'システムメッセージ',
    lbl_register_person: '登録者',
    lbl_register_date: '登録日時',
    lbl_approval_history: '履歴',
    lbl_required_field: '※は入力必須項目です。\n全て登録が完了すれば、「送信検査」を押して下さい。',
    lbl_store_not_register: '店舗未登録',
    msg_update_successfully: '更新に成功',
    msg_required: '{0}を選択してください。',
    msg_select_department: '所属を選択してください。',
    msg_no_inspection_history: '法定設備点検はありません',
    msg_confirm_approve: '指定組織下の店舗を、一括で承認しますか？',
    msg_confirm_send_back: '指定組織下の店舗を、一括で差戻しますか？',
    msg_confirm_need_report: '指定組織下の店舗を、一括で報告書提出済に更新しますか？',
    msg_confirm_final_confirmation: '最終確定を行いますか？',
    msg_confirm_cancel_final_confirmation: '確定取消を行いますか？',
    inspection_error_001: '資産がありません',
    inspection_error_002: '入力された資産は、他店の資産として登録されています。<br> 本当に登録しますか？',

    //column of InspectionResultTable
    lbl_installation_location: '設置場所',
    lbl_use_location: '使用場所',
    lbl_extreme_rust_state: '極度な錆',
    lbl_installation_state: '設置状態',
    lbl_dripping_state: '液垂れ',
    lbl_unusual_sound_state: '異常な音',
    lbl_abnormal_vibration_state: '異常な振動',
    lbl_operating_state: '動作状態',
    lbl_remark: '備考',
  },
};
