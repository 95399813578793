import { useAppSelector } from 'app/hooks';
import { AxiosError } from 'axios';
import { GlobalModalContext } from 'components/common/GlobalModal';
import StatusHeader from 'components/common/StatusHeader';
import { selectJobInfo, selectPermissionByFeature } from 'features/Auth/authSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PERMISSIONS } from 'utils';
import { handleServiceError } from 'utils/common';
import { DEADLINE_PATTERN_DISPLAY_DUE_DATE } from '../constants';
import { Todo } from '../models/todo';
import todoService from '../todoService';
import './MainMenuPage.scss';

function MainMenuPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();

  // state
  const selectedJob = useAppSelector(selectJobInfo);
  const permissions = useAppSelector(selectPermissionByFeature('main_menu'));
  const [todoList, setTodoList] = useState<Todo[]>([]);

  useEffect(() => {
    (async () => {
      try {
        if (selectedJob) {
          const response = await todoService.getAll({
            company_id: selectedJob.company_id,
            department_id: selectedJob.department_id,
          });
          setTodoList(response.data);
        }
      } catch (e) {
        handleServiceError(showGlobalModal, e as AxiosError);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInventoryMenuClick() {
    history.push('/inventory');
  }

  function handleLegalEquipmentInspectionMenuClick() {
    history.push('/inspection');
  }

  function handleAssetSearchMenuClick() {
    history.push('/asset');
  }

  function handleAssetAddMenuClick() {
    history.push('/asset/add');
  }

  function handleCheckMovementHistoryMenuClick() {
    history.push('/movement-history');
  }

  function handleModalMasterMenuClick() {
    history.push('/asset-model');
  }

  function handleInspectionHistoryMenuClick() {
    history.push('/inspection-history');
  }

  function handleInventoryHistoryMenuClick() {
    history.push('/inventory-history');
  }

  function handleAdminMasterMenuClick() {
    history.push('/admin-master');
  }

  return (
    <div className="mainmenu-page page-wrapper">
      <div className="page-header">
        <StatusHeader actionName="logout"></StatusHeader>
      </div>
      <div className="page-main">
        <div className="page-content d-flex flex-column">
          <h5 className="text-primary">{t('MainMenuFeature.todo_list')}</h5>
          <div className="sticky-table flex-grow-1 flex-shrink-1 mb-4">
            <Table hover>
              <thead>
                <tr>
                  <th style={{ width: '15%' }}>{t('MainMenuFeature.added_date')}</th>
                  <th style={{ width: '15%' }}>{t('MainMenuFeature.item')}</th>
                  <th style={{ width: '15%' }}>{t('MainMenuFeature.deadline')}</th>
                  <th style={{ width: '55%' }}>{t('MainMenuFeature.work_content')}</th>
                </tr>
              </thead>
              <tbody>
                {todoList.map(function (todo, index) {
                  return (
                    <tr key={index}>
                      <td className="text-center">
                        {todo.created_date === null ? '' : moment(todo.created_date).format('YYYY/MM/DD')}
                      </td>
                      <td className="text-center">{todo.name}</td>
                      <td className="text-center">
                        {todo.deadline_pattern !== DEADLINE_PATTERN_DISPLAY_DUE_DATE ||
                        todo.todo_due_date === null ||
                        todo.todo_due_date.length === 0
                          ? t('MainMenuFeature.deadline_unknown')
                          : moment(todo.todo_due_date).format('YYYY/MM/DD')}
                      </td>
                      <td>{todo.description}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className="mainmenu-box flex-grow-0 flex-shrink-0">
            {permissions &&
              (permissions.includes(PERMISSIONS.INVENTORY) || permissions.includes(PERMISSIONS.INSPECTION)) && (
                <Row className="flex-grow-0 flex-shrink-0 mb-4">
                  {permissions && permissions.includes(PERMISSIONS.INVENTORY) && (
                    <Col sm={{ span: 3, offset: 3 }} className="text-center">
                      <Button variant="primary" className="mainmenu-item" onClick={handleInventoryMenuClick}>
                        {t('MainMenuFeature.inventory')}
                      </Button>
                    </Col>
                  )}
                  {permissions && permissions.includes(PERMISSIONS.INSPECTION) && (
                    <Col sm="3" className="text-center">
                      <Button
                        variant="primary"
                        className="mainmenu-item"
                        onClick={handleLegalEquipmentInspectionMenuClick}
                      >
                        {t('MainMenuFeature.legal_equipment_inspection')}
                      </Button>
                    </Col>
                  )}
                </Row>
              )}

            {permissions &&
              (permissions.includes(PERMISSIONS.SEARCH_ASSET) ||
                permissions.includes(PERMISSIONS.INVENTORY_HISTORY) ||
                permissions.includes(PERMISSIONS.MOVEMENT_HISTORY)) && (
                <div className="mainmenu-section bg-green p-3 mb-4">
                  <h5 className="text-primary mb-3">
                    <strong className="d-inline-block me-4">{t('MainMenuFeature.asset_search')}</strong>
                    <span className="fs-6 text-gray">{t('MainMenuFeature.asset_search_note')}</span>
                  </h5>
                  <Row>
                    {permissions && permissions.includes(PERMISSIONS.SEARCH_ASSET) && (
                      <Col sm="4" className="text-center">
                        <Button className="btn--white mainmenu-item" onClick={handleAssetSearchMenuClick}>
                          {t('MainMenuFeature.asset_search')}
                        </Button>
                      </Col>
                    )}

                    {permissions && permissions.includes(PERMISSIONS.INVENTORY_HISTORY) && (
                      <Col sm="4" className="text-center">
                        <Button className="btn--white mainmenu-item" onClick={handleInventoryHistoryMenuClick}>
                          {t('MainMenuFeature.check_inventory_history')}
                        </Button>
                      </Col>
                    )}

                    {permissions && permissions.includes(PERMISSIONS.MOVEMENT_HISTORY) && (
                      <Col sm="4" className="text-center">
                        <Button className="btn--white mainmenu-item" onClick={handleCheckMovementHistoryMenuClick}>
                          {t('MainMenuFeature.check_movement_history')}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              )}

            {permissions &&
              (permissions.includes(PERMISSIONS.ASSET_MODEL) ||
                permissions.includes(PERMISSIONS.ASSET_DETAIL) ||
                permissions.includes(PERMISSIONS.INSPECTION_HISTORY) ||
                permissions.includes(PERMISSIONS.ADMIN_MASTER)) && (
                <div className="mainmenu-section bg-yellow p-3">
                  <h5 className="text-danger mb-3">
                    <strong className="d-inline-block me-4">{t('MainMenuFeature.system_administrators')}</strong>
                  </h5>
                  <Row>
                    {permissions && permissions.includes(PERMISSIONS.ASSET_MODEL) && (
                      <Col sm="3" className="text-center">
                        <Button className="btn--white mainmenu-item" onClick={handleModalMasterMenuClick}>
                          {t('MainMenuFeature.model_master')}
                        </Button>
                      </Col>
                    )}

                    {permissions && permissions.includes(PERMISSIONS.ASSET_DETAIL) && (
                      <Col sm="3" className="text-center">
                        <Button className="btn--white mainmenu-item" onClick={handleAssetAddMenuClick}>
                          {t('MainMenuFeature.asset_detail')}
                        </Button>
                      </Col>
                    )}

                    {permissions && permissions.includes(PERMISSIONS.INSPECTION_HISTORY) && (
                      <Col sm="3" className="text-center">
                        <Button className="btn--white mainmenu-item" onClick={handleInspectionHistoryMenuClick}>
                          {t('MainMenuFeature.confirmation_of_status_equipment')}
                        </Button>
                      </Col>
                    )}

                    {permissions && permissions.includes(PERMISSIONS.ADMIN_MASTER) && (
                      <Col sm="3" className="text-center">
                        <Button className="btn--white mainmenu-item" onClick={handleAdminMasterMenuClick}>
                          {t('MainMenuFeature.admin_master')}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainMenuPage;
