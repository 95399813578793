import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CommonResponse } from 'models';
import { ApprovalStatus } from './models/approvalStatus';

export interface ApprovalStatusState {
  loading: boolean;
  list: ApprovalStatus[];
}

const initialState: ApprovalStatusState = {
  loading: false,
  list: [],
};

const approvalStatusSlice = createSlice({
  name: 'approval_status',
  initialState,
  reducers: {
    fetchApprovalStatusList(state) {
      state.loading = true;
    },
    fetchApprovalStatusSuccess(state, action: PayloadAction<CommonResponse<{ approval_status: ApprovalStatus[] }>>) {
      state.list = action.payload.data.approval_status;
      state.loading = false;
    },
    fetchApprovalStatusFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const approvalStatusActions = approvalStatusSlice.actions;

// Selectors
export const selectApprovalStatusLoading = (state: RootState) => state.approvalStatus.loading;
export const selectApprovalStatusList = (state: RootState) => state.approvalStatus.list;

export const selectApprovalStatusMap = createSelector(selectApprovalStatusList, (approvalStatusList) =>
  approvalStatusList.reduce((map: { [key: number]: ApprovalStatus }, approvalStatus: ApprovalStatus) => {
    map[approvalStatus.id] = approvalStatus;
    return map;
  }, {})
);

// Reducer
export const approvalStatusReducer = approvalStatusSlice.reducer;
