export const movementHistoryFeatureTranslationEN = {
  MovementHistoryFeature: {
    Component: {
      affiliation_search: 'Account search',
      employee_search: 'Employee search',
      search: 'search',
      csv_output: 'CSV output',
    },
    Text: {
      search_condition: 'Search conditions',
      it_is_a_required_field: '※It is a required field.',
      movement_information: 'Moving information',
      moving_day: 'Moving day',
      moving_source: 'Moving source',
      sub_inventory_transfer: 'Moving storage location',
      storage_location_selection: 'Selection of storage location',
      affiliation_transfer: 'Affiliated movement (moving to store / department)',
      company: 'Company',
      affiliation_code: 'Affiliated code',
      affiliation_name: 'Affiliation name',
      affiliation_movement: 'Affiliation movement (with specified user)',
      employee_code: 'Employee code',
      employee_name: 'Employee name',
      basic_information: 'Basic information',
      asset_management_number: 'Asset management number',
      accounting_information: 'Accounting information',
      accounting_number: 'Accounting number',
      search_result: 'search results',
      number_of_searches: 'Number of searches',
      subject: 'Subject',
    },
    Table: {
      moving_day: 'Moving day',
      control_number: 'Control number',
      accounting_number: 'Accounting number',
      classification: 'Classification',
      manufacturer: 'Manufacturer',
      model_name: 'Model name',
      usage: 'use',
      purchase_date: 'Purchase date',
      purchase_price: 'Purchase price',
      moving_source: 'Moving source',
      source_company: 'Source company',
      source_department: 'Source department',
      source_location: 'Storage location',
      source_sub_inventory: 'Source subinventory',
      source_user: 'Source user',
      destination_company: 'Destination company',
      destination_department: 'Destination department',
      destination_sub_inventory: 'Destination subinventory',
      destination_user: 'Destination user',
      update_date: 'Update date',
      changer: 'changer',
      destination: 'destination',
    },
  },
};
