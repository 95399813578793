export const statusHeaderTranslationEN = {
  Status_Header: {
    login_information: 'Login information',
    department_name: 'Department name',
    employee_name: 'Employee name',
    employee_code: 'Employee code',
    company_name: 'Company name',
    job_name: 'Job name',
    return: 'Return',
    logout: 'Logout',
  },
};
