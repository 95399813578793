export const employeeSearchModalTranslationJP = {
  Employee_Search_Modal: {
    title: '社員検索',
    close: '閉じる',
    reflect: '反映',
    search: '検索',
    company: '会社',
    employee_code: '社員コード',
    employee_name: '社員名',
    department_code: '所属コード',
    department_name: '所属名',
    how_to_use: '検索結果（選択を選んで、反映ボタンを押下してください）',
    select: '選択',
    company_name: '会社名',
    select_company: 'すべての会社',
  },
};
