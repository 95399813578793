import { useState } from 'react';
import { Col, Container, Row, Form, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { AssetForm } from 'features/AssetScreen/models/Asset';
import * as yup from 'yup';
import { Formik } from 'formik';
import OwnershipChangeModal from 'components/common/OwnershipChangeModal';
import '../scss/AssetAdd.scss';
import { DataLoadForm } from 'features/AssetScreen/models/DataLoadForm';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { assetAddActions, selectManufacturers, selectModels } from '../assetAddSlice';
import { Manufacturer } from 'features/AssetScreen/models/Manufacturer';
import { AssetModel } from 'features/AssetScreen/models/AssetModel';
import assetAddService from 'features/AssetScreen/pages/add/assetService';
import ExportCSV from 'features/AssetScreen/components/ExportCSV';
import ImportCSV from 'features/AssetScreen/components/ImportCSV';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { debounce } from 'lodash';
import { AlertModalProps } from 'components/common/AlertModal';
import { GlobalModalContext } from 'components/common/GlobalModal';
import { MODAL_TYPES } from 'utils';
import CustomDatePicker from 'components/common/DatePickerCustom/CustomDatePicker';


interface PropsDataLoadFormAdd {
  dataLoad: DataLoadForm;
}
function AssetFormAdd({ dataLoad }: PropsDataLoadFormAdd) {
  const { t } = useTranslation();
  const [purchasedDate, setPurchasedDate] = useState(new Date());
  const [assetClassification, setAssetClassification] = useState('');
  const [applyDate, setApplyDate] = useState(new Date());
  const endApplyDate = moment(applyDate).endOf('month').toDate();
  const manufactures = useAppSelector(selectManufacturers);
  const models = useAppSelector(selectModels);
  const dispatch = useAppDispatch();
  const [showOwnershipModal, setShowOwnershipModal] = useState(false);
  const [contractExpiryDate, setContractExpiryDate] = useState<undefined | Date >(undefined);
  const [contractExpiryDateState, setcontractExpiryDateState] = useState(true)
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();

  function handleShowOwnershipChangeModal() {
    setShowOwnershipModal(true);
  }

  const schema = yup.object().shape({
    management_no: yup.string().required(t('AssetScreen.add.form.required_field')),
    purchased_date: yup.string().required(t('AssetScreen.add.form.required_field')),
    type: yup.number().required(t('AssetScreen.add.form.required_field')),
    model: yup.number().required(t('AssetScreen.add.form.required_field')),
    asset_classification_id: yup.number().required(t('AssetScreen.add.form.required_field')),
    manufacturer_id: yup.number().required(t('AssetScreen.add.form.required_field')),
    accounting_class_id: yup.number().required(t('AssetScreen.add.form.required_field')),
    purchased_price: yup
      .number()
      .required(t('AssetScreen.add.form.required_field'))
      .typeError(t('AssetScreen.add.form.number_field')),
    purchased_method: yup.string().required(t('AssetScreen.add.form.required_field')),
    current_status: yup.number().required(t('AssetScreen.add.form.required_field')),
    apply_date: yup.string().required(t('AssetScreen.add.form.required_field')),
  });

  const initial: AssetForm = {
    id: 0,
    management_no: '',
    purchased_date: moment(purchasedDate).format('YYYY-MM-DD'),
    size: 0,
    asset_classification_id: '',
    manufacturer_id: '',
    manufacturer_serial_number: '',
    external_order_number: '',
    compressed_output: '',
    accounting_class_id: '',
    accounting_no: '',
    leasing_divided_contract_company: '',
    type: '',
    model: '',
    model_number: 0,
    mac_address: '',
    gas_specie: '',
    power_supply: '',
    purchased_price: '',
    purchased_method: '',
    contract_expiry_date: moment(new Date()).format('YYYY-MM-DD'),
    current_use: '',
    current_status: '',
    current_installation_location: '',
    current_use_location: '',
    current_possession_company: {
      id: '',
      short_name: '',
      cd: '',
    },
    current_possession_department: {
      id: '',
      name: '',
      cd: '',
    },
    current_possession_employee: {
      id: '',
      cd: '',
      name: '',
    },
    current_storage_place: {
      id: '',
      name: '',
      company: {
        id: '',
        cd: '',
        short_name: '',
      },
    },
    delete_flg: false,
    updated_date: '',
    updated_employee: '',
    updated_company: '',
    apply_date: moment(applyDate).format('YYYY-MM-DD'),
    moving_day: moment(endApplyDate).format('YYYY-MM-DD'),
  };
  const [initialValues, setInitialValues] = useState(initial);

  const handleSubmitForm = (values: AssetForm) => {
    if (values.purchased_method !== '2') {
      values.contract_expiry_date = '';
    }
    values.current_possession_company = initialValues.current_possession_company
      ? initialValues.current_possession_company.id
      : '';
    values.current_possession_department = initialValues.current_possession_department?.id;
    values.current_possession_employee = initialValues.current_possession_employee?.id;
    values.current_storage_place = initialValues.current_storage_place?.id;
    dispatch(assetAddActions.saveAsset(values));
  };
  const handleChangeAssetClass = (e: any) => {
    const query = `asset_classification_id=${e.target.value}`;
    setAssetClassification(e.target.value);
    dispatch(assetAddActions.fetchDataManufacturer(query));
  };

  const handleChangeManufacture = (e: any) => {
    const query = `asset_classification_id=${assetClassification}&manufacturer_id=${e.target.value}`;
    dispatch(assetAddActions.fetchDataModel(query));
  };

  const handleChangeManagementNo = debounce(
    async (event: any, setFieldError: (field: string, message: string | undefined) => void) => {
      const query = `management_no=${event.target.value}`;
      if (event.target.value !== '') {
        await assetAddService
          .checkAssetCode(query)
          .catch((err) => {
            const asset = err.response.data;
            showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
              message: asset.message,
              title: t('App.error'),
            });
          });
      }
    },
    100
  );


  return (
    <div className="mb-3">
      <Container fluid="lg">
        <Formik validationSchema={schema} onSubmit={handleSubmitForm} initialValues={initialValues}>
          {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* 資産情報 */}
              <ButtonGroup className="me-2">
                <ImportCSV></ImportCSV>
              </ButtonGroup>

              <ButtonGroup className="me-2">
                <ExportCSV></ExportCSV>
              </ButtonGroup>

              <Card className="mt-3">
                <Card.Header as="h5">資産情報</Card.Header>
                <Card.Body>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      <span className="text-danger">※</span>
                      {t('AssetScreen.add.form.management_no')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="management_no"
                        value={values.management_no}
                        onChange={async (event) => {
                          handleChange(event);
                          handleChangeManagementNo(event, setFieldError);
                        }}
                        isInvalid={!!errors.management_no}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.management_no}</Form.Control.Feedback>
                    </Col>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      <span className="text-danger">※</span>
                      {t('AssetScreen.add.form.type')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Select
                        size="sm"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        isInvalid={!!errors.type}
                      >
                        <option value="">---{t('AssetScreen.add.form.type')}---</option>
                        {dataLoad.data.asset_type.map((assetType) => {
                          return (
                            <option key={assetType.id} value={assetType.id}>
                              {assetType.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      <span className="text-danger">※</span>
                      {t('AssetScreen.add.form.purchased_date')}
                    </Form.Label>
                    <Col xs sm="4">
                      <CustomDatePicker
                        value={purchasedDate}
                        onChange={(date: Date) => {
                          if (!date) {
                            date = new Date();
                          }
                          values.purchased_date = moment(date).format('YYYY-MM-DD');
                          setPurchasedDate(date);
                        }}
                        disabledKeyboardNavigation={true}
                        className="form-control"
                        name="purchased_date"
                        dateFormat="yyyy/MM/dd"
                      />
                      <Form.Control.Feedback type="invalid">{errors.purchased_date}</Form.Control.Feedback>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      <span className="text-danger">※</span>
                      {t('AssetScreen.add.form.asset_classification_id')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Select
                        size="sm"
                        name="asset_classification_id"
                        value={values.asset_classification_id}
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeAssetClass(e);
                        }}
                        isInvalid={!!errors.asset_classification_id}
                      >
                        <option>
                          ---{t('AssetScreen.add.form.asset_classification_id')}
                          ---
                        </option>
                        {dataLoad.data.asset_classifications.map((assetClass) => {
                          return (
                            <option key={assetClass.id} value={assetClass.id}>
                              {assetClass.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.asset_classification_id}</Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      <span className="text-danger">※</span>
                      {t('AssetScreen.add.form.manufacturer_id')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Select
                        size="sm"
                        name="manufacturer_id"
                        value={values.manufacturer_id}
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeManufacture(e);
                        }}
                        isInvalid={!!errors.manufacturer_id}
                      >
                        <option>---{t('AssetScreen.add.form.manufacturer_id')}---</option>
                        {manufactures.data.map((manufacture: Manufacturer) => {
                          return (
                            <option key={manufacture.id} value={manufacture.id}>
                              {manufacture.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.manufacturer_id}</Form.Control.Feedback>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      <span className="text-danger">※</span>
                      {t('AssetScreen.add.form.model')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Select
                        size="sm"
                        name="model"
                        value={values.model}
                        onChange={handleChange}
                        isInvalid={!!errors.model}
                      >
                        <option>---{t('AssetScreen.add.form.model')}---</option>
                        {models.data.map((model: AssetModel) => {
                          return (
                            <option key={model.id} value={model.id}>
                              {model.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.model}</Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.size')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="size"
                        value={values.size}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.model_number')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="model_number"
                        value={values.model_number}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.manufacturer_serial_number')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="manufacturer_serial_number"
                        value={values.manufacturer_serial_number}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.mac_address')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="mac_address"
                        value={values.mac_address}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.external_order_number')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="external_order_number"
                        value={values.external_order_number}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.gas_specie')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="gas_specie"
                        value={values.gas_specie}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.compressed_output')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="compressed_output"
                        value={values.compressed_output}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.power_supply')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="power_supply"
                        value={values.power_supply}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      <span className="text-danger">※</span>
                      {t('AssetScreen.add.form.accounting_class_id')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Select
                        size="sm"
                        name="accounting_class_id"
                        value={values.accounting_class_id}
                        onChange={handleChange}
                        isInvalid={!!errors.accounting_class_id}
                      >
                        <option>---{t('AssetScreen.add.form.accounting_class_id')}---</option>
                        {dataLoad.data.accounting_class.map((accountingClass) => {
                          return (
                            <option key={accountingClass.id} value={accountingClass.id}>
                              {accountingClass.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.accounting_class_id}</Form.Control.Feedback>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      <span className="text-danger">※</span>
                      {t('AssetScreen.add.form.purchased_price')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="number"
                        name="purchased_price"
                        value={values.purchased_price}
                        onChange={handleChange}
                        isInvalid={!!errors.purchased_price}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.purchased_price}</Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.accounting_no')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="accounting_no"
                        value={values.accounting_no}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      <span className="text-danger">※</span>
                      {t('AssetScreen.add.form.purchased_method')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Select
                        size="sm"
                        name="purchased_method"
                        value={values.purchased_method}
                        onChange={(event:React.ChangeEvent<HTMLSelectElement>)=> {
                          handleChange(event);
                          const purchased_method = event.target.value;
                          if ( purchased_method === '2') {
                            setcontractExpiryDateState(false);
                            setContractExpiryDate(moment(moment(new Date()).format('YYYY-MM-DD'), 'YYYY-MM-DD').toDate());
                          } else {
                            setcontractExpiryDateState(true);
                            setContractExpiryDate(undefined);
                          }
                        }}
                        isInvalid={!!errors.purchased_method}
                      >
                        <option>---{t('AssetScreen.add.form.purchased_method')}---</option>
                        {dataLoad.data.purchased_method.map((purchasedMethod) => {
                          return (
                            <option key={purchasedMethod.id} value={purchasedMethod.id}>
                              {purchasedMethod.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.purchased_method}</Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.leasing_divided_contract_company')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="leasing_divided_contract_company"
                        value={values.leasing_divided_contract_company}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.contract_expiry_date')}
                    </Form.Label>
                    <Col xs sm="4">
                      <CustomDatePicker
                        disabled={contractExpiryDateState}
                        value={contractExpiryDate}
                        onChange={(date: Date) => {
                          if ( values.purchased_method === '2') {
                            if (!date) {
                              date = new Date();
                            }
                            values.contract_expiry_date = moment(date).format('YYYY-MM-DD');
                            setContractExpiryDate(date);
                            setcontractExpiryDateState(false);
                          }
                        }}
                        className="form-control"
                        name="contract_expiry_date"
                        dateFormat="yyyy/MM/dd"
                        disabledKeyboardNavigation={true}
                      />
                      <Form.Control.Feedback type="invalid">{errors.contract_expiry_date}</Form.Control.Feedback>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* 資産ステータス情報 */}
              <Card className="mt-3">
                <Card.Header as="h5">資産ステータス情報</Card.Header>
                <Card.Body>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.current_use')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Select
                        size="sm"
                        name="current_use"
                        value={values.current_use}
                        onChange={handleChange}
                        isInvalid={!!errors.current_use}
                      >
                        <option>---{t('AssetScreen.add.form.current_use')}---</option>
                        {dataLoad.data.asset_uses.map((assetUse) => {
                          return (
                            <option key={assetUse.id} value={assetUse.id}>
                              {assetUse.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.current_use}</Form.Control.Feedback>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      <span className="text-danger">※</span>
                      {t('AssetScreen.add.form.current_status')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Select
                        size="sm"
                        name="current_status"
                        value={values.current_status}
                        onChange={handleChange}
                        isInvalid={!!errors.current_status}
                      >
                        <option>---{t('AssetScreen.add.form.current_status')}---</option>
                        {dataLoad.data.asset_status.map((assetStatus) => {
                          return (
                            <option key={assetStatus.id} value={assetStatus.id}>
                              {assetStatus.name}
                            </option>
                          );
                        })}
                      </Form.Select>

                      <Form.Control.Feedback type="invalid">{errors.current_status}</Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.current_installation_location')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="current_installation_location"
                        value={values.current_installation_location}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.current_use_location')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Control
                        className="form-control-sm"
                        type="text"
                        name="current_use_location"
                        value={values.current_use_location}
                        onChange={handleChange}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* 資産保有情報 */}
              <Card className="mt-3">
                <Card.Header as="h5">
                  <Row>
                    <Col xs sm="2">
                      <Form.Label className="col-form-label text-end">資産保有情報</Form.Label>
                    </Col>
                    <Col xs sm="2">
                      <Button onClick={handleShowOwnershipChangeModal}>移動処理</Button>
                      <OwnershipChangeModal
                        show={showOwnershipModal}
                        setShowOwnershipModal={setShowOwnershipModal}
                        setInitialValues={setInitialValues}
                        initialValues={values}
                        companies={dataLoad.data.companies}
                        storage_places={dataLoad.data.storage_places}
                      />
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.current_possession_company')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Label className="col-sm-6 col-form-label text-end">
                        {initialValues.current_possession_company?.cd}{' '}
                        {initialValues.current_possession_company?.short_name}
                      </Form.Label>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.current_possession_department')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Label className="col-sm-6 col-form-label text-end">
                        {initialValues.current_possession_department.cd}{' '}
                        {initialValues.current_possession_department.name}
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.current_storage_place')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Label className="col-sm-4 col-form-label text-end">
                        {initialValues.current_storage_place.name}
                      </Form.Label>
                    </Col>

                    <Form.Label className="col-sm-2 col-form-label text-end">
                      {t('AssetScreen.add.form.current_possession_employee')}
                    </Form.Label>
                    <Col xs sm="4">
                      <Form.Label className="col-sm-4 col-form-label text-end">
                        {initialValues.current_possession_employee.cd} {initialValues.current_possession_employee.name}
                      </Form.Label>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Row className="align-items-center mt-3 mb-3">
                <Col xs sm="3"></Col>
                <Col xs sm="4">
                  <div className="inline">
                    <span className="text-danger">※</span>
                    <span>{t('AssetScreen.add.form.apply_date')}</span>
                    <CustomDatePicker
                      value={applyDate}
                      onChange={(date: Date) => {
                        if (!date) {
                          date = new Date();
                        }
                        values.apply_date = moment(date).format('YYYY-MM-DD');
                        setApplyDate(date);
                      }}
                      className="form-control w-75"
                      name="apply_date"
                      dateFormat="yyyy/MM/dd"
                      disabledKeyboardNavigation={true}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">{errors.apply_date}</Form.Control.Feedback>
                </Col>
                <Col xs sm="1">
                  <Button
                    onClick={() => {
                      setFieldValue('delete_flg', false);
                      handleSubmit();
                    }}
                  >
                    {t('AssetScreen.add.form.button_ok')}
                  </Button>
                </Col>
                <Col xs sm="1">
                  <Button
                    onClick={() => {
                      setFieldValue('delete_flg', true);
                      handleSubmit();
                    }}
                    className="btn--red"
                  >
                    {t('AssetScreen.add.form.button_submit_del')}
                  </Button>
                </Col>

                <Col xs sm="3"></Col>
              </Row>

              {/* 変更履歴 */}
              {/* <AssetHistory /> */}
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
}

export default AssetFormAdd;
