import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { CommonResponse } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import assetStatusService from './assetStatusService';
import { assetStatusActions } from './assetStatusSlice';
import { AssetStatus } from './models/assetStatus';

function* fetchAssetStatusList() {
  try {
    const response: CommonResponse<AssetStatus[]> = yield call(assetStatusService.getAll);
    yield put(assetStatusActions.fetchAssetStatusSuccess(response));
  } catch (e) {
    yield put(assetStatusActions.fetchAssetStatusFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* assetStatusSaga() {
  yield takeLatest(assetStatusActions.fetchAssetStatusList.type, fetchAssetStatusList);
}
